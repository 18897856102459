import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  bookmarkRoute,
  categoryRoute,
  homeRoute,
  myPageRoute,
} from '../pages/Router';
import { Icon } from './Icon';

export const BottomNavBar = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  return (
    <div className="fixed bottom-0 z-20 grid w-full grid-cols-4 border-t bg-white py-5">
      <button
        onClick={() => push(`${homeRoute}`)}
        type="button"
        className="grid place-content-center"
      >
        <Icon.Home className={pathname === homeRoute ? '' : 'text-gray-400'} />
      </button>

      <button
        onClick={() => push(`${categoryRoute}`)}
        type="button"
        className="grid place-content-center"
      >
        <Icon.List
          className={pathname === categoryRoute ? '' : 'text-gray-400'}
        />
      </button>

      <button
        onClick={() => push(`${bookmarkRoute}`)}
        type="button"
        className="grid place-content-center"
      >
        <Icon.Bookmark
          className={pathname === bookmarkRoute ? '' : 'text-gray-400'}
        />
      </button>

      <button
        onClick={() => push(`${myPageRoute}`)}
        type="button"
        className="grid place-content-center"
      >
        <Icon.Settings
          className={pathname === myPageRoute ? '' : 'text-gray-400'}
        />
      </button>
    </div>
  );
};
