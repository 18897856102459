import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';
import { TextArea } from '../../../../components/TextArea';
import { TopBar } from '../../../../components/TopBar';
import { useProduct } from '../../../../queries/products'
import { useCreateReview, useEditReview, useReview } from '../../../../queries/reviews'
import { PostReviewBody, Review } from '../../../../types/review'
import { IdParams } from '../../../../types/router'
import { displayProductInfo, range } from '../../../../utils';

export const CreateMyReviewPage = () => {
  const { id } = useParams<IdParams>();
  const [productId, shippingLineItemId] = id.split('-');
  const { createReview } = useCreateReview();
  return (
    <WriteMyReviewPage
      productId={productId}
      shippingLineItemId={shippingLineItemId}
      submitReview={createReview}
    />
  );
};

export const EditMyReviewPage = () => {
  const { id } = useParams<IdParams>();
  const { editReview } = useEditReview(id);
  const { review } = useReview(id);
  if (!review.productId) return <></>;
  return (
    <WriteMyReviewPage
      productId={review.productId.toString()}
      shippingLineItemId={review.shippingLineItemId.toString()}
      submitReview={editReview}
      review={review}
    />
  )
}

interface WriteMyReviewPageProps {
  productId: string
  shippingLineItemId: string
  submitReview: Function
  review?: Review
}

const WriteMyReviewPage = ({
  productId,
  shippingLineItemId,
  submitReview,
  review,
}: WriteMyReviewPageProps) => {
  const { product } = useProduct(productId);
  const [star, setStar] = useState(review?.score ?? 5);
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<PostReviewBody>({ mode: 'onChange' });

  useEffect(() => {
    if (!setValue) return;
    setValue('shippingLineItemId', +shippingLineItemId);
  }, [shippingLineItemId, setValue]);

  useEffect(() => {
    if (!setValue) return;
    setValue('score', star);
  }, [star, setValue]);

  useEffect(() => {
    if (!review?.content) return;
    if (!setValue) return;
    setValue('content', review.content, {
      shouldValidate: true
    });
  }, [review?.content, setValue]);

  const onSubmit = (data: PostReviewBody) => {
    submitReview(data);
  }

  return (
    <div className="md:mx-auto md:mt-14 md:w-full md:max-w-3xl">
      <TopBar title="리뷰쓰기" />

      <div className="mx-5 space-y-4 md:mx-0 md:space-y-8">
        <div className="card-2 py-5">
          <div className="mt-4 flex space-x-3">
            <img
              src={product.images?.[0]}
              alt={product.name}
              className="h-32 w-20 overflow-hidden rounded-xl bg-brand-3"
            />

            <div className="flex flex-col">
              <h6 className="">{product.name}</h6>
              <span className="text-14 text-gray-500">
                {displayProductInfo(product)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex space-x-1">
          {range(star).map((item, index) => (
            <Icon.Star
              className="wh-4 fill-current text-yellow-300 md:wh-6 cursor-pointer"
              onClick={() => setStar(index + 1)}
            />
          ))}

          {range(5 - star).map((item, index) => (
            <Icon.Star
              className="wh-4 fill-current text-gray-100 md:wh-6 cursor-pointer"
              onClick={() => setStar(star + index + 1)}
            />
          ))}
        </div>

        <TextArea
          placeholder="배송 클레임, 도서나 타인에 대한 근거 없는 비방, 의성어나 의태어 등의 단순 단어 반복, 욕설과 비속어 등 리뷰와 무관한 내용은 별도의 통보 없이 삭제될 수 있습니다."
          className="mb-8 h-52"
          {...register('content', {
            required: true,
            minLength: {
              value: 1,
              message: '1자 이상 입력해주세요.'
            }
          })}
        />

        <Button
          disabled={!isValid}
          text="작성하기"
          className="filled-gray-900 w-full"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};
