import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Radio } from '../../../components/Radio';
import { RadioGroup } from '../../../components/RadioGroup';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { useCreatePartnershipFrom } from '../../../queries/partnershipFroms';
import {
  CreatePartnershipForm,
  InquiryType,
} from '../../../types/partnershipForm';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const PartnerShipFormPage = () => {
  const { goBack } = useHistory();
  const { createPartnershipForm } = useCreatePartnershipFrom();

  const CreatePartnershipFormSchema = Yup.object().shape({
    email: Yup.string()
      .email('이메일 형식이 아닙니다.')
      .required('이메일을 입력해주세요.'),
    name: Yup.string().required('이름을 입력해주세요.'),
    phoneNumber: Yup.string()
      .required('휴대폰을 입력해주세요.')
      .matches(/^\d{2,3}-?\d{3,4}-?\d{4}$/, '전화번호 양식이 아닙니다.'),
    contents: Yup.string().required('제휴 문의 내용을 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreatePartnershipForm>({
    mode: 'onChange',
    resolver: yupResolver(CreatePartnershipFormSchema),
    defaultValues: {
      inquiryType: InquiryType.CONSULTING as keyof typeof InquiryType,
    },
  });

  return (
    <div className="md:mx-auto md:mt-8 md:max-w-3xl">
      <div className="ml-5 flex gap-5">
        <button onClick={goBack} className="">
          <Icon.ChevronLeft />
        </button>
        <h4>제휴 문의하기</h4>
      </div>

      <form onSubmit={handleSubmit((data) => createPartnershipForm(data))}>
        <div className="mx-5 mt-5 space-y-3 rounded-xl p-4 shadow md:mx-0">
          <p className="pb-2 text-17 font-bold text-black">제휴 문의 선택</p>
          <RadioGroup
            onChange={(e) =>
              setValue(
                'inquiryType',
                e.target.value as keyof typeof InquiryType
              )
            }
          >
            <Radio
              label="북큐레이션 및 공간 컨설팅 문의"
              value={InquiryType.CONSULTING}
              checked={watch('inquiryType') === InquiryType.CONSULTING}
            />
            <Radio
              label="입점 문의"
              value={InquiryType.ENTERED}
              checked={watch('inquiryType') === InquiryType.ENTERED}
            />
            <Radio
              label="콘텐츠 및 이벤트 협업 문의"
              value={InquiryType.COLLABORATION}
              checked={watch('inquiryType') === InquiryType.COLLABORATION}
            />
            <Radio
              label="대관 문의"
              value={InquiryType.RENTAL}
              checked={watch('inquiryType') === InquiryType.RENTAL}
            />
            <Radio
              label="광고 문의"
              value={InquiryType.ADVERTISEMENT}
              checked={watch('inquiryType') === InquiryType.ADVERTISEMENT}
            />
            <Radio
              label="대량 구매 문의"
              value={InquiryType.BULK_PURCHASE}
              checked={watch('inquiryType') === InquiryType.BULK_PURCHASE}
            />
            <Radio
              label="기타 사업 관련 문의"
              value={InquiryType.BUSINESS}
              checked={watch('inquiryType') === InquiryType.BUSINESS}
            />
          </RadioGroup>
        </div>

        <div className="mx-5 mt-5 space-y-5 rounded-xl p-4 shadow md:mx-0">
          <p className="text-17 font-bold text-black">정보입력</p>
          <div className="grid-cols-2 space-y-5 md:grid md:gap-5 md:space-y-0">
            <TextField
              placeholder="이름 (필수)"
              peerStyle={false}
              helper={errors.name?.message}
              {...register('name', {
                required: '이름을 입력해주세요.',
              })}
            />
            <TextField
              type="email"
              placeholder="이메일 (필수)"
              peerStyle={false}
              helper={errors.email?.message}
              {...register('email', { required: '이메일을 입력해주세요.' })}
            />
            <TextField
              placeholder="전화번호 (필수)"
              peerStyle={false}
              helper={errors.phoneNumber?.message}
              {...register('phoneNumber', {
                required: '전화번호를 입력해주세요.',
              })}
            />
            <TextField
              placeholder="업체명"
              peerStyle={false}
              {...register('companyName')}
            />
            <div className="col-span-2">
              <TextArea
                placeholder="내용 (필수)"
                className="h-80 resize-none rounded-lg focus:border-black"
                helper={errors.contents?.message}
                {...register('contents', {
                  required: '제휴 문의 내용을 입력해주세요.',
                })}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 bg-[#FBFAF9] p-5">
          <p className="text-12 font-bold text-[#221F20]">입점 절차</p>
          <p className="mt-1.5 text-12 text-gray-600">
            입점 신청서 작성
            <br />
            1. 담당자 검토 및 연락 (담당자가 검토 후에 입점이 적합한 경우 전화나
            이메일로 연락을 드리겠습니다.)
            <br />
            2. 조건 협의 및 계약체결 (제휴 내용 구체화 및 조건을 협의한 후
            계약을 체결합니다.)
          </p>

          <p className="mt-5 text-12 font-bold text-[#221F20]">
            ※ 입점진행 시 주의사항 안내
          </p>
          <p className="mt-1.5 text-12 text-gray-600">
            -아크앤북은 정품을 판매하고 취급하는 업체 및 브랜드만 입점이
            가능합니다.
            <br />
            -입점 신청서에 기재해주신 내용을 내부 검토 후에 입점 가능 여부와
            계약 절차를 안내해드립니다. (고객센터, 별도 메일을 통한 문의 및 접수
            불가)
            <br />
            -아크앤북은 정품을 판매하고 취급하는 업체 및 브랜드만 입점이
            가능하며, 가품, 도매 사입품(택갈이) 등 아크앤북 사업 방향에 맞지
            않는 브랜드의 입점은 진행하지 않습니다. (병행수입 상품의 경우
            수입신고 필증 보유 시 가능)
          </p>
          <p className="mt-5 text-12 text-gray-600">
            아크앤북에 관심을 가져주시고 좋은 제안 주신 점 감사 말씀 드립니다.
            <br />
            제안 주신 내용은 내부 기준에 따라 검토 예정입니다.
            <br />
            입점이 진행될 경우 일주일 이내 연락드릴 예정이며, 진행이 어렵다고
            판단될 경우 별도의 회신을 드리지 않는 점 깊은 양해 부탁드립니다.
            <br />
            감사합니다.
          </p>
        </div>
        <Button
          text="문의하기"
          className="fixed bottom-0 w-full rounded-none bg-[#221F20] text-center text-white disabled:bg-[#D6D6D6] disabled:text-white md:relative md:mt-6 md:rounded-xl"
          disabled={
            !watch('inquiryType') ||
            !watch('name') ||
            !watch('email') ||
            !watch('phoneNumber') ||
            !watch('contents')
          }
        />
      </form>
    </div>
  );
};
