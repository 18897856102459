export const InquiryType = {
  CONSULTING: 'CONSULTING',
  ENTERED: 'ENTERED',
  COLLABORATION: 'COLLABORATION',
  RENTAL: 'RENTAL',
  ADVERTISEMENT: 'ADVERTISEMENT',
  BULK_PURCHASE: 'BULK_PURCHASE',
  BUSINESS: 'BUSINESS',
};

export interface CreatePartnershipForm {
  inquiryType: keyof typeof InquiryType;
  name: string;
  email: string;
  phoneNumber: string;
  companyName?: string;
  contents: string;
}
