import React from 'react';
import { useParams } from 'react-router-dom'
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment'
import { useOrderDetail } from '../../queries/orders'
import { useUser } from '../../queries/user'
import { IdParams } from '../../types/router'
import { Accordion } from '../Accordion';
import { InfoItem } from './InfoItem';

export const OrderInfo = () => {
  const { user } = useUser()
  const { id } = useParams<IdParams>();
  const { order } = useOrderDetail(+id);
  if (!order.shippingLines) return <></>

  const createdAt = utcToLocalFormat(order.createdAt, MomentFormat.DateTime)
  const shippingLine = order.shippingLines[0]
  return (
    <div className="card-2">
      <Accordion
        title={<h6 className="font-bold">주문 정보</h6>}
        panel={
          <div className="space-y-2">
            <InfoItem title="주문일시" desc={createdAt} />
            <InfoItem title="주문자명" desc={user.name} />
            <InfoItem title="받는 분" desc={shippingLine.receiverName} />
            <InfoItem title="핸드폰" desc={shippingLine.receiverTel} />
            <InfoItem title="주소" desc={`${shippingLine.address} ${shippingLine.addressDetail}`} />
            <InfoItem
              title="배송 요청사항"
              desc={shippingLine.requestMemo}
            />
          </div>
        }
      />
    </div>
  );
};
