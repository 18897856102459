import React, { FC, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Radio: FC<RadioProps> = ({ className = '', label, ...props }) => {
  const [id] = useId(1, 'radio');

  return (
    <div className="label-row">
      <input
        id={id}
        type="radio"
        className={`radio peer disabled:bg-[#D6D6D6]  ${className}`}
        {...props}
      />
      {label && (
        <Label
          htmlFor={id}
          text={label}
          className="peer-disabled:text-gray-300"
        />
      )}
    </div>
  );
};
