import React from 'react';
import { ProductCard } from '../../components/ProductCard';
import { useBookmarkProducts } from '../../queries/products';

export const BookmarksPage = () => {
  const { bookmarks } = useBookmarkProducts();
  return (
    <div className="px-5 py-6 md:mx-auto md:max-w-screen-xl md:px-0">
      <h4>저장한 도서 | 일반상품 </h4>

      <div className="mt-8 grid grid-cols-2 gap-5 md:grid-cols-6">
        {bookmarks.map((p) => (
          <ProductCard
            to={`/product/${p.id}`}
            product={p}
            showRanking={false}
          />
        ))}
      </div>
      {bookmarks.length === 0 && (
        <div className="flex h-[28vh] w-full items-center justify-center">
          저장된 도서 | 일반상품이 없습니다.
        </div>
      )}
    </div>
  );
};
