import React from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductCard } from '../../components/ProductCard';
import { Section } from '../../components/Section';
import { useArcNShop } from '../../queries/arcNShop';
import { useMainBanners } from '../../queries/mainBanners';
import { Header } from '../home/Header';
import { goToUrl } from '../home/utils';

export const ArcNShop = () => {
  const { push } = useHistory();
  const { arcNShop } = useArcNShop();
  const { mainBanners } = useMainBanners('SHOP');
  return (
    <>
      <Section>
        {mainBanners.length > 0 && (
          <Swiper
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            loop
            id="white_bullet"
            modules={[Pagination]}
            className="mySwiper"
          >
            {mainBanners.map((banner) => (
              <SwiperSlide key={banner.id}>
                <div
                  onClick={() => goToUrl(banner.url)}
                  style={{
                    backgroundImage: `url(${
                      isMobile
                        ? banner.mobileBannerImageUrl
                        : banner.pcBannerImageUrl
                    })`,
                  }}
                  className={`relative aspect-[4/5]  bg-cover bg-center bg-no-repeat md:aspect-[3/1]`}
                >
                  {/* <div className="absolute inset-0 bg-black bg-opacity-10" />
            <div className="px-10 pt-14 text-white">
              <div className="filled-brand-1 w-max rounded-full px-3 py-1 text-14">
                EVENT 1
              </div>

              <h1 className="mt-3 mb-1">{banner.title}</h1>
              <h6>{banner.subTitle}</h6>
            </div> */}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Section>

      <div className="mt-10 px-5 md:mx-auto md:mt-14 md:w-full md:max-w-screen-xl">
        <Header title={'라이프'} description={arcNShop?.description} />

        <div className="grid grid-cols-2 gap-5 md:mt-10 md:grid-cols-6 md:gap-y-8">
          {arcNShop?.products?.map((product) => (
            <ProductCard
              product={product.product}
              to={`/product/${product.product.id}`}
            />
          ))}
        </div>
      </div>
    </>
  );
};
