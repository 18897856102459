import { useQuery } from 'react-query'
import API from '../api'
import { TopMenu } from '../types/topMenu'

export const useTopMenus = () => {
  const { data, isLoading } = useQuery(
    ['topMenus'],
    async () => await API.getTopMenus(),
    {
      select: (data) => {
        const topMenus = data?.data?.items
        if (!topMenus) return
        return {
          topMenus: topMenus
            .sort((a, b) => a.order - b.order)
        }
      }
    }
  )
  if (isLoading || !data) {
    return {
      topMenus: [] as TopMenu[]
    }
  }

  return {
    ...data,
  }
}
