import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../../api';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { useModal } from '../../components/modal/Modal';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import CONST from '../../const';
import useAuth from '../../hooks/useAuth';
import { LibroUserInfo, SignUpBody } from '../../types/user';

export const SignupPage = () => {
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
  } = useForm<SignUpBody>({ mode: 'onChange' });
  const { push } = useHistory();
  const { alertNewUser, alertLibroUser } = useModal();

  useEffect(() => {
    if (!register) return;
    register('phone', { required: true });
  }, [register]);

  const onVerifiedPhoneNumber = (phone: string) => {
    setValue('phone', phone);
    setValue('phoneConfirmedAt', new Date().toISOString());
  };

  const onGetLibroUser = (userInfo: LibroUserInfo) => {
    if (userInfo.isNewUser) {
      alertNewUser();
    } else {
      setValue('libroCustomerCode', userInfo.libroCustomerCode);
      setValue('name', userInfo.libroUserName);
      alertLibroUser();
    }
  };

  const termChecked = !!watch('isServiceTermAgreed');
  const privacyChecked = !!watch('isPrivacyTermAgreed');
  const marketingChecked = !!watch('isMarketingUsageAgreed');

  const setCheckValue = (field: keyof SignUpBody, value?: boolean) => {
    const checked = value ? !value : !getValues(field);
    if (checked) {
      setValue(field, true);
    } else {
      setValue(field, undefined);
    }
  };

  const setCheckValueForAll = (field: keyof SignUpBody, value?: boolean) => {
    setValue(field, !value);
  };

  const onSubmit = useCallback(
    async (data: SignUpBody) => {
      const { data: isExist } = await API.getUserExists({
        email: getValues('email'),
      });
      if (isExist) {
        return toast.error('이미 존재하는 계정입니다.');
      }
      API.postUser({
        ...data,
        name: data.name.trim(),
      })
        .then(() => {
          toast.success('반갑습니다! 회원가입이 완료되었습니다.');
          login({
            email: data.email,
            password: data.password,
          })
        })
        .catch((error) => {
          if (!axios.isAxiosError(error)) return;
          toast.error(error?.response?.data?.message);
        });
    },
    [getValues, login]
  );

  return (
    <div className="md:mx-auto md:w-full md:max-w-lg md:pt-14">
      <TopBar title="회원가입" />

      <form
        className="flex flex-col space-y-9 px-5 py-6 md:px-0"
      >
        <div className="">
          <h5 className="mb-5 font-bold">핸드폰 번호 인증</h5>
          <PhoneNumber
            onVerified={onVerifiedPhoneNumber}
            onGetLibroUser={onGetLibroUser}
            shouldCheckExist
          />
        </div>

        <h5 className="font-bold">회원정보</h5>
        <TextField
          label="이름을 입력해주세요."
          placeholder="이름을 입력해주세요."
          helper={errors.name?.message}
          {...register('name', { required: '이름을 입력해주세요.' })}
        />
        <TextField
          type="email"
          label="이메일을 입력해주세요."
          placeholder="이메일을 입력해주세요."
          helper={errors.email?.message}
          {...register('email', { required: '이메일을 입력해주세요.' })}
        />

        <TextField
          type="password"
          label="비밀번호를 입력해주세요."
          placeholder="비밀번호를 입력해주세요."
          helper={errors.password?.message}
          {...register('password', {
            required: '비밀번호를 입력해주세요',
            minLength: {
              value: 8,
              message: '비밀번호를 8자리 이상 입력해주세요.',
            },
          })}
        />

        <TextField
          type="password"
          label="비밀번호를 다시 입력해주세요."
          placeholder="비밀번호를 다시 입력해주세요."
          helper={errors.passwordConfirm?.message}
          {...register('passwordConfirm', {
            required: '비밀번호를 다시 입력해주세요.',
            validate: (value) =>
              value === getValues('password') ||
              '비밀번호가 일치하지 않습니다.',
          })}
        />

        {/* <PhoneNumber onClick={() => setVerified(true)} verified={verified} /> */}

        <div className="space-y-4">
          <Checkbox
            onChange={() => {
              const allChecked =
                termChecked && marketingChecked && privacyChecked;
              setCheckValueForAll('isServiceTermAgreed', allChecked);
              setCheckValueForAll('isPrivacyTermAgreed', allChecked);
              setCheckValueForAll('isMarketingUsageAgreed', allChecked);
            }}
            checked={termChecked && privacyChecked && marketingChecked}
            label2={<span className="font-bold">전체동의</span>}
          />
          <Checkbox
            onChange={() => setCheckValue('isServiceTermAgreed')}
            checked={termChecked}
            label2={
              <a
                href={CONST.TERMS_AGREEMENT_URL}
                target="_blank"
                rel="noreferrer"
                className="text-15 text-gray-600 underline"
              >
                이용약관 (필수)
              </a>
            }
          />
          <Checkbox
            onChange={() => setCheckValue('isPrivacyTermAgreed')}
            checked={privacyChecked}
            label2={
              <a
                href={CONST.PRIVACY_AGREEMENT_URL}
                target="_blank"
                rel="noreferrer"
                className="text-15 text-gray-600 underline"
              >
                개인정보 처리방침 (필수)
              </a>
            }
          />

          <Checkbox
            onChange={() => setCheckValue('isMarketingUsageAgreed')}
            checked={marketingChecked}
            label2={
              <a
                href={CONST.MARKETING_AGREEMENT_URL}
                target="_blank"
                rel="noreferrer"
                className="text-15 text-gray-600 underline"
              >
                마케팅 약관 동의(선택)
              </a>
            }
          />
        </div>

        <Button
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          text="회원가입"
          className="filled-gray-900"
        />
      </form>
    </div>
  );
};
