import React, { Dispatch, FC, SetStateAction } from 'react';
import { PayMethod } from '../../types/orders'
import { Accordion } from '../Accordion';
import { Radio } from '../Radio';
import { RadioGroup } from '../RadioGroup';

interface PaymentTypeProps {
  payMethod: keyof typeof PayMethod
  setPayMethod: Dispatch<SetStateAction<keyof typeof PayMethod>>
}

export const PaymentType: FC<PaymentTypeProps> = ({ payMethod, setPayMethod }) => {
  const paymentString = {
    [PayMethod.CREDIT_CARD]: '신용카드',
    [PayMethod.NAVER_PAY]: '네이버페이',
    [PayMethod.KAKAO_PAY]: '카카오페이',
    [PayMethod.BANK_TRANSFER]: '실시간 계좌이체',
    [PayMethod.FREE_ORDER]: '',
  }
  return (
    <>
      <div className="card-2">
        <Accordion
          defaultOpen
          title={
            <div className="flex w-full justify-between">
              <h6 className="font-bold">결제수단</h6>
              <span className="mr-3 text-gray-600">{paymentString[payMethod]}</span>
            </div>
          }
          panel={
            <>
              <div className="space-y-3">
                <RadioGroup onChange={(e) => setPayMethod(e.target.value as keyof typeof PayMethod)}>
                  <Radio label="신용카드" value={PayMethod.CREDIT_CARD} checked={payMethod === PayMethod.CREDIT_CARD} />
                  <Radio label="네이버페이" value={PayMethod.NAVER_PAY} checked={payMethod === PayMethod.NAVER_PAY} />
                  <Radio label="카카오페이" value={PayMethod.KAKAO_PAY} checked={payMethod === PayMethod.KAKAO_PAY} />
                  <Radio label="실시간 계좌이체" value={PayMethod.BANK_TRANSFER} checked={payMethod === PayMethod.BANK_TRANSFER} />
                </RadioGroup>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};
