import React, { createContext, Dispatch, useContext, useReducer } from "react"
import { CartItem } from "../types/cart"

const SELECT_ITEM = 'ADD_ITEM' as const
const DESELECT_ITEM = 'REMOVE_ITEM' as const
const SYNC_ITEMS = 'SYNC_ITEMS' as const

const selectItemAction = (item: CartItem) => ({
  type: SELECT_ITEM,
  payload: item,
})

const deselectItemAction = (item: CartItem) => ({
  type: DESELECT_ITEM,
  payload: item,
})

const syncItemsAction = (items: CartItem[]) => ({
  type: SYNC_ITEMS,
  payload: items,
})

type CartAction =
  | ReturnType<typeof selectItemAction>
  | ReturnType<typeof deselectItemAction>
  | ReturnType<typeof syncItemsAction>

type CartDispatch = Dispatch<CartAction>

type CartState = {
  cartItems: CartItem[]
  selectedCartItemIds: number[]
};

const initialState: CartState = {
  cartItems: [],
  selectedCartItemIds: [],
}

function reducer(
  state: CartState = initialState,
  action: CartAction
): CartState {
  switch (action.type) {
    case SELECT_ITEM:
      return {
        ...state,
        selectedCartItemIds: state.selectedCartItemIds.concat(action.payload.id)
      }
    case DESELECT_ITEM:
      return {
        ...state,
        selectedCartItemIds: state.selectedCartItemIds.filter(id => id !== action.payload.id)
      }
    case SYNC_ITEMS:
      // 장바구니에 변경점이 생길때마다 context와 동기화
      const addedItems = action.payload
        .filter(item => !state.cartItems.some(prev => prev.id === item.id))
        .map(item => item.id)
      const removedItems = state.cartItems
        .filter(item => !action.payload.some(prev => prev.id === item.id))
        .map(item => item.id)
      const changedItems = action.payload
        .filter(newItem => {
          const oldItem = state.cartItems.find(item => item.id === newItem.id)
          return newItem.quantity !== oldItem?.quantity
        })

      if (
        addedItems.length === 0 &&
        removedItems.length === 0 &&
        changedItems.length === 0
      ) return state

      return {
        ...state,
        cartItems: action.payload,
        selectedCartItemIds: state.selectedCartItemIds
          .filter(id => action.payload.some(item => item.id === id))
          .concat(addedItems),
      }
    default:
      return state
  }
}

const CartStateContext = createContext<CartState>(initialState)
const CartDispatchContext = createContext<CartDispatch>(() => {})

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
}

export function useCartContext() {
  const state = useContext(CartStateContext);
  const dispatch = useContext(CartDispatchContext);
  return {
    selectedCartItemIds: state.selectedCartItemIds,
    selectedCartItems: state.cartItems
      .filter(item => state.selectedCartItemIds.includes(item.id)),
    checkIsSelected: (item: CartItem) => state.selectedCartItemIds.includes(item.id),
    toggleSelect: (item: CartItem) => {
      const isSelected = state.selectedCartItemIds.includes(item.id)
      if (!isSelected) {
        dispatch(selectItemAction(item));
      } else {
        dispatch(deselectItemAction(item));
      }
    },
    syncItems: (items: CartItem[]) => dispatch(syncItemsAction(items)),
  }
}
