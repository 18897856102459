import React, { FC, HTMLAttributes } from 'react';
import { Button } from '../../components/Button';
import { Header } from './Header';

import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper';
import { Section } from '../../components/Section';
import { Icon } from '../../components/Icon';
import { SectionDto } from '../../types/sections';
import { useGridCardProducts } from '../../queries/gridCardProducts';
import { GridCardProductType } from '../../types/gridCardProduct';
import { goToUrl } from './utils';

interface GridCardSectionProps {
  section: SectionDto;
}

export const GridCardSection = ({ section }: GridCardSectionProps) => {
  const { gridCardProducts } = useGridCardProducts(
    section.type as keyof typeof GridCardProductType
  );

  return (
    <div className="-mx-5 mt-12 md:mx-0">
      <div className="px-5 md:px-0">
        <Header title={section.title} description={section.description} />
      </div>

      <div className="relative">
        {gridCardProducts.length > 0 && (
          <Swiper
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            navigation={{
              nextEl: `.swiper-button-next-${section.id}`,
              prevEl: `.swiper-button-prev-${section.id}`,
            }}
            loop
            id="gray_bullet"
            modules={[Pagination, Navigation]}
            className="mySwiper"
            spaceBetween={20}
            breakpoints={{
              420: {
                width: 420,
                slidesPerView: 1,
              },
              1280: {
                width: 1280,
                slidesPerView: 3,
              },
            }}
          >
            {gridCardProducts.map((item) => (
              <SwiperSlide key={item.id}>
                <Section className="mb-10 px-5 md:px-0">
                  <div className="space-y-3 rounded-xl bg-brand-3 p-6 shadow-3xl">
                    <h6>{item.title}</h6>
                    <p className="h-24 pb-2 text-14 text-gray-500 overflow-hidden text-ellipsis">
                      {item.description}
                    </p>

                    <div className="grid grid-cols-2 gap-x-3 gap-y-5">
                      <Thumbnail
                        src={item.curationProducts[0]?.product?.images[0]}
                        onClick={() => goToUrl(item.clickUrl)}
                      />
                      <div className="grid grid-cols-2 gap-3">
                        {item.curationProducts.slice(1, 5).map((p) => (
                          <Thumbnail
                            key={p.product.id}
                            src={p.product.images[0]}
                            onClick={() => goToUrl(item.clickUrl)}
                          />
                        ))}
                      </div>
                      <div />
                      <Button
                        onClick={() => goToUrl(item.clickUrl)}
                        type="button"
                        text="모두 읽어볼래요 →"
                        className="filled-brand-2 text-13 md:text-16"
                      />
                    </div>
                  </div>
                </Section>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <button
          className={`swiper-button-prev-${section.id} wh-10 absolute -left-10 top-1/2 hidden md:block`}
        >
          <Icon.ChevronLeft />
        </button>
        <button
          className={`swiper-button-next-${section.id} wh-10 absolute -right-10 top-1/2 hidden place-content-center md:grid`}
        >
          <Icon.ChevronRight />
        </button>
      </div>
    </div>
  );
};

interface ThumbnailProps extends HTMLAttributes<HTMLDivElement> {
  src: string;
}

export const Thumbnail: FC<ThumbnailProps> = ({ src, ...props }) => {
  return (
    <div
      className="aspect-[3/4.5] overflow-hidden rounded-xl shadow-lg"
      {...props}
    >
      <img src={src} alt="" className="h-full w-full object-cover" />
    </div>
  );
};
