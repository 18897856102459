import { ArcNShop } from '../types/arcNShop'
import base from './base'

const arcNShop = {
  getArcNShop () {
    return base.get<ArcNShop>('/arc-n-shop')
  },
}

export default arcNShop
