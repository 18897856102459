import React, { FC } from 'react';
import { Icon } from '../../components/Icon';

interface HeaderProps {
  rowReverse?: boolean;
  title?: string;
  normalTitle?: string;
  boldTitle?: string; // TODO: 제거
  description?: string;
  onClick?: () => void;
}

export const Header: FC<HeaderProps> = ({
  rowReverse,
  title = '',
  description,
  onClick,
}) => {
  return (
    <div className="space-y-2 pb-4">
      <div
        onClick={onClick}
        className={`flex items-center justify-between md:justify-start ${onClick && 'cursor-pointer'}`}
      >
        <h5 className={`${rowReverse ? 'flex flex-row-reverse' : ''}`}>
          <span dangerouslySetInnerHTML={{__html: title}}></span>
        </h5>

        {onClick && <Icon.ChevronRight />}
      </div>

      <p className="text-14 text-gray-700">{description}</p>
    </div>
  );
};
