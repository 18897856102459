import React, { ChangeEvent, FC } from 'react';
import { useUser } from '../../queries/user'
import { TextField } from '../TextField';

interface PointsProps {
  value: number
  totalProductPrice: number
  shippingPrice: number
  onChangeMileage: (mileage: number) => void
  disabled: boolean
}

export const Points: FC<PointsProps> = ({
    value,
  totalProductPrice,
  shippingPrice,
  onChangeMileage,
    disabled,
}: PointsProps) => {
  const { user } = useUser()
  const { mileageAmount } = user
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let mileage = +e.target.value
    if (e.target.value.indexOf('.') > -1) {
      mileage = Math.floor(mileage)
      e.target.value = mileage.toString()
    }
    mileage = +mileage
    if (mileage > mileageAmount) {
      e.target.value = mileageAmount.toString()
      mileage = mileageAmount
    }
    const totalPayAmount = totalProductPrice + shippingPrice // 할인 전 총 결제금액
    if (mileage > totalPayAmount) {
      e.target.value = totalPayAmount.toString()
      mileage = totalPayAmount
    }
    if (mileage < 0) {
      e.target.value = "0"
      mileage = 0
    }
    onChangeMileage(mileage)
  }
  return (
    <div className={'mt-5'}>
      <p className={'mb-3'}>적립금</p>
      <div className="flex items-center space-x-3">
        <div className="w-1/2 flex-1">
          <TextField
              disabled={disabled}
            type="number"
            placeholder="적립금 사용"
              value={value > 0 ? value : undefined}
            peerStyle={false}
            onChange={onChange}
          />
        </div>
        <span className="text-14 text-brand-2">사용가능 {user.mileageAmount}p</span>
      </div>
      <p className="mt-2 text-13 text-gray-400">
        * 적립금 전액 사용 가능 / 일부 사용시 최소 결제 금액 1천원
      </p>
    </div>
  );
};
