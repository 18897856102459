import {TopBar} from "../../../components/TopBar";
import {Card} from "../../../components/Card";
import {UsersCouponDto} from "../../../types/coupons";
import {useCoupon} from "../../../queries/user";
import moment from "moment";

export const MyCouponPage = () => {
    const {coupons} = useCoupon();

    if (!coupons) return null;
    return (
        <div className="md:mx-auto md:mt-14 md:w-full md:max-w-3xl">
            <TopBar title="쿠폰"/>
            <div className={'p-5'}>
                {coupons.length === 0 && (
                    <div className={'py-40 text-center'}>
                        <p>쿠폰이 없습니다.</p>
                    </div>
                )}
                {coupons
                    .sort((a, b) => Number(a.isUsed) - Number(b.isUsed))
                    .sort((a, b) => Number(!!a.deletedAt) - Number(!!b.deletedAt))
                    .sort((a, b) => Number(new Date(a.expireAt) < new Date()) - Number(new Date(b.expireAt) < new Date()))
                    .map((coupon, index) => <CouponItem key={index} {...coupon} />)}
            </div>
        </div>
    )
}

const CouponItem = (props: UsersCouponDto) => {

    const usedTypeText = props.isUsed ? '사용완료' : new Date(props.expireAt) < new Date() ? '기간만료' : !!props.deletedAt ? '만료됨' : '';

    return (usedTypeText !== '') ? (
        <Card className={`${couponCardStyle} bg-[#F4F4F4]`}>
            <div>
                <p className={'text-[14px] text-[#959394] font-bold float-right'}>{usedTypeText}</p>
                <p className={'text-[14px] text-[#959394] font-bold'}>{props.coupon.kind}</p>
                <p className={'text-[20px] text-[#646263] font-bold my-1'}>{props.coupon.title}</p>
                <p className={'text-[12px] text-[#959394]'}>{props.coupon.description}</p>
                <p className={'text-[13px] text-[#959394] font-bold mt-2'}>사용
                    기간 {moment(props.createdAt).format('YYYY.MM.DD')} ~ {moment(props.expireAt).format('YYYY.MM.DD')}</p>
            </div>
        </Card>
    ) : (
        <Card className={`${couponCardStyle}`}>
            <div>
                <p className={'text-[14px] text-[#959394] font-bold'}>{props.coupon.kind}</p>
                <p className={'text-[20px] font-bold my-1'}>{props.coupon.title}</p>
                <p className={'text-[12px] text-[#959394]'}>{props.coupon.description}</p>
                <p className={'text-[13px] text-[#FCA311] font-bold mt-2'}>사용
                    기간 {moment(props.createdAt).format('YYYY.MM.DD')} ~ {moment(props.expireAt).format('YYYY.MM.DD')}</p>
            </div>
        </Card>
    );
}

const couponCardStyle = `p-4 mb-4`;
