import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../../components/Icon';
import { TopBar } from '../../../components/TopBar';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useReviews } from '../../../queries/reviews';
import { displayProductInfo } from '../../../utils';

export const MyReviewsPage = () => {
  const { push } = useHistory();
  const { reviews } = useReviews();
  return (
    <div className="md:mx-auto md:mt-14 md:w-full md:max-w-3xl">
      <TopBar title="내가 쓴 리뷰" />

      {reviews.length === 0 && (
          <div className="flex h-[36vh] w-full items-center justify-center">
            아직 작성한 리뷰가 없습니다.
          </div>
      )}
      {reviews?.map((review) => (
        <div className="mx-5 mb-5 border-b-2 border-stone-100 pb-10 md:mx-0 md:mb-12">
          <div className="card-2 py-5">
            <div className="mt-4 flex space-x-3">
              <img
                src={review.product.images?.[0]}
                alt={review.product.name}
                className="h-32 w-20 flex-none cursor-pointer overflow-hidden rounded-xl bg-brand-3"
                onClick={() => push(`/product/${review.productId}`)}
              />
              <div className="flex flex-col">
                <h6 className="">{review.product.name}</h6>
                <span className="text-14 text-gray-500">
                  {displayProductInfo(review.product)}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-5 flex justify-between">
            <div className="flex items-center space-x-4">
              <span className="text-14 text-gray-600">
                {utcToLocalFormat(review.createdAt, MomentFormat.Date)}
              </span>
              <div className="flex space-x-1">
                <Icon.Star className="wh-4 fill-current text-yellow-300" />
                <span className="text-12 text-gray-600">{review.score}</span>
              </div>
            </div>
            <button
              className="text-14 text-brand-2"
              onClick={() => push(`/mypage/reviews/edit/${review.id}`)}
            >
              수정
            </button>
          </div>

          <p className="mt-2">{review.content}</p>
        </div>
      ))}
    </div>
  );
};
