import { Curation } from '../types/curation'
import base from './base'

const curations = {
  getCuration (id: string) {
    return base.get<Curation>(`/curations/${id}`)
  },
}

export default curations
