import { Keyword, KeywordType } from '../types/keyword'
import base from './base'

const keywords = {
  getKeywords (type: KeywordType) {
    return base.get<Keyword[]>('keywords', {
      params: {
        type,
      },
    })
  },
}

export default keywords
