import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';

export const CartNonMemberSuccessPage = () => {
  const { push } = useHistory();
  return (
    <div className="p-5 md:mx-auto md:mt-14 md:w-full md:max-w-lg">
      <h4 className="mb-4 font-medium">주문완료</h4>

      <div className="card-2 space-y-2">
        <div className="mb-4 flex items-center justify-between border-b border-gray-100 pb-4">
          <h6 className="font-medium">총 결제금액</h6>
          <h6 className="font-bold">₩115,000</h6>
        </div>

        <div className="flex items-center justify-between">
          <span className="">주문일시</span>
          <span className="text-gray-500">2021-06-30 21:38:00</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="">주문번호</span>
          <button className="flex items-center space-x-2 text-gray-500">
            <span>2021063021228877</span> <Icon.Copy />
          </button>
        </div>
        <div className="flex items-center justify-between">
          <span className="">상품</span>
          <span className="text-gray-500">그러라 그래 외 2</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="">결제수단</span>
          <span className="text-gray-500">신용카드</span>
        </div>
      </div>

      <p className="pt-6 text-center text-14 text-gray-500">
        비회원의 경우 주문조회시 주문번호가 필요하니,
        <br />
        주문번호를 복사해주세요.
      </p>

      <Button
        onClick={() => push('/')}
        text="계속 쇼핑하기"
        className="filled-gray-900 mt-12 w-full"
      />
    </div>
  );
};
