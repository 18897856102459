import moment from 'moment';
import React, { FC } from 'react';
import { TopBar } from '../../../components/TopBar';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useMileageHistories } from '../../../queries/mileageHistories';
import { useUser } from '../../../queries/user';
import { MileageHistory } from '../../../types/mileageHistory';

export const MyMileagePage = () => {
  const { user } = useUser();
  const { mileageHistories } = useMileageHistories(user.id);
  return (
    <div className="mt-8 md:mx-auto md:w-full md:max-w-3xl">
      <TopBar title="마일리지" />

      <div className="mt-4 flex items-center justify-between bg-brand-3 p-6">
        <span className="text-13">현재 보유 마일리지</span>
        <h5 className="font-bold">{user.mileageAmount?.toLocaleString()}원</h5>
      </div>

      {mileageHistories.map((history) => (
        <PointsItem history={history} />
      ))}
    </div>
  );
};

interface PointsItemProps {
  history: MileageHistory;
}

export const PointsItem: FC<PointsItemProps> = ({ history }) => {
  // type fix as number
  const givenMileage = history.givenMileage || 0;
  const useMileage = history.useMileage || 0;

  // declare values
  const amount = givenMileage || (useMileage * -1);
  const isPositive = amount > 0;
  const isHighlight = amount < 0;

  return (
    <div id={`mileage_history-${history.id}`} className="border-b border-gray-100 p-5">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <span className="text-12 text-gray-600">
            {utcToLocalFormat(history.date, MomentFormat.Date)}
          </span>
          <span className="text-14">{history.memo}</span>
        </div>
        <span
          className={`${
            isHighlight ? 'text-brand-1' : ''
          } font-bold text-gray-700`}
        >
          {isPositive ? '+' : ''}
          {amount}P
        </span>
      </div>
    </div>
  );
};
