import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon';

interface CartButtonProps {
  itemsCount: number
}

export const CartButton: FC<CartButtonProps> = ({ itemsCount }) => {
  return (
    <>
      <Link to="/cart" className="relative">
        {true && (
          <div className="filled-brand-1 wh-4 absolute left-0 grid place-content-center rounded-full text-8">
            {itemsCount}
          </div>
        )}
        <Icon.ShoppingCart className="ml-2" />
      </Link>
    </>
  );
};
