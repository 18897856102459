import { useMutation, useQuery } from 'react-query'
import API from '../api'
import { CreateShippingAddress, ShippingAddress } from '../types/shippingAddress'

export const useShippingAddresses = () => {
  const { data, isLoading, refetch } = useQuery(
    'shippingAddresses',
    async () => await API.getShippingAddresses()
  )
  if (isLoading || !data?.data) {
    return {
      isLoading,
      shippingAddresses: [] as ShippingAddress[]
    }
  }

  const shippingAddresses = data.data.items
    .sort((a, b) => {
      if (a.isDefault) return -1
      else if (b.isDefault) return 1
      return b.id - a.id
    })

  return {
    shippingAddresses,
    isLoading,
    refetch,
  }
}

export const useCreateShippingAddress = (onSuccess: (data: ShippingAddress) => void) => {
  const { refetch } = useShippingAddresses()
  const { mutate: createShippingAddress } = useMutation(
    (data: CreateShippingAddress) =>
      API.postShippingAddress(data),
    {
      onSuccess: (res) => {
        refetch!()
        onSuccess(res.data)
      }
    }
  )
  return {
    createShippingAddress,
  }
}

export const useUpdateShippingAddress = (onSuccess: () => void) => {
  const { refetch } = useShippingAddresses()
  const { mutate: updateShippingAddress } = useMutation(
    ({id, data}: {id: number, data: CreateShippingAddress}) =>
      API.patchShippingAddress(id, data),
    {
      onSuccess: () => {
        refetch!()
        onSuccess()
      }
    }
  )
  return {
    updateShippingAddress,
  }
}