import React, { FC } from 'react';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment'
import { Review } from '../types/review'
import { maskUserName } from '../utils'
import { Icon } from './Icon';

interface ProductReviewItemProps {
  review: Review
}

export const ProductReviewItem: FC<ProductReviewItemProps> = ({ review }) => {
  return (
    <div className="border-b border-gray-100 pb-5">
      <div className="flex justify-between  text-14">
        <div className="flex items-center space-x-2">
          <span className="">{review.user ? maskUserName(review.user.name) : ''}</span>
          <div className="flex items-center space-x-1">
            <Icon.Star className="wh-4 fill-current text-yellow-300" />
            <span className="text-gray-700">{review.score}</span>
          </div>
        </div>
        <span className="text-gray-500">
          {utcToLocalFormat(review.createdAt, MomentFormat.Date)}
        </span>
      </div>

      <p className="mt-2 text-gray-700">
        {review.content}
      </p>
    </div>
  );
};
