import { Paginated } from '../types/api'
import { Product } from '../types/products';
import base from './base'

const eventTags = {
  getEventTagProducts (id: number) {
    return base.get<Paginated<Product>>(`/event-tags/${id.toString()}/products`, {
      params: {
        itemsPerPage: 8,
        sortBy: 'random'
      }
    });
  },
}

export default eventTags
