import { Paginated } from '../types/api'
import { Comment, CreateCommentRequestbody } from '../types/comments'
import base from './base'

const comments = {
  getComments (postingId: string, page = 1) {
    return base.get<Paginated<Comment>>(`/comments`, {
      params: {
        'where[postingId]': postingId,
        page,
        'order[createdAt]': 'DESC',
        itemsPerPage: 10,
      },
    });
  },
  postComment (body: CreateCommentRequestbody) {
    return base.post('/comments', body);
  },
  patchComment (id: number, body: CreateCommentRequestbody) {
    return base.patch(`/comments/${id.toString()}`, body);
  },
  deleteComment (id: number) {
    return base.delete(`/comments/${id.toString()}`);
  },
}

export default comments;
