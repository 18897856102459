import React, { FC } from 'react';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment'
import { modalState } from '../plugins/ridge'
import { useDeleteComment, useEditComment } from '../queries/comments'
import { useUser } from '../queries/user'
import { Comment } from '../types/comments'
import { maskUserName } from '../utils'

interface CommentItemProps {
  comment: Comment;
}

export const CommentItem: FC<CommentItemProps> = ({ comment }) => {
  const { user } = useUser();
  const isMe = user.id === comment.user.id;
  const { editComment } = useEditComment(comment.id);
  const { deleteComment } = useDeleteComment(comment.postingId, comment.id);

  const onEdit = () => {
    modalState.set({
      title: '코멘트 수정',
      input: true,
      inputInitValue: comment.comment,
      primaryButtonText: '확인',
      primaryClick: (input) => {
        editComment({
          comment: input,
          postingId: comment.postingId,
        })
      },
      secondaryButtonText: '취소',
    });
  };

  const onDelete = () => {
    modalState.set({
      title: '코멘트 삭제',
      bodyText: '정말 삭제하시겠습니까?',
      primaryButtonText: '확인',
      primaryClick: () => deleteComment(),
      secondaryButtonText: '취소',
    });
  };

  return (
    <div className="space-y-2 border-b border-gray-100 pb-4">
      <div className="flex justify-between">
        <div className="text-13 font-bold text-gray-600">
          {maskUserName(comment.user.name)}
        </div>
        <div className="text-12 text-gray-500">
          {utcToLocalFormat(comment.createdAt, MomentFormat.Date)}
        </div>
      </div>

      <p className="text-14 text-gray-900">{comment.comment}</p>

      {isMe && (
        <div className="flex flex-row-reverse space-x-4 space-x-reverse text-14">
          <button
            className="text-red-500"
            onClick={() => onDelete()}
          >
            삭제
          </button>
          <button
            className="text-gray-500"
            onClick={() => onEdit()}
          >
            수정
          </button>
        </div>
      )}
    </div>
  );
};
