import moment from 'moment'
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { CartCard } from '../../components/cart/CartCard';
import { DesktopCartCard } from '../../components/cart/DesktopCartCard';
import { PromotionModal } from '../../components/modal/PromotionModal'
import { useCartContext } from '../../context/cart';
import { useAddProductToCart, useCart } from '../../queries/cart';
import { usePromotion } from '../../queries/promotions'
import { useShipmentsPricingByProducts } from '../../queries/shipments';
import { Product } from '../../types/products'
import {toast} from "react-toastify";

export const Delivery = () => {
  const { push } = useHistory();
  const { online } = useCart();
  const books = online.books;
  const generals = online.generals;

  const { syncItems, selectedCartItems, selectedCartItemIds } =
    useCartContext();
  const { calculrateResult } = useShipmentsPricingByProducts(false, selectedCartItems);
  const priceSum = selectedCartItems.reduce(
    (sum, curr) => sum + curr.totalPrice,
    0
  );
  useEffect(() => {
    // 카트 상품 상태 바뀌면 동기화
    syncItems([...books, ...generals]);
  }, [books, generals, syncItems]);

  const { promotion } = usePromotion('1000promo');
  const isInStockPromotionProducts =
    promotion.products.length > 0 &&
    promotion.products.some(product =>
      !product.isSoldOut
    );
  const promotionItem = generals.find(item => item.product.promotionId > 0);

  const checkPromotion = () => {
    const soldOutItem = selectedCartItems.find(item => item.product.isSoldOut);
    if (!!soldOutItem) {
      toast.error(`${soldOutItem.product.name} 상품은 품절되었습니다. 장바구니에서 제거해주세요.`);
    } else if (isInStockPromotionProducts && !promotionItem && priceSum >= 20000) {
      setShowModal(true);
    } else {
      goToCartPayPage();
    }
  };

  const goToCartPayPage = (id?: number) => {
    const cartItemIds = [...selectedCartItemIds];
    if (id) {
      cartItemIds.push(id);
    }
    sessionStorage.setItem(
      'cartPayItemIds',
      JSON.stringify(cartItemIds)
    );
    push('/cart/pay');
  };

  const { addProductToCart } = useAddProductToCart();
  const [showModal, setShowModal] = useState(false);
  const onConfirmPromotionModal = async (product?: Product) => {
    setShowModal(false);
    if (!product) return;
    const { data } = await addProductToCart({ product });
    goToCartPayPage(data.id);
  };
  const onClosePromotionModal = () => {
    setShowModal(false);
    goToCartPayPage();
  };

  const overPromotionPriceSum = priceSum >= (20000 - (promotionItem?.product?.price ?? 0))
  const canPromotionOrder = !promotionItem || overPromotionPriceSum;
  const canOrder = selectedCartItems.length > 0 && canPromotionOrder;

  return (
    <>
      <PromotionModal
        open={showModal}
        onConfirm={onConfirmPromotionModal}
        onClose={onClosePromotionModal}
      />
      <div className="mb-12 flex flex-col md:flex-row md:space-x-8">
        <div className="flex-1">
          <div className="bg-[#C2CED1] py-2 text-center text-13 font-bold">
            도서상품은 1만 원, 일반상품은 3만 원 이상 구매 시 무료배송!
          </div>

          <div className="flex items-center justify-between bg-brand-3 p-5">
            <h6 className="font-bold">도서상품</h6>
            <span className="text-14 text-gray-500">
              {calculrateResult?.BOOK?.shippingPrice > 0
                ? `배송비 +${calculrateResult.BOOK.shippingPrice}원`
                : '무료배송'}
            </span>
          </div>

          {books?.length > 0 ? (
            <>
              {/* Mobile UI */}
              <div className="m-5 grid grid-cols-2 gap-x-5 gap-y-8 md:hidden">
                {books.map((cartItem) => (
                  <CartCard key={cartItem.id} item={cartItem} />
                ))}
              </div>

              {/* Desktop UI */}
              <div className="my-8 hidden space-y-8 md:block">
                {books.map((cartItem) => (
                  <DesktopCartCard key={cartItem.id} item={cartItem} />
                ))}
              </div>
            </>
          ) : (
            <>
              <p className="my-10 grid place-content-center">
                장바구니가 비었습니다.
              </p>
            </>
          )}

          <div className="flex items-center justify-between bg-brand-3 p-5">
            <h6 className="font-bold">일반상품</h6>
            <span className="text-14 text-gray-500">
              {calculrateResult?.GENERAL?.shippingPrice > 0
                ? `배송비 +${calculrateResult.GENERAL.shippingPrice}원`
                : '무료배송'}
            </span>
          </div>

          {generals?.length > 0 ? (
            <>
              {/* Mobile UI */}
              <div className="m-5 grid grid-cols-2 gap-x-5 gap-y-8 md:hidden">
                {generals.map((cartItem) => (
                  <CartCard key={cartItem.id} item={cartItem} />
                ))}
              </div>

              {/* Desktop UI */}
              <div className="my-8 hidden space-y-8 md:block">
                {generals.map((cartItem) => (
                  <DesktopCartCard key={cartItem.id} item={cartItem} />
                ))}
              </div>
            </>
          ) : (
            <>
              <p className="my-10 grid place-content-center">
                장바구니가 비었습니다.
              </p>
            </>
          )}
        </div>

        <div className="md:w-1/4">
          <div className="m-5 space-y-3 md:m-0 md:rounded-xl md:border md:border-gray-100 md:p-5">
            <h5 className="hidden font-bold md:block">결제 금액</h5>
            <div className="flex justify-between text-15 text-gray-600">
              <span className="">총 주문금액</span>
              <span className="">{priceSum.toLocaleString()}원</span>
            </div>
            <div className="flex justify-between pb-4 text-15 text-gray-600">
              <span className="">배송비</span>
              <span className="">
                {calculrateResult.totalShippingPrice.toLocaleString()}원
              </span>
            </div>

            <hr className="border-gray-100" />

            <div className="flex justify-between pt-4 text-17 font-bold md:text-20">
              <span className="">총 결제금액</span>
              <span className="">
                {(
                  priceSum + calculrateResult.totalShippingPrice
                ).toLocaleString()}
                원
              </span>
            </div>

            <hr className="border-gray-100" />

            <p className="text-12 text-gray-500">
              * 일반상품의 경우 각 브랜드에서 직접 배송하여, 배송이 따로 이뤄질
              수 있습니다. <br />* 적립금은 배송이 완료된 시점에 적립됩니다.
            </p>
          </div>

          <div className="mx-5 flex flex-col space-y-3 pt-8 md:mx-0">
            <Button
              disabled={!canOrder}
              onClick={checkPromotion}
              text="주문하기"
              className="filled-gray-900"
            />
            {/* <Button
              to="/cart/non-member"
              text="비회원 주문하기"
              className="outlined-gray-900"
            /> */}
            {!overPromotionPriceSum && isInStockPromotionProducts && (
              <p className="text-12 text-red-500">
                이벤트 상품은 2만원 이상 구매 시 함께 구매할 수 있습니다.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
