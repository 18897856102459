import moment from 'moment';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../components/Icon';
import { TopBar } from '../../components/TopBar';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { useOrders } from '../../queries/orders';
import { Order } from '../../types/orders';

export const OrderHistoryPage = () => {
  const { push } = useHistory();
  const { orders, fetchNextPage, hasNextPage } = useOrders();
  return (
    <div className="mt-8 md:mx-auto md:w-full md:max-w-3xl">
      <TopBar title="주문 내역" />

      {orders?.length > 0 ? (
        <InfiniteScroll
          dataLength={orders.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<>로딩중</>}
          className="mx-5 space-y-6 md:mx-0"
        >
          {orders.map((order) => (
            <OrderHistoryCard
              key={order.id}
              order={order}
              onClick={() => push(`/order-history/${order.id}`)}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <div className="flex h-[36vh] w-full items-center justify-center">
          주문내역이 없습니다.
        </div>
      )}
    </div>
  );
};

interface OrderHistoryCardProps {
  onClick: () => void;
  order: Order;
}

export const OrderHistoryCard: FC<OrderHistoryCardProps> = ({
  order,
  onClick,
}) => {
  return (
    <div className="card-2">
      <button onClick={onClick} className="flex w-full justify-between">
        <h6 className="font-medium">주문번호 {order.code}</h6>
        <Icon.ChevronRight />
      </button>

      <div className="mt-4 flex space-x-3">
        <img
          src={order.image}
          alt=""
          className="h-24 w-16 rounded-xl bg-brand-3 object-cover"
        />

        <div className="flex flex-col space-y-2">
          <p className="text-10 text-gray-500">
            {utcToLocalFormat(order.createdAt, MomentFormat.Date)} {order.id}
          </p>
          <span className="text-14">{order.title}</span>
          <h6 className="font-semibold">
            {order.payment.totalPayAmount.toLocaleString()}원
          </h6>
        </div>
      </div>
    </div>
  );
};
