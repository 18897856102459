import { AxiosResponse } from 'axios'
import moment from 'moment'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import API from '../api'
import { Curation } from '../types/curation'

export const useCuration = (id: string, sortBy: string) => {
  const { data, isLoading } = useQuery(
    ['curation', id],
    async () => await API.getCuration(id),
    {
      select: useCallback((data: AxiosResponse<Curation>) => {
        const curation = data.data;
        if (!curation) return;
        return {
          curation: {
            ...curation,
            products: curation.products
              .filter(product => !product.product.isDisplayHidden)
              .sort((a, b) => {
                if (sortBy === 'recent') {
                  const aDateStr = a.product.type === 'BOOK'
                    ? a.product.bookInfo?.publishedAt
                    : a.product.createdAt;
                  const bDateStr = b.product.type === 'BOOK'
                    ? b.product.bookInfo?.publishedAt
                    : b.product.createdAt;
                  return moment(aDateStr).isAfter(bDateStr)
                    ? -1 : 1;
                } else if (sortBy === 'popular') {
                  return a.product.categoryRank - b.product.categoryRank;
                }
                return a.order - b.order;
              })
          }
        }
      }, [sortBy]),
    }
  )
  if (isLoading || !data) {
    return {
      curation: {} as Curation
    }
  }

  return {
    ...data
  }
}
