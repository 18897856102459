import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import useAuth from '../../hooks/useAuth'
import { LoginBody } from '../../types/user'

export const LoginPage = () => {
  const { login } = useAuth();
  // const { login } = useLogin()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginBody>();

  return (
    <>
      <div className="mx-auto w-full max-w-md md:my-12">
        <TopBar title="로그인" />

        <form
          className="flexflex-col space-y-9 px-5 pt-6"
          onSubmit={handleSubmit((data) => login(data))}
        >
          <TextField
            label="이메일을 입력해주세요"
            type="email"
            placeholder="이메일을 입력해주세요"
            helper={errors.email?.message}
            {...register('email', { required: '이메일을 입력해주세요' })}
          />

          <TextField
            label="비밀번호를 입력해주세요"
            type="password"
            placeholder="비밀번호를 입력해주세요"
            helper={errors.password?.message}
            {...register('password', { required: '비밀번호를 입력해주세요' })}
          />

          <div className="flex flex-col space-y-3 pt-6">
            <Button
              text="로그인"
              className="filled-gray-900"
              onClick={handleSubmit((data) => login(data))}
            />
            <Button
              type="button"
              to="/signup"
              text="회원가입"
              className="outlined-gray-900"
            />
          </div>

          <div className="mx-auto text-center text-14 text-gray-500">
            <Link to="/username/find" className="mr-3">
              아이디 찾기
            </Link>{' '}
            |{' '}
            <Link to="/passwords/find" className="mx-3">
              비밀번호 변경
            </Link>{' '}
            {/* |{' '}
            <Link to="/non-member" className="ml-3">
              비회원 주문조회
            </Link> */}
          </div>
        </form>
      </div>
    </>
  );
};
