import { FC } from 'react';
import { useBranches } from '../queries/branches';
import { formatStoreRunningTime, getMomentDailyRunning } from '../utils'

interface SelectedStoreInfoProps {
  currentBranchCode: string;
  onClick: () => void;
}

export const SelectedStoreInfo: FC<SelectedStoreInfoProps> = ({
  currentBranchCode,
  onClick,
}) => {
  const { branches } = useBranches();
  const currentBranch = branches.find(branch => branch.code === currentBranchCode)!;
  if (!currentBranch) return <></>;
  const { name, address, tel, dailyRunning } = currentBranch;
  const { startTime, finishTime } = getMomentDailyRunning(dailyRunning);
  return (
    <div className="mx-5 rounded-2xl p-4 shadow-3xl md:mx-0">
      <div className="flex items-center justify-between">
        <h6 className="font-bold">{name}</h6>
        <button onClick={onClick} className="text-12 text-brand-2">
          지점 변경
        </button>
      </div>

      <p className="pt-3 text-13 text-gray-500">
        {formatStoreRunningTime(startTime, finishTime)}
        <br /> {address}
        <br /> {tel}
      </p>
    </div>
  );
};
