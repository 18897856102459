import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import API from '../api';
import { Partnership } from '../types/partnership';

export const usePartnership = () => {
  const { data, isLoading } = useQuery(
    ['partnerships', 'one'],
    async () => await API.getPartnership(),
    {
      select: useCallback((data: AxiosResponse<Partnership>) => {
        const partnership = data.data;
        if (!partnership) return;
        return {
          partnership: {
            ...partnership,
          },
        };
      }, []),
    }
  );
  if (isLoading || !data) {
    return {
      partnership: {} as Partnership,
    };
  }

  return {
    ...data,
  };
};
