import moment from 'moment';
import 'moment/locale/ko';

// example utc: '2021-05-28T08:21:28.475Z'

export enum MomentFormat {
  /** 2021-05-28 17:21:10 */
  DateTime = 'YYYY-MM-DD HH:mm:ss',
  /** 2021-05-28 */
  Date = 'YYYY-MM-DD',
}

export function utcToLocalFormat(utc: string, format?: MomentFormat) {
  return moment.utc(utc).local().format(format);
}

/** 3시간 전 */
export function utcToLocalFromNow(utc: string) {
  return moment.utc(utc).local().fromNow();
}
