import { Disclosure, Transition } from '@headlessui/react';
import React, { FC } from 'react';
import { Icon } from './Icon';

interface AccordionProps {
  title: any;
  panel: any;
  defaultOpen?: boolean
}

export const Accordion: FC<AccordionProps> = ({ title, panel, defaultOpen = false }) => {
  return (
    <>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <div className="">
            <Disclosure.Button
              className={`${open && 'mb-4'} flex w-full justify-between`}
            >
              {title}
              <Icon.ChevronDown
                className={` ${
                  open ? 'rotate-180 transition duration-75 ease-in-out' : ''
                }`}
              />
            </Disclosure.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>{panel}</Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </>
  );
};
