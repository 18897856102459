import { GridCardProduct, GridCardProductType } from '../types/gridCardProduct'
import base from './base'

const gridCardProducts = {
  getGridCardProducts (type: keyof typeof GridCardProductType) {
    return base.get<GridCardProduct[]>('grid-card-products', {
      params: {
        type,
      },
    })
  },
}

export default gridCardProducts
