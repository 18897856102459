import { Paginated } from '../types/api'
import { CalculateOrderRequest, CalculateOrderResponse, CreateOrder, Order } from '../types/orders'
import base from './base'

const orders = {
  getOrders (page = 1) {
    return base.get<Paginated<Order>>('/orders', {
      params: {
        page,
        'order[id]': 'DESC'
      }
    })
  },
  postOrder (body: CreateOrder) {
    return base.post<Order>('/orders', body)
  },
  getOrder (id: number) {
    return base.get<Order>(`/orders/${id.toString()}`)
  },
  postOrderCalculate (body: CalculateOrderRequest) {
    return base.post<CalculateOrderResponse>('/orders/calculate', body)
  },
  postOrderCancel (id: string) {
    return base.post<CalculateOrderResponse>(`/orders/${id}/cancel`)
  },
}

export default orders
