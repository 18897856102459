import { CreatePartnershipForm } from '../types/partnershipForm';
import base from './base';

const partnershipForms = {
  postPartnershipForm(body: CreatePartnershipForm) {
    return base.post(`/partnership-forms`, body);
  },
};

export default partnershipForms;
