import { useQueries } from "react-query";
import API from "../api";
import { EventTag } from "../types/eventTag";
import { Product } from "../types/products";

interface EventTagProduct extends Product {
  eventTagId: number
}

export const useEventTagProducts = (eventTags: EventTag[]) => {
  const fetchEventTagProducts = (tag: EventTag) => async () => {
    const data = await API.getEventTagProducts(tag.id);
    if (!data.data) return [] as Product[];
    return data.data.items;
  }
  const queries = useQueries(
    eventTags.map((tag) => ({
      queryKey: ['eventTags', tag.id, 'products'],
      queryFn: fetchEventTagProducts(tag),
    }))
  );

  const isLoading = queries.some(data => data.isLoading);
  if (isLoading) return { products: [] };

  return {
    products: queries
      .flatMap((data, index) => {
        if (!data.data) return;
        return data.data.map((product: Product) => ({
          ...product,
          eventTagId: eventTags[index].id
        }))
      })
      .filter(_ => _) as EventTagProduct[],
  };
};
