import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import API from '../api'
import { Posting } from '../types/posting'

export const usePosting = (id: string) => {
  const { data, isLoading } = useQuery(
    ['posting', id],
    async () => await API.getPosting(id),
    {
      select: useCallback((data: AxiosResponse<Posting>) => {
        const posting = data.data;
        if (!posting) return;
        return {
          posting: {
            ...posting,
            products: posting.products
              .filter(product => !product.product.isDisplayHidden)
              .sort((a, b) => a.order - b.order),
          }
        }
      }, []),
    }
  )
  if (isLoading || !data) {
    return {
      posting: {} as Posting
    }
  }

  return {
    ...data,
  }
}
