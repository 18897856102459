import React, { FC, useState } from 'react';
import { toast } from 'react-toastify'
import { useUpdateShippingAddress } from '../queries/shippingAddress'
import { CreateShippingAddress, ShippingAddress } from '../types/shippingAddress'
import { AddressFormCard } from './AddressFormCard'
import { CheckCircle } from './CheckCircle'

interface AddressCardProps {
  address?: ShippingAddress;
  checked?: (address: ShippingAddress) => boolean;
  onCheckAddress?: (id: number) => void;
}

export const AddressCard: FC<AddressCardProps> = ({
  address,
  checked,
  onCheckAddress,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const { updateShippingAddress } = useUpdateShippingAddress(() => {
    setIsEdit(false)
    toast.success('배송지가 변경되었습니다.')

  })
  if (!address) return <></> // TODO: required로 변경

  const onChange = () => {
    if (!onCheckAddress) return
    if (!checked || checked(address)) return
    onCheckAddress(address.id)
  }

  const onSave = (data: CreateShippingAddress) => {
    updateShippingAddress({
      id: address.id,
      data,
    })
  }
  return (
    <>
      {isEdit ? (
        <AddressFormCard
          onSave={onSave}
          onCancel={() => setIsEdit(false)}
          address={address}
        />
      ) : (
        <>
          {checked && (
            <CheckCircle
              checked={checked(address)}
              onClick={onChange}
            />
          )}
          <div className="card-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between space-x-3">
                <h6 className="font-bold">{address.title}</h6>

                {address.isDefault && (
                  <div className="rounded-full border-2 border-brand-2 px-2 py-1 text-14 font-bold text-brand-2">
                    기본배송지
                  </div>
                )}
              </div>

              <button onClick={() => setIsEdit(true)} className="text-13 text-gray-600">
                수정
              </button>
            </div>

            <p className="pt-4 text-13 text-gray-600">
              {address.address} {address.addressDetail} <br />
              {address.publicDoorPassword} | {address.receiverName} | {address.receiverTel}
            </p>
          </div>
        </>
      )}
    </>
  );
};
