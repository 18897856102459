import { PhoneAuthCompare, PhoneAuthSession } from '../types/sms'
import base from './base'

const sms = {
  postPhoneAuthSend (phone: string) {
    return base.post<PhoneAuthSession>(`/sms/phoneAuth/${phone}/send`)
  },
  postPhoneAuthCompare (phone: string, authNumber: number) {
    return base.post<PhoneAuthCompare>(`/sms/phoneAuth/${phone}/auth`, {
      authNumber,
    })
  },
}

export default sms
