import { useQuery } from 'react-query'
import API from '../api'
import { Keyword, KeywordType } from '../types/keyword'

export const useKeywords = (type: KeywordType) => {
  const { data, isLoading } = useQuery(
    ['keywords', type],
    async () => await API.getKeywords(type),
    {
      select: (data) => {
        const keywords = data.data
        if (!keywords) return
        return {
          keywords: keywords
            .filter(keyword => keyword.keyword)
            .sort((a, b) => a.order - b.order)
        }
      }
    }
  )
  if (isLoading || !data) {
    return {
      keywords: [] as Keyword[]
    }
  }

  return {
    ...data,
  }
}
