import React, { FC, useState } from 'react';
import { Icon } from './Icon';
import { ReactComponent as BookmarkFilled } from '../assets/icons/icon-bookmark-filled-2.svg';
import { useAddProductToBookmark, useBookmarkProducts, useRemoveProductToBookmark } from '../queries/products'
import { useModal } from './modal/Modal'
import useAuth from '../hooks/useAuth'

interface BookmarkButtonProps {
  productId?: number
}

export const BookmarkButton: FC<BookmarkButtonProps> = ({ productId }) => {
  const { bookmarks } = useBookmarkProducts()
  const { addProductToBookmark } = useAddProductToBookmark(productId ?? 1)
  const { removeProductToBookmark } = useRemoveProductToBookmark(productId ?? 1)
  const { authenticated } = useAuth()
  const { requireLogin } = useModal()
  const isBookmarked = bookmarks.some(bookmark => bookmark.id === productId)
  const onClickBookmark = () => {
    if (!authenticated) {
      return requireLogin('북마크')
    }
    if (isBookmarked) {
      removeProductToBookmark()
    } else {
      addProductToBookmark()
    }
  }
  return (
    <>
      <button
        onClick={onClickBookmark}
        className={`grid h-12 w-14 place-content-center rounded-xl border border-gray-100 p-2`}
      >
        {isBookmarked ? (
          <>
            <BookmarkFilled className="w-3.5 stroke-current text-gray-900" />
          </>
        ) : (
          <>
            <Icon.Bookmark className="" />
          </>
        )}
      </button>
    </>
  );
};
