import { useMutation } from 'react-query';
import { CreatePartnershipForm } from '../types/partnershipForm';
import API from '../api';
import { useModal } from '../components/modal/Modal';

export const useCreatePartnershipFrom = () => {
  const { partnershipFormSuccessModal } = useModal();

  const { mutateAsync: createPartnershipForm } = useMutation(
    (data: CreatePartnershipForm) => API.postPartnershipForm(data),
    {
      onSuccess: () => {
        partnershipFormSuccessModal();
      },
    }
  );
  return {
    createPartnershipForm,
  };
};
