import React, { forwardRef, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassname?: string;
  helper?: string;
  peerStyle?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className = '',
      label,
      labelClassname = '',
      peerStyle = true,
      helper,
      ...props
    },
    ref
  ) => {
    const [id] = useId(1, 'textfield');

    return (
      <div className="label-col relative">
        <input
          ref={ref}
          id={id}
          className={`${peerStyle ? 'peer-textfield peer' : 'textfield'}  ${
            helper && 'border-error focus:border-error'
          }`}
          // className={`textfield ${helper ? 'border-error' : ''} ${className}`}
          {...props}
        />
        {label && (
          <Label
            htmlFor={id}
            text={label}
            // className={labelClassname}
            className="peer-label"
          />
        )}
        {helper && <p className="text-sm text-error">{helper}</p>}
      </div>
    );
  }
);
