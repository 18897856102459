import React, { useEffect, useMemo, useState } from 'react';
import { MerchProductPage } from './MerchProductPage';
import { BookProductPage } from './BookProductPage';
import { IdParams } from '../../types/router';
import { useParams } from 'react-router-dom';
import {
  useProduct,
  useProductEventTags,
  useProductReviews,
} from '../../queries/products';
import Meta from '../../components/Meta';
import { TopBar } from '../../components/TopBar';
import { Button } from '../../components/Button';
import { ProductReviewItem } from '../../components/ProductReviewItem';
import { useModal } from '../../components/modal/Modal';
import { useProductReviewable } from '../../queries/reviews';
import { Section } from '../../components/Section';
import { HorizontalScrollView } from '../../components/HorizontalScrollView';
import { KeywordButton } from '../../components/KeywordButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductCard } from '../../components/ProductCard';
import { Navigation } from 'swiper';
import { Icon } from '../../components/Icon';
import { useEventTagProducts } from '../../queries/eventTags';
import { EventTag } from '../../types/eventTag';

export const ProductPage = () => {
  const { id } = useParams<IdParams>();
  const { product } = useProduct(id);

  useEffect(() => {
    // 최근 본 상품 목록에 현재 상품 저장
    const productIdsRecentlyViewedStr = localStorage.getItem(
      'productIdsRecentlyViewed'
    );
    if (!productIdsRecentlyViewedStr) {
      localStorage.setItem('productIdsRecentlyViewed', `["${id}"]`);
      return;
    }

    const productIdsRecentlyViewed: string[] = JSON.parse(
      productIdsRecentlyViewedStr
    );
    localStorage.setItem(
      'productIdsRecentlyViewed',
      JSON.stringify([
        id,
        ...productIdsRecentlyViewed
          .filter((productId) => productId !== id)
          .slice(0, 24),
      ])
    );
  }, [id]);

  if (!product.type) return <></>;

  return (
    <>
      <Meta
        title={`아크앤북 - ${product.name}`}
        description={product.contentText ?? ''}
        canonical={`product/${product.id}`}
        image={product.images?.[0] ?? ''}
      />
      <TopBar />
      {product?.type === `BOOK` ? (
        <>
          <BookProductPage product={product} />
        </>
      ) : (
        <>
          <MerchProductPage product={product} />
        </>
      )}

      <EventTagSection />
      <ReviewSection />
    </>
  );
};

const EventTagSection = () => {
  const { id } = useParams<IdParams>();
  const { eventTags } = useProductEventTags(id);
  const { products } = useEventTagProducts(eventTags);
  const [selectedTag, selectTag] = useState<EventTag | null>(null);

  const displayProducts = useMemo(() => {
    // 태그가 선택되지 않은 경우 여러 태그에 중복 등록된 상품 필터링
    const result = products.filter((product) => product.id !== Number(id));
    if (!selectedTag)
      return result.filter((product, index, arr) => {
        return index === arr.findIndex((a) => a.id === product.id);
      });
    return result.filter((product) => product.eventTagId === selectedTag.id);
  }, [products, selectedTag, id]);

  const showSwiperPagination = useMemo(() => {
    return displayProducts.length >= 5;
  }, [displayProducts.length]);

  const onClickTag = (tag: EventTag) => {
    if (tag.id === selectedTag?.id) {
      selectTag(null);
      return;
    }
    selectTag(tag);
  };

  return (
    <Section className="my-5 p-5 max-w-screen-sm md:my-20 md:mx-auto md:w-full">
      <h6>
        이 <span className="font-bold">키워드</span>와 관련있어요
      </h6>

      {eventTags.length === 0 ? (
        <p className="pt-1 text-14 text-gray-600 md:pt-2 md:text-16">
          {' '}
          아직 관련 키워드가 없습니다.
        </p>
      ) : (
        <>
          <HorizontalScrollView className="-mx-2 my-4">
            {eventTags.map((tag) => (
              <KeywordButton
                key={tag.id}
                title={tag.name}
                selected={tag.id === selectedTag?.id}
                onClick={() => onClickTag(tag)}
              />
            ))}
          </HorizontalScrollView>
          <HorizontalScrollView
            alignTop
            key={selectedTag?.id}
            className="snap-x snap-mandatory md:hidden"
          >
            {displayProducts?.map((p) => (
              <div key={p.id} className="w-[28vw] snap-start">
                <ProductCard
                  to={`/product/${p.id}`}
                  product={p}
                  showRanking={false}
                />
              </div>
            ))}
          </HorizontalScrollView>
        </>
      )}

      <Section className="relative hidden md:block">
        {displayProducts.length > 0 && (
          <>
            <Swiper
              key={selectedTag?.id}
              navigation={{
                nextEl: '.swiper-button-next-tag',
                prevEl: '.swiper-button-prev-tag',
              }}
              loop={showSwiperPagination}
              modules={[Navigation]}
              className="mySwiper"
              spaceBetween={20}
              slidesPerView={5}
            >
              {displayProducts?.map((p) => (
                <SwiperSlide key={p.id}>
                  <div className="">
                    <ProductCard to={`/product/${p.id}`} product={p} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {showSwiperPagination && (
              <>
                <button className="swiper-button-prev-tag wh-10 absolute -left-10 top-36 hidden md:block">
                  <Icon.ChevronLeft />
                </button>
                <button className="swiper-button-next-tag wh-10 absolute -right-10 top-36 hidden place-content-center md:grid">
                  <Icon.ChevronRight />
                </button>
              </>
            )}
          </>
        )}
      </Section>
    </Section>
  );
};

const ReviewSection = () => {
  const { id } = useParams<IdParams>();
  const { product } = useProduct(id);
  const { reviews, hasNextPage, fetchNextPage, totalItemCount } =
    useProductReviews(id);
  const { isWritable } = useProductReviewable(id);
  const { createReview } = useModal();

  return (
    <>
      <div className="bg-brand-3 bg-opacity-50 p-5 md:mt-20 md:mb-10">
        <div className="flex items-center justify-between md:mx-auto md:max-w-screen-md">
          <h6 className="font-bold">별점 | 리뷰 ({totalItemCount})</h6>
          <Button
            onClick={() => {
              createReview(product.id, isWritable.shippingLineItem.id);
            }}
            disabled={!isWritable.available}
            text="리뷰쓰기"
            className="filled-gray-900 h-12"
          />
        </div>
      </div>

      <div className="max-w-screen-md space-y-5 px-5 pt-5 md:mx-auto md:w-full md:p-0">
        {reviews.map((review) => (
          <ProductReviewItem key={review.id} review={review} />
        ))}
      </div>

      {hasNextPage && (
        <button
          className="mx-auto py-6 text-14 text-gray-700"
          onClick={() => fetchNextPage()}
        >
          더보기
        </button>
      )}
    </>
  );
};
