import React from 'react';
import { isMobile } from 'react-device-detect';
import { Section } from '../../components/Section';
import { SingleCardSection } from './SingleCardSection';
import { MultiCardSection } from './MultiCardSection';

import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import { Navigation, Pagination } from 'swiper';
import { KeywordSection } from './KeywordSection';
import { GridCardSection } from './GridCardSection';
import { BannerSection } from './BannerSection';
import { useMainBanners } from '../../queries/mainBanners'
import { useSections } from '../../queries/sections'
import { SectionDto, SectionType } from '../../types/sections'
import { goToUrl } from './utils'
import { Icon } from '../../components/Icon'

const sectionComponent = {
  [SectionType.SINGLE_CARD_SECTION]: (section: SectionDto) => <SingleCardSection key={section.id} section={section} />,
  [SectionType.MULTI_CARD_SECTION_1]: (section: SectionDto) => <MultiCardSection key={section.id} section={section} />,
  [SectionType.MULTI_CARD_SECTION_2]: (section: SectionDto) => <MultiCardSection key={section.id} section={section} />,
  [SectionType.GRID_CARD_SECTION_1]: (section: SectionDto) => <GridCardSection key={section.id} section={section} />,
  [SectionType.GRID_CARD_SECTION_2]: (section: SectionDto) => <GridCardSection key={section.id} section={section} />,
  [SectionType.KEYWORD_SECTION]: (section: SectionDto) => <KeywordSection key={section.id} section={section} />,
  [SectionType.BANNER_SECTION]: (section: SectionDto) => <BannerSection key={section.id} section={section} />,
}

export const HomePage = () => {
  const { mainBanners } = useMainBanners('MAIN')
  const { sections } = useSections()

  return (
    <>

      <Section className="relative">
        {mainBanners.length > 0 && (
          <Swiper
            navigation={{
              nextEl: `.swiper-button-next-main`,
              prevEl: `.swiper-button-prev-main`,
            }}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            loop
            id="white_bullet"
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {mainBanners.map((banner) => (
              <SwiperSlide key={banner.id}>
                <div
                  onClick={() => goToUrl(banner.url)}
                  style={{
                    backgroundImage: `url(${isMobile ? banner.mobileBannerImageUrl :  banner.pcBannerImageUrl})`,
                  }}
                  className={`relative aspect-[4/5]  bg-cover bg-center bg-no-repeat md:aspect-[3/1] cursor-pointer`}
                >
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <button className={`swiper-button-prev-main wh-10 absolute left-4 top-[50%] hidden md:block z-10`}>
          <Icon.ChevronLeft className="text-gray-500" />
        </button>
        <button className={`swiper-button-next-main wh-10 absolute right-4 top-[50%] hidden place-content-center md:grid z-10`}>
          <Icon.ChevronRight  className="text-gray-500" />
        </button>
      </Section>

      {sections.map(section => (
        <div key={section.id} className="px-5 md:mx-auto md:max-w-screen-xl md:px-0 w-full">
          {sectionComponent[section.type](section)}
        </div>
      ))}
    </>
  );
};
