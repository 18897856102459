import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query'
import API from '../api'
import { ArcNShop } from '../types/arcNShop'

export const useArcNShop = () => {
  const { data, isLoading } = useQuery(
    ['arcNShop'],
    API.getArcNShop,
    {
      select: useCallback((data: AxiosResponse<ArcNShop>) => {
        const arcNShop = data.data;
        if (!arcNShop) return;
        return {
          arcNShop: {
            ...arcNShop,
            products: arcNShop.products
              .filter(product => !product.product.isDisplayHidden)
              .sort((a, b) => a.order - b.order),
          }
        };
      }, []),
    },
  );

  if (isLoading || !data) return {
    arcNShop: {} as ArcNShop
  };

  return {
    ...data,
  };
}
