import { FC } from 'react';
import { Product } from '../types/products'
import { PromotionThumbnail } from './PromotionThumbnail';

interface PromotionCardProps {
  product: Product;
  selected: boolean;
  onClick: () => void;
}

export const PromotionCard: FC<PromotionCardProps> = ({
  product,
  selected,
  onClick,
}) => {
  const { images, regularPrice, price, name, company } = product;

  return (
    <div onClick={onClick} className="space-y-1">
      <PromotionThumbnail
        selected={selected}
        src={images[0]}
        isSoldOut={product.isSoldOut}
      />

      <span className="text-12 font-medium">{name}</span>
      <p className="text-10 text-gray-500">{company.name}</p>

      <div className="text-10">
        <span className="text-gray-500 line-through">
          {regularPrice.toLocaleString()}
        </span>
        <span className="text-red-500">{price.toLocaleString()}원</span>
      </div>
    </div>
  );
};
