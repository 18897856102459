import { useQuery } from "react-query"
import API from "../api";
import useAuth from "../hooks/useAuth";
import { CartItem } from '../types/cart'
import { CalculateResult } from "../types/shipment";

export const useShipmentsPricingByProducts = (isPickUp: boolean, items: CartItem[]) => {
  const { authenticated } = useAuth();
  const { data, isLoading, refetch } = useQuery(
    [
      'getShipmentsPricingByProducts',
      items?.map(item => `${item.productId}-${item.quantity}`)?.join(',')
    ],
    async () => {
      if (!authenticated || items?.length === 0) return
      const res = await API.postShipmentsPricingByProducts({
        isBranchPickUp: isPickUp,
        items: items.map(item => ({
          productId: item.productId,
          quantity: item.quantity,
        }))
      })
      return res
    },
    {
      enabled: items.length > 0,
    },
  )
  if (isLoading || !data?.data) {
    return {
      calculrateResult: {
        totalShippingPrice: 0
      } as CalculateResult,
    }
  }

  return {
    calculrateResult: data.data,
    refetch,
  }
}
