import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { AddressCard } from '../../components/AddressCard';
import { AddressFormCard } from '../../components/AddressFormCard';
import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { TopBar } from '../../components/TopBar';
import {
  useCreateShippingAddress,
  useShippingAddresses,
} from '../../queries/shippingAddress';
import { ShippingAddress } from '../../types/shippingAddress';

export const ShippingAddressPage = () => {
  const [add, setAdd] = useState(false);
  const { shippingAddresses } = useShippingAddresses();
  const { createShippingAddress } = useCreateShippingAddress(() => {
    setAdd(false);
    toast.success('새 배송지가 등록되었습니다.');
  });
  const [selectedAddressId, setSelectedAddressId] = useState(0);

  const defaultAddressId = useMemo(() => {
    if (shippingAddresses.length === 0) return 0;
    const defaultAddress = shippingAddresses.find(
      (address) => address.isDefault
    )!;
    return defaultAddress.id;
  }, [shippingAddresses]);

  useEffect(() => {
    setSelectedAddressId(defaultAddressId);
  }, [defaultAddressId]);

  return (
    <div className="mt-8 md:mx-auto md:w-full md:max-w-lg">
      <TopBar title="배송지 관리" />

      <div className="space-y-8 p-5 md:px-0">
        {shippingAddresses.map((address) => (
          <div className="" key={address.id}>
            <AddressCard address={address} />
          </div>
        ))}

        {add && (
          <AddressFormCard
            onSave={createShippingAddress}
            onCancel={() => setAdd(false)}
          />
        )}

        {!add && (
          <Button
            onClick={() => setAdd(true)}
            className="flex w-full items-center justify-center space-x-2 bg-gray-100 text-gray-700"
          >
            <span className="">주소지 추가하기 </span>
            <Icon.PlusCircle />
          </Button>
        )}
      </div>
    </div>
  );
};
