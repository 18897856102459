import React, {useEffect} from "react";
import {Select} from "../Select";
import API from "../../api";
import {UsersCouponDto} from "../../types/coupons";
import {CartItem} from "../../types/cart";
import moment from "moment";

type CouponSelectorProps = {
    orderItems: CartItem[]
    totalProductPrice: number;
    totalDeliveryPrice: number;
    onSelect: (couponId: number | null) => void;
    // selectedCouponId: number | null;
}

export const CouponSelector = (props: CouponSelectorProps) => {
    const [selectedOption, setSelectedOption] = React.useState<string | null>(null);
    const [coupons, setCoupons] = React.useState<UsersCouponDto[]>([]);
    const books = props.orderItems.filter(item => item.product.type === 'BOOK');
    const generals = props.orderItems.filter(item => item.product.type === 'GENERAL');

    const sumPrice = (items: CartItem[]) => {
        let sum = 0;
        items.forEach(item => sum += item.product.price * item.quantity);
        return sum;
    }

    const isThisCouponAvailable = (coupon: UsersCouponDto) => {
        if (coupon.isUsed) return false;
        if (coupon.coupon.activationLimitPrice > props.totalProductPrice) return false;
        if (coupon.coupon.discountType === 'FREE_SHIPPING') {
            if (props.totalDeliveryPrice === 0) return false;
        } else {
            if (coupon.coupon.allowedProductTypes.find(type => type !== 'BOOK')) {
                if (sumPrice(generals) < coupon.coupon.activationLimitPrice) return false;
                if (generals.length === 0) return false;
            }
            if (coupon.coupon.allowedProductTypes.find(type => type !== 'GENERAL')) {
                if (sumPrice(books) < coupon.coupon.activationLimitPrice) return false;
                if (books.length === 0) return false;
            }
        }
        return true;
    }

    function Option({coupon, disabled}: { coupon: UsersCouponDto; disabled: boolean }) {
        if (coupon.coupon.discountType === 'FREE_SHIPPING') {
            return (
                <>
                    <option disabled={books.length === 0 || sumPrice(books) > 10000}
                             value={`${coupon.id}-BOOK`}>{coupon.coupon.title} {coupon.coupon.description} - 도서
                    </option>
                    <option disabled={generals.length === 0 || sumPrice(generals) > 30000}
                             value={`${coupon.id}-GENERAL`}>{coupon.coupon.title} {coupon.coupon.description} - 일반
                    </option>
                </>
            )
        } else {
            const value = `${coupon.id}-${coupon.coupon.allowedProductTypes.join(',')}`;
            return (<option disabled={disabled}
                            value={value}>{coupon.coupon.title} {coupon.coupon.description}</option>)
        }
    }

    useEffect(() => {
        API.getCoupons().then(res => {
            const list = res.data.items.filter((item: UsersCouponDto) => !item.isUsed && !item.deletedAt && moment(item.expireAt) > moment());
            setCoupons(list);
        });
    }, []);

    useEffect(() => {
        const couponId = selectedOption ? parseInt(selectedOption.split('-')[0]) : null;
        props.onSelect(couponId);
        console.log({ selectedOption, couponId });
    }, [selectedOption]);

    return (
        <div>
            <p className={'mb-3'}>쿠폰</p>
            <Select defaultValue={''}
                    value={selectedOption || ''}
                    onChange={e => {
                        setSelectedOption(e.target.value || null);
                    }}>
                <option disabled hidden value={'-'}>선택해 주세요</option>
                {coupons.map((coupon, i) => (
                    <Option key={i} coupon={coupon} disabled={!isThisCouponAvailable(coupon)}/>
                ))}
                <option value={''}>사용안함</option>
            </Select>
        </div>
    )
}


