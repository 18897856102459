import { CSSProperties } from 'react';
import DaumPostCode from 'react-daum-postcode';

export interface DaumPostResult {
  address: string
  zonecode: string
}

interface DaumPostProps {
  address: string
  onComplete: (data: DaumPostResult) => void
  open: boolean
  onClose: () => void
}

export const DaumPost = ({ address, onComplete, open, onClose }: DaumPostProps) => {
  const onCompletePost = (data: DaumPostResult) => {
    onComplete(data);
    onClose();
  };

  const postCodeStyle: CSSProperties = {
    display: "block",
    padding: "5px",
    height: "480px",
    maxWidth: "720px"
  };

  return open ?
    <div
      className="fixed flex z-100 top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 justify-center md:items-center"
      onClick={onClose}
    >
      <DaumPostCode
        style={postCodeStyle}
        onComplete={onCompletePost}
        defaultQuery={address}
        autoClose
      />
    </div> : <></>;
}
