
import {CalculateResult, ShippingPriceCalculateRequest, ShippingPriceCalculationArgument} from '../types/shipment'
import base from './base'

const shipments = {
  postShipmentsPricingByProducts (body: ShippingPriceCalculateRequest) {
    return base.post<CalculateResult>('/shipments/pricing/products', body)
  },
}

export default shipments
