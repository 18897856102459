import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../components/Button';
import { PaymentType } from '../../../components/cart/PaymentType';
import { ProductList } from '../../../components/cart/ProductList';
import { TopBar } from '../../../components/TopBar';
import { Icon } from '../../../components/Icon';
import { Points } from '../../../components/cart/Points';
import { TotalAmount } from '../../../components/cart/TotalAmount';
import { Checkbox } from '../../../components/Checkbox';
import { useCart } from '../../../queries/cart';
import { CreateOrder, PayMethod } from '../../../types/orders';
import { useUser } from '../../../queries/user';
import { useShipmentsPricingByProducts } from '../../../queries/shipments';
import { useOrderCalculate, useStartOrder } from '../../../queries/orders';
import { useShippingAddresses } from '../../../queries/shippingAddress';
import { ShippingAddressModal } from '../../../components/modal/ShippingAddressModal';
import {toast} from "react-toastify";
import {CouponSelector} from "../../../components/cart/CouponSelector";
import {useQueryClient} from "react-query";

export const CartPayPage = () => {
  const [showShippingAddressModal, setShowShippingAddressModal] =
    useState(false);
  const [payMethod, setPayMethod] = useState<keyof typeof PayMethod>(
    PayMethod.CREDIT_CARD
  );
  const [shippingAddressId, setShippingAddressId] = useState(0);
  const [agreeOrder, setAgreeOrder] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [usedPointAmount, setUsedPointAmount] = useState(0);
  const [selectedCouponId, setSelectedCouponId] = useState<number | null>(null);
  const { cart } = useCart();
  const { user } = useUser();
  const { shippingAddresses } = useShippingAddresses();
  const { startOrder } = useStartOrder();
  const queryClient = useQueryClient();

  const orderItems = useMemo(() => {
    const cartPayItemIdsStr = sessionStorage.getItem('cartPayItemIds');
    if (!cartPayItemIdsStr) return [];
    const cartPayItemIds = JSON.parse(cartPayItemIdsStr);
    return cart.filter((item) => cartPayItemIds.includes(item.id));
  }, [cart]);

  const { calculrateResult } = useShipmentsPricingByProducts(false, orderItems);
  const { calculrateResult: orderCalculateResult } = useOrderCalculate({
    usedPointAmount,
    itemIds: orderItems.map((item) => item.id),
    shippingPrice: calculrateResult.totalShippingPrice,
    userCouponIds: selectedCouponId ? [selectedCouponId] : [],
  });

  useEffect(() => {
    if (!user) return;
    setShippingAddressId(user.defaultShippingAddressId ?? 0);
  }, [user]);

  useEffect(() => {
    if (selectedCouponId) setUsedPointAmount(0);
  }, [selectedCouponId]);

  useEffect(() => {
    if (usedPointAmount > 0) setSelectedCouponId(null);
  }, [usedPointAmount]);

  const address = useMemo(() => {
    return shippingAddresses.find(
      (address) => address.id === shippingAddressId
    );
  }, [shippingAddresses, shippingAddressId]);

  const totalProductPrice = useMemo(() => {
    const prices = orderItems.map((item) => item.totalPrice);
    return prices.reduce((total, price) => total + price, 0);
  }, [orderItems]);

  const totalPayAmount = useMemo(() => {
    return (
      totalProductPrice + orderCalculateResult.shippingPrice - usedPointAmount
    );
  }, [totalProductPrice, orderCalculateResult.shippingPrice, usedPointAmount]);

  const MIMINUM_PAY_AMOUNT = 1000;
  const isFreeByMileage = totalPayAmount === 0;

  const isRightAddress = () => {
    if (!address || address?.addressDetail === '') {
      !toast.isActive(1) && toast('배송지 정보가 잘못된 것 같아요. 상세 주소를 확인해주세요.', {toastId: 1});
      return false;
    } else {
      return true;
    }
  }

  const isOrderable =
    agreeOrder &&
    agreePrivacy &&
    isRightAddress() &&
    (totalPayAmount >= MIMINUM_PAY_AMOUNT || isFreeByMileage);

  const startPay = async () => {
    const body: CreateOrder = {
      isCheckoutAgreed: true,
      itemIds: orderItems.map((item) => item.id),
      payMethod: isFreeByMileage ? PayMethod.FREE_ORDER : payMethod,
      shippingAddressId,
      shippingPrice: orderCalculateResult.shippingPrice,
      totalDiscountAmount: orderCalculateResult.totalDiscountAmount,
      totalPayAmount: orderCalculateResult.totalPayAmount,
      totalProductPrice: orderCalculateResult.totalProductPrice,
      usedPointAmount: orderCalculateResult.usedPointAmount,
      isProvidePrivacyAgreed: true,
      userCouponIds: selectedCouponId ? [selectedCouponId] : [],
      usedCouponDiscountAmount: orderCalculateResult.totalDiscountAmount,
    };
    startOrder(body);
  };

  return (
    <>
      <div className="max-w-screen-xl md:mx-auto md:w-full">
        <TopBar title="결제하기" />

        <ShippingAddressModal
          open={showShippingAddressModal}
          onClose={() => setShowShippingAddressModal(false)}
          selectedAddressId={shippingAddressId}
          onCheckAddress={setShippingAddressId}
        />

        <div className="flex flex-col p-5 md:flex-row md:space-x-8 md:p-0">
          <div className="flex-1 space-y-8">
            {address ? (
              <>
                <div className="card-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between space-x-3">
                      <h6 className="font-bold">{address.title}</h6>

                      {address.isDefault && (
                        <div className="rounded-full border-2 border-brand-2 px-2 py-1 text-14 font-bold text-brand-2">
                          기본배송지
                        </div>
                      )}
                    </div>

                    <button
                      onClick={() => setShowShippingAddressModal(true)}
                      className="text-13 text-gray-600"
                    >
                      변경
                    </button>
                  </div>

                  <p className="pt-4 text-13 text-gray-600">
                    {`${address.address} ${address.addressDetail}`} <br />
                    {`${address.publicDoorPassword} | ${address.receiverName} | ${address.receiverTel}`}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={() => setShowShippingAddressModal(true)}
                  className="flex cursor-pointer justify-center space-x-2 rounded-xl bg-gray-100 p-5 text-gray-600"
                >
                  <span className="">주소지 추가하기</span>
                  <Icon.PlusCircle />
                </div>
              </>
            )}

            <ProductList orderItems={orderItems} />

            <PaymentType payMethod={payMethod} setPayMethod={setPayMethod} />

            <div className={'card-2'}>
              <h6 className="mb-4 font-bold">할인</h6>
              <CouponSelector totalProductPrice={totalProductPrice}
                              totalDeliveryPrice={orderCalculateResult.shippingPrice}
                              orderItems={orderItems}
                              // selectedCouponId={selectedCouponId}
                              onSelect={(id) => setSelectedCouponId(id)}/>
              <Points
                  value={usedPointAmount}
                  disabled={selectedCouponId !== null}
                  totalProductPrice={totalProductPrice}
                  shippingPrice={orderCalculateResult.shippingPrice}
                  onChangeMileage={(mileage: number) => setUsedPointAmount(mileage)}
              />
            </div>

            <div className="space-y-4">
              <p className="text-15 text-gray-700">
                주문하실 상품, 가격, 배송정보, 할인정보 등을 확인 하였으며,
                구매에 동의하시겠습니까?
              </p>

              <Checkbox
                label="동의합니다. (전자상거래법 제8조 제2항)"
                labelClassName={`${
                  agreeOrder
                    ? `text-black font-bold text-16`
                    : `text-gray-500 text-16`
                }`}
                onChange={() => setAgreeOrder(!agreeOrder)}
                checked={agreeOrder}
              />
              <div className="flex space-x-3 ">
                <Checkbox
                  onChange={() => setAgreePrivacy(!agreePrivacy)}
                  checked={agreePrivacy}
                />
                <button
                  onClick={() => {
                    window.open(
                      'https://prairie-porcupine-6b9.notion.site/e114db6022f44737bae05505ffb07753',
                      '_blank'
                    );
                  }}
                  className={`${
                    agreePrivacy
                      ? `text-16 font-bold text-black underline`
                      : `text-16 text-gray-500 underline`
                  }`}
                >
                  개인정보 제 3자 제공
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-8">
            <TotalAmount
              totalProductPrice={totalProductPrice}
              totalPayAmount={totalPayAmount}
              calculrateResult={calculrateResult}
              orderCalculateResult={orderCalculateResult}
              usedPointAmount={usedPointAmount}
              isPickUp={false}
            />
            <Button
              disabled={!isOrderable}
              onClick={startPay}
              text="결제하기"
              className="filled-gray-900 hidden md:block"
            />
          </div>
        </div>

        <div className="fixed bottom-5 w-full px-5 md:hidden">
          <Button
            disabled={!isOrderable}
            onClick={startPay}
            text="결제하기"
            className="filled-gray-900 w-full"
          />
        </div>
      </div>
    </>
  );
};
