import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom'
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment'
import { modalState } from '../../plugins/ridge'
import { useCancelOrder, useOrderDetail } from '../../queries/orders'
import { ShippingStatus } from '../../types/orders'
import { IdParams } from '../../types/router'
import {getCouponKindText, getProductTypeText, openChannelIO} from '../../utils'
import { Accordion } from '../Accordion';
import { Button } from '../Button';
import { InfoItem } from './InfoItem';

export const PaymentInfo = () => {
  const { id } = useParams<IdParams>();
  const { order } = useOrderDetail(+id);
  const { payment } = order
  const canCancel = useMemo(() => {
    if (!order.shippingLines) return false;
    return order.shippingLines
      .map(shippingLine => shippingLine.shippingStatus)
      .every(shippingStatus => shippingStatus === ShippingStatus.INIT);
  }, [order]);
  const { cancelOrder } = useCancelOrder()
  if (!payment) return <></>;

  const onClickCancel = () => {
    if (canCancel) {
      modalState.set({
        title: '주문 취소',
        bodyText:
          '정말 주문을 취소하시겠습니까?',
        primaryButtonText: '확인',
        primaryClick: () => cancelOrder(id),
        secondaryButtonText: '취소',
      });
    } else {
      openChannelIO();
    }
  }

  const hasPaymentCoupon = order.paidCoupons && order.paidCoupons.length > 0;

  return (
    <div className="card-2">
      <Accordion
        title={<h6 className="font-bold">결제 정보</h6>}
        panel={
          <div className="space-y-2">
            <InfoItem title="결제번호" desc={payment.orderCode} />
            <InfoItem title="결제일시" desc={utcToLocalFormat(payment.createdAt, MomentFormat.DateTime)} />
            <InfoItem title="상품 금액" desc={`${payment.totalProductPrice.toLocaleString()}원`} />
            <InfoItem title="배송비" desc={`${payment.shippingPrice.toLocaleString()}원`} />
            {hasPaymentCoupon && (
                <>
                  <InfoItem title="쿠폰 할인" desc={`${order.payment.totalDiscountAmount.toLocaleString()}원`}/>
                  <InfoItem title="사용 쿠폰" desc={`${order.paidCoupons[0].title}`}/>
                  <InfoItem title="적용 상품" desc={`${getProductTypeText(order.paidCoupons[0].allowedProductTypes)}`}/>
                </>
            )}
            <InfoItem title="적립금 사용" desc={`${payment.usedPointAmount.toLocaleString()}원`} />
            <InfoItem title="결제금액" desc={`${payment.totalPayAmount.toLocaleString()}원`} />
            <InfoItem title="적립금액" desc={`${payment.receivablePointAmount.toLocaleString()}원`} />

            <div className="pt-4">
              <Button
                text={`${canCancel ? '주문 취소' : '주문 취소 문의'} `}
                className="filled-gray-900 h-12 w-full"
                onClick={onClickCancel}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};
