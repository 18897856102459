import React from 'react';
import { Posting } from '../../types/posting';
import '../home/swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { goToUrl } from '../home/utils';

interface TypeCListProps {
  posting: Posting;
  id: string;
}

export const TypeCList = ({ posting, id }: TypeCListProps) => {
  const banners = posting.banners;
  if (!banners) return <></>;
  return (
    <div className="mx-auto space-y-4 md:my-14 md:max-w-screen-lg">
      {banners
          .sort((a, b) => a.order - b.order) // sort by order
          .map((banner) => (
        <img
          alt=""
          src={banner.mobileBannerImageUrl}
          onClick={() => goToUrl(banner.url)}
          className={`w-full cursor-pointer`}
          key={banner.id}
        />
      ))}
    </div>
  );
};
