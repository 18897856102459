import { useQuery } from 'react-query'
import API from '../api'
import { Product } from '../types/products'
import { Promotion } from '../types/promotion'

export const usePromotion = (key: string) => {
  const { data, isLoading } = useQuery(
    ['promotions', key],
    () => API.getPromotionByKey(key)
  );

  if (isLoading || !data?.data) {
    return {
      promotion: {
        products: [] as Product[],
      } as Promotion,
    }
  }

  return {
    promotion: data.data,
  };
};
