import { useEffect, useState } from 'react'

const useTimer = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const now = new Date().getTime()
    if (countDownDate - now < 0) return
    const interval = setInterval(() => {
      setCountDown(countDownDate - now)
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate, countDown])

  useEffect(() => {
    const now = new Date().getTime()
    setCountDown(countDownDate - now)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: number) => {
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [minutes, seconds]
}

export default useTimer
