import { useQuery } from 'react-query'
import API from '../api'
import { MileageHistory } from '../types/mileageHistory'

export const useMileageHistories = (userId: number) => {
  const { data, isLoading } = useQuery(
    ['mileageHistories', userId],
    async () => await API.getMileageHistories(userId),
    {
      select: (data) => {
        if (!data.data) return
        const mileageHistories = data.data.items.sort((a, b) => b.id - a.id)
        return {
          mileageHistories,
        }
      }
    }
  )
  if (isLoading || !data) {
    return {
      mileageHistories: [] as MileageHistory[]
    }
  }

  return {
    ...data,
  }
}
