import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../api'
import useAuth from '../hooks/useAuth'
import { IsWritable, PostReviewBody, Review } from '../types/review'

export const useReview = (id: string) => {
  const { data, isLoading } = useQuery(
    ['review', id],
    async () => await API.getReview(id)
  );

  if (isLoading || !data?.data) {
    return {
      review: {} as Review,
    };
  }

  return {
    review: data.data,
  };
};

export const useReviews = (where?: Partial<Review>) => {
  const { data, isLoading } = useQuery(
    ['reviews', JSON.stringify(where)],
    async () => await API.getReviews(where ?? {})
  );

  if (isLoading || !data?.data) {
    return {
      reviews: [] as Review[],
    };
  }

  return {
    reviews: data.data.items,
  };
};

export const useCreateReview = () => {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const { mutate: createReview } = useMutation(
    (body: PostReviewBody) => API.postReview(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['reviews']);
        push('/mypage/reviews');
        toast.success('작성이 완료되었습니다!');
      }
    }
  );
  return {
    createReview,
  };
};

export const useEditReview = (id: string) => {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const { mutate: editReview } = useMutation(
    (body: PostReviewBody) => API.patchReview(body, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['reviews']);
        push('/mypage/reviews');
        toast.success('수정이 완료되었습니다!');
      }
    }
  );
  return {
    editReview,
  };
};

export const useProductReviewable = (productId: string) => {
  const { authenticated } = useAuth();
  const { data, isLoading } = useQuery(
    'reviews',
    async () => await API.getProductReviewable(productId),
    {
      enabled: authenticated,
    },
  );

  if (isLoading || !data?.data || !('available' in data.data)) {
    return {
      isWritable: {
        available: false,
      } as IsWritable,
    };
  }

  return {
    isWritable: data.data,
  };
};
