import { BulkUpdateCartItem, Cart, CartItemShallow, CartShallow, ChangeCartItemAmount } from '../types/cart';
import base from './base';

const cart = {
  getCart () {
    return base.get<Cart>('cart')
  },
  getCartShallow () {
    return base.get<CartShallow>('cart/shallow')
  },
  patchCartItems (body: BulkUpdateCartItem[]) {
    return base.patch<CartItemShallow>('/cart/items', body);
  },
  postCartItems (productId: number, body: ChangeCartItemAmount, branchCode?: string) {
    const url = `/cart/items/${productId}`;
    const query = branchCode ? `?branchCode=${branchCode}` : '';
    return base.post<CartItemShallow>(url + query, body);
  },
  deleteCartItems (productId: number, body: ChangeCartItemAmount, branchCode?: string) {
    const url = `/cart/items/${productId}`;
    const query = branchCode ? `?branchCode=${branchCode}` : '';
    return base.delete<CartItemShallow>(url + query, {
      data: body,
    })
  },
  postCartItemsPosting (postingId: number) {
    return base.post<CartItemShallow>(`/cart/items/posting/${postingId}`)
  },
}

export default cart
