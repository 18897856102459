import { Partnership } from '../types/partnership';
import base from './base';

const partnerships = {
  getPartnership() {
    return base.get<Partnership>(`/partnerships/one`);
  },
};

export default partnerships;
