import arcNShop from './arcNShop';
import branches from './branches';
import cart from './cart';
import categories from './categories';
import comments from './comments';
import curations from './curatons';
import eventTags from './eventTags';
import gridCardProducts from './gridCardProducts';
import keywords from './keywords';
import mainBanners from './mainBanners';
import mileageHistories from './mileageHistories';
import orders from './orders';
import postings from './postings';
import products from './products';
import promotions from './promotions';
import reviews from './reviews';
import sections from './sections';
import shipments from './shipments';
import shippingAddress from './shippingAddress';
import sms from './sms';
import topMenus from './topMenus';
import user from './user';
import coupons from './coupons';
import partnershipForms from './partnershipForms';
import partnerships from './partnership';

const API = {
  ...arcNShop,
  ...branches,
  ...cart,
  ...categories,
  ...comments,
  ...curations,
  ...eventTags,
  ...gridCardProducts,
  ...keywords,
  ...mainBanners,
  ...mileageHistories,
  ...orders,
  ...postings,
  ...products,
  ...promotions,
  ...reviews,
  ...sections,
  ...shipments,
  ...shippingAddress,
  ...sms,
  ...topMenus,
  ...user,
  ...coupons,
  ...partnershipForms,
  ...partnerships,
};

export default API;
