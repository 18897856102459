import { FC, useState } from 'react';
import { useBranches, useChangeBranch } from '../../queries/branches';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { StoreCard } from '../StoreCard';

interface SelectStoreModalProps {
  open: boolean;
  onClose: () => void;
  currentBranchCode: string;
}

export const SelectStoreModal: FC<SelectStoreModalProps> = ({
  open,
  onClose,
  currentBranchCode,
}) => {
  const { branches } = useBranches();
  const [selectedBranchCode, selectBranchCode] = useState(currentBranchCode);
  const { changeBranch } = useChangeBranch(selectedBranchCode);
  if (!open) return <></>;

  return (
    <div className=" fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 md:items-center">
      <div className="no-scrollbar relative flex h-screen w-full max-w-md flex-col overflow-hidden overflow-y-auto bg-white py-8 px-5 md:max-h-[80vh] md:rounded-xl md:px-8">
        <div className="flex items-center justify-between">
          <h4>지점 선택하기</h4>
          <button onClick={onClose}>
            <Icon.X />
          </button>
        </div>

        <div className="mt-6 space-y-8 pb-20">
          {branches.map((branch) => (
            <StoreCard
              key={branch.code}
              branch={branch}
              selected={selectedBranchCode === branch.code}
              onSelect={selectBranchCode}
            />
          ))}
        </div>

        <div className="sticky bottom-0 z-10 -mx-5 px-5">
          <Button
            onClick={() => changeBranch(onClose)}
            text="변경하기"
            className="filled-gray-900 w-full"
          />
        </div>
      </div>
    </div>
  );
};
