import { FC, useMemo, useState } from 'react';
import {
  useBranches,
  useBranchesBooksStock,
  useChangeBranch,
} from '../../queries/branches';
import { useAddProductToCart, useCartShallow } from '../../queries/cart';
import { Branch } from '../../types/branch';
import { Product } from '../../types/products';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { Radio } from '../Radio';
import { RadioGroup } from '../RadioGroup';

interface StorePickUpSelectModalProps {
  open: boolean;
  onClose: () => void;
  product: Product;
}

interface BranchStockStatus extends Branch {
  remainQuantity: number;
}

export const StorePickUpSelectModal: FC<StorePickUpSelectModalProps> = ({
  open,
  onClose,
  product,
}) => {
  const [branchCode, setBranchCode] = useState('');

  const { branches } = useBranches();
  const { books } = useBranchesBooksStock(product.id);
  const branchStatuses: BranchStockStatus[] = useMemo(() => {
    if (branches.length === 0) return [];
    return branches
      .map((branch) => {
        if (books.length === 0)
          return {
            ...branch,
            remainQuantity: 0,
          };
        const statuses = books[0].statuses;
        if (statuses.length === 0)
          return {
            ...branch,
            remainQuantity: 0,
          };
        const status = statuses.find((s) => s.branchCode === branch.code);
        return {
          ...branch,
          remainQuantity: status?.remainQuantity ?? 0,
        };
      })
      .sort((a, b) => b.remainQuantity - a.remainQuantity);
  }, [branches, books]);

  const { pickUpBranchCode, pickUpItems } = useCartShallow();
  const { addProductToCart } = useAddProductToCart();
  const { changeBranch } = useChangeBranch(branchCode);
  const addToCart = () => {
    if (!branchCode) return;
    if (pickUpBranchCode === branchCode || pickUpBranchCode === '') {
      addProductToCart({
        product,
        branchCode,
      });
      onClose();
    } else {
      if (!pickUpItems) return;
      changeBranch(async () => {
        await addProductToCart({
          product,
          branchCode,
        });
        onClose();
      });
    }
  };

  if (!open) return <></>;
  return (
    <div className=" fixed inset-0 z-50 flex items-end justify-center overflow-y-auto bg-black bg-opacity-50 md:items-center">
      <div className="no-scrollbar flex w-full max-w-md flex-col space-y-4 overflow-hidden overflow-y-auto bg-white py-8 px-5 md:max-h-[80vh] md:rounded-xl md:px-8">
        <div className="flex items-center justify-between">
          <h5>픽업 지점 선택</h5>
          <button onClick={onClose}>
            <Icon.X />
          </button>
        </div>

        <RadioGroup>
          <div className="divide-y divide-gray-100">
            {branchStatuses.map((branch) => (
              <Radio
                key={branch.code}
                label={
                  branch.name +
                  (branch.remainQuantity > 0
                    ? `(${branch.remainQuantity}권)`
                    : '')
                }
                value={branch.code}
                disabled={branch.remainQuantity === 0}
                className="my-3"
                checked={branchCode === branch.code}
                onChange={(e) => setBranchCode(e.target.value)}
              />
            ))}
          </div>
        </RadioGroup>

        <div className="w-full pt-4">
          {books[0].statuses.length > 0 ? (
            <Button
              onClick={addToCart}
              text="북앤픽업 장바구니 담기 →"
              className="filled-gray-900 w-full"
              disabled={!branchCode}
            />
          ) : (
            <Button
              onClick={() => onClose()}
              text="닫기"
              className="filled-gray-900 w-full"
            />
          )}
        </div>
      </div>
    </div>
  );
};
