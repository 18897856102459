const CONST = {
  TERMS_AGREEMENT_URL:
    'https://prairie-porcupine-6b9.notion.site/e61adea0d6d64eaaab59305c6c049cab',
  PRIVACY_AGREEMENT_URL:
    'https://prairie-porcupine-6b9.notion.site/e114db6022f44737bae05505ffb07753',
  MARKETING_AGREEMENT_URL:
    'https://prairie-porcupine-6b9.notion.site/7289cca9da814c4cab8d59c95870801d',
};

export default CONST;
