import { Product } from './products'

export const SectionType = {
  SINGLE_CARD_SECTION: 'SINGLE_CARD_SECTION',
  MULTI_CARD_SECTION_1: 'MULTI_CARD_SECTION_1',
  MULTI_CARD_SECTION_2: 'MULTI_CARD_SECTION_2',
  GRID_CARD_SECTION_1: 'GRID_CARD_SECTION_1',
  GRID_CARD_SECTION_2: 'GRID_CARD_SECTION_2',
  KEYWORD_SECTION: 'KEYWORD_SECTION',
  BANNER_SECTION: 'BANNER_SECTION',
} as const

export interface SectionDto {
  id: number
  createdAt: string
  updatedAt: string
  type: keyof typeof SectionType
  order: number
  isShow: boolean
  title: string
  description: string
  clickUrl: string
  products: CurationProduct[]
}

export interface CurationProduct {
  id: number
  createdAt: string
  updatedAt: string
  order: number
  title: string
  description: string
  product: Product
  productId: number
}