import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import API from '../api'
import { Paginated } from '../types/api'
import { BannerType, MainBanner } from '../types/mainBanners'

export const useMainBanners = (type: BannerType) => {
  const { data, isLoading } = useQuery(
    ['mainBanners', type],
    () => API.getMainBanners(type),
    {
      select: useCallback((data: AxiosResponse<Paginated<MainBanner>> | undefined) => {
        const mainBanners = data?.data;
        if (!mainBanners) return
        return {
          mainBanners: mainBanners.items.sort((a, b) => a.order - b.order),
        }
      }, []),
    }
  )

  if (isLoading || !data) return {
    mainBanners: [],
  }

  return {
    ...data,
  }
}