import React from 'react';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';

export const NonMemberPage = () => {
  return (
    <div className="">
      <div className="mx-auto max-w-md md:my-12">
        <TopBar title="비회원 주문조회" />

        <form className="space-y-9 px-5 pt-6 md:px-0">
          <TextField
            label="이름을 입력해주세요."
            placeholder="이름을 입력해주세요."
            // helper={errors.name?.message}
            // {...register('name', { required: '이름을 입력해주세요' })}
          />

          <TextField
            label="주문번호를 입력해주세요"
            placeholder="주문번호를 입력해주세요"
            // helper={errors.orderNumber?.message}
            // {...register('orderNumber', { required: '주문번호를 입력해주세요' })}
          />

          <div className="grid grid-cols-3 gap-3 pb-6">
            <Select onChange={(e) => console.log(e.target.value)}>
              <option selected value="010">
                010
              </option>
              <option value="020">020</option>
            </Select>

            <TextField type="tel" maxLength={4} />
            <TextField type="tel" maxLength={4} />
          </div>

          <Button
            // disabled
            text="주문 조회하기"
            type="button"
            className="filled-gray-900 w-full"
          />
        </form>
      </div>
    </div>
  );
};
