
import { Paginated } from '../types/api'
import { TopMenu } from '../types/topMenu'
import base from './base'

const topMenus = {
  getTopMenus () {
    return base.get<Paginated<TopMenu>>('/top-menus')
  },
}

export default topMenus
