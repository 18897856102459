import { Paginated } from '../types/api'
import { CreateShippingAddress, ShippingAddress } from '../types/shippingAddress'
import base from './base'

const ENDPOINT_ROOT = '/shipping-addresses'

const shippingAddress = {
  getShippingAddresses () {
    return base.get<Paginated<ShippingAddress>>(ENDPOINT_ROOT)
  },
  getShippingAddress (id: number) {
    return base.get<ShippingAddress>(`${ENDPOINT_ROOT}/${id.toString()}`)
  },
  postShippingAddress (data: CreateShippingAddress) {
    return base.post<ShippingAddress>(`${ENDPOINT_ROOT}`, data)
  },
  patchShippingAddress (id: number, data: Partial<CreateShippingAddress>) {
    return base.patch<ShippingAddress>(`${ENDPOINT_ROOT}/${id.toString()}`, data)
  },
  deleteShippingAddress (id: number) {
    return base.delete<ShippingAddress>(`${ENDPOINT_ROOT}/${id.toString()}`)
  },
}

export default shippingAddress
