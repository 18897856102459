import { TopBar } from '../../../components/TopBar';
import { useEffect, useState } from 'react';
import { useUser } from '../../../queries/user';

export const GradeInformationPage = () => {
  const { user } = useUser();

  if (!user) return null;
  return (
    <div className="md:mx-auto md:mt-14 md:w-full md:max-w-3xl">
      <TopBar title="등급별 혜택" />
      <div className={'p-5'}>
        <p className={'text-[14px] text-[#959394]'}>최근 3개월 누적 구매금액</p>
        <h3 className={'h3 text-[20px] font-bold'}>
          {user.libroOrderAmount || 0}원
        </h3>
      </div>
      <div className={'bg-gray-50 pb-10'}>
        <GradeInformation
          title={'혜택 기간'}
          desc={'회원등급 적용일로부터 3개월'}
        />
        <GradeInformation title={'선정기간'} desc={'직전 3개월 순수 구매액'} />
        <GradeInformation
          title={'순수구매액'}
          desc={
              '아크앤북 온/오프라인 구매금액 합산\n' +
              '(쿠폰, 배송비 등은 제외)'
          }
        />
        <GradeInformation
          title={'쿠폰 사용 시 유의사항'}
          desc={
            '- 쿠폰 유효기간은 1개월로, 발행한 월 1일부터 1개월간 사용이 가능합니다.\n' +
            '- 쿠폰 적용시 적립금 사용이 불가합니다.\n' +
            '- 배송비를 제외한 주문 상품 금액 기준으로 적용됩니다.\n' +
            '- 부분취소/반품(품절,배송지연 등 판매자의 사유 포함)시, 남아있는 주문 상품 금액이 쿠폰 사용 조건에 미달될 경우, 쿠폰 할인 금액 자동 차감 후 나머지 금액이 환불 됩니다.\n' +
            '- 유효기간이 지난 쿠폰은 재발행 되지 않습니다.\n' +
            '- 프로모션 진행 상품은 쿠폰 적용이 제외됩니다.\n' +
            '- 쿠폰 재발급은 전체반품일 경우 재발급이 가능하며, 부분반품일 경우 재발급되지 않습니다. \n' +
            '- 무료배송 쿠폰은 전체/부분 반품시 재발급되지 않습니다.'
          }
        />

        <GradeTitle title="아크앤피플" />
        <GradeInformation
          title={'금액 기준'}
          desc={'직전 3개월 순수 구매액이 누적 10만원 미만일 시'}
        />
        <GradeInformation
          title={'적립금'}
          desc={'도서 5% / 일반상품 1% 적립'}
        />
        <GradeInformation title={'쿠폰'} desc={'등급 혜택 없음'} />

        <GradeTitle title="아크앤프렌즈" color={'#714624'} />
        <GradeInformation
          title={'금액 기준'}
          desc={'직전 3개월 순수구매액이 누적 10만원 이상~30만원 미만일 시'}
        />
        <GradeInformation
          title={'적립금'}
          desc={
            '월 1회 3,000원\n' +
            '도서 5% 일반상품 1%\n' +
            '*적립금은 지급일로부터 1년간 유효합니다.\n'
          }
        />
        <GradeInformation
          title={'쿠폰'}
          desc={
            '일반상품  5% 할인쿠폰 1장 (10만원 이상 구매시, 최대 3만원 할인)\n' +
            '일반상품  5% 할인쿠폰 1장 (5만원 이상 구매시, 최대 1만원 할인) \n' +
            '일반상품  5% 할인쿠폰 1장 (3만원 이상 구매시) \n' +
            '무료배송 쿠폰 1종 (도서/일반상품 중 선택)'
          }
        />

        <GradeTitle title="아크앤패밀리" color={'#2D221A'} />
        <GradeInformation
          title={'금액 기준'}
          desc={'직전 3개월 순수 구매액이 30만원 이상일 시'}
        />
        <GradeInformation
          title={'적립금'}
          desc={
            '월 1회 5,000원\n' +
            '도서 5% 일반상품 1%\n' +
            '*적립금은 지급일로부터 1년간 유효합니다.\n'
          }
        />
        <GradeInformation
          title={'쿠폰'}
          desc={
            '일반상품  5% 할인쿠폰 2장 (10만원 이상 구매시, 최대 3만원 할인)\n' +
            '일반상품  5% 할인쿠폰 2장 (5만원 이상 구매시, 최대 1만원 할인) \n' +
            '일반상품  5% 할인쿠폰 1장 (3만원 이상 구매시) \n' +
            '무료배송 쿠폰 2종 (도서/일반상품 중 선택)'
          }
        />
      </div>
    </div>
  );
};

type GradeTitleProps = {
  title: string;
  color?: string;
};

const GradeTitle = (props: GradeTitleProps) => {
  return (
    <div
      className={`mt-[20px] bg-[#D7B67D] py-[14px] px-[20px]`}
      style={{ backgroundColor: props.color }}
    >
      <h2 className={`text-[17px] ${props.color && 'text-white'}`}>
        {props.title}
      </h2>
    </div>
  );
};

const GradeInformation = (props: { title: string; desc: string }) => {
  return (
    <div className={'py-4 px-[20px] pb-0'}>
      <p className={'text-[12px] text-[#8D95A1]'}>{props.title}</p>
      <p className={'whitespace-pre-wrap text-[15px] text-[#333D4B]'}>
        {props.desc}
      </p>
    </div>
  );
};
