import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import API from '../api'
import { Comment, CreateCommentRequestbody } from '../types/comments'

export const usePostingComments = (postingId: string) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['comments', postingId],
    ({ pageParam = 1 }) => API.getComments(postingId, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        const pagination = lastPage.data.pagination
        if (pagination.currentPage < pagination.totalPage) return pagination.currentPage + 1
        return undefined
      }
    }
  )

  if (isLoading || !data) {
    return {
      comments: [] as Comment[],
      fetchNextPage: () => {},
      hasNextPage: false,
    }
  }

  return {
    comments: data?.pages.flatMap((page) => page.data.items),
    isLoading,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
  }
};

export const useCreateComment = () => {
  const queryClient = useQueryClient()
  const { mutateAsync: createComment } = useMutation(
    (data: CreateCommentRequestbody) => API.postComment(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['comments']);
        toast.success('코멘트가 등록되었습니다!');
      },
    },
  );
  return {
    createComment,
  };
};

export const useEditComment = (id: number) => {
  const queryClient = useQueryClient()
  const { mutateAsync: editComment } = useMutation(
    (data: CreateCommentRequestbody) => API.patchComment(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['comments']);
        toast.success('코멘트가 수정되었습니다!');
      },
    },
  );
  return {
    editComment,
  };
};

export const useDeleteComment = (postingId: number, id: number) => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteComment } = useMutation(
    () => API.deleteComment(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['comments', postingId.toString()]);
        toast.success('코멘트가 삭제되었습니다!');
      },
    },
  );
  return {
    deleteComment,
  };
};
