import React, { useState } from 'react';
import { Section } from '../../components/Section';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { BookmarkButton } from '../../components/BookmarkButton';
import { Icon } from '../../components/Icon';
import { Accordion } from '../../components/Accordion';
import { Button } from '../../components/Button';
import { displayProductInfo } from '../../utils';
import { ProductCardPrice } from '../../components/ProductCard';
import { Product } from '../../types/products';
import { useAddProductToCart } from '../../queries/cart';
import { StorePickUpSelectModal as StorePickUpModal } from '../../components/modal/StorePickUpModal';
import useAuth from '../../hooks/useAuth';
import { Modal, useModal } from '../../components/modal/Modal';

interface BookProductPageProps {
  product: Product;
}

export const BookProductPage = ({ product }: BookProductPageProps) => {
  const { addProductToCart } = useAddProductToCart();
  const { authenticated } = useAuth();
  const { requireLogin } = useModal();
  const [storePickUpModal, setStorePickUpModal] = useState(false);

  const showPickUpOrLoginModal = () =>
    authenticated ? setStorePickUpModal(true) : requireLogin();

  return (
    <>
      <StorePickUpModal
        open={storePickUpModal}
        onClose={() => setStorePickUpModal(false)}
        product={product}
      />

      <div className="mt-8 max-w-screen-lg md:mx-auto md:flex md:flex-row md:space-x-8 lg:min-w-[100vw] xl:min-w-[71vw] 2xl:min-w-[55vw] ">
        <Swiper
          pagination={{
            dynamicBullets: true,
            dynamicMainBullets: 3,
          }}
          loop
          id="gray_bullet"
          modules={[Pagination]}
          spaceBetween={20}
          className="mySwiper  md:w-2/3"
        >
          {product.images.map((image, index) => (
            <SwiperSlide key={index}>
              <div
                style={{
                  backgroundImage: `url(${image})`,
                }}
                className={`relative mx-auto mb-10 aspect-[3/4.5] max-w-[50vw] overflow-hidden rounded-xl border border-[#f4f4f4] bg-brand-3 bg-cover bg-center bg-no-repeat`}
              ></div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="w-full">
          <Section className="mx-5 mt-2 space-y-2 md:mx-0">
            <div className="text-17 font-bold truncate-2-lines">
              {product.name}
            </div>

            <p className="text-gray-700">{product?.contentText}</p>

            <p className="text-14 text-gray-500 line-clamp-1">
              {displayProductInfo(product, true)}
            </p>

            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-1">
                <Icon.Star className="wh-3 fill-current text-[#FED188]" />
                <span className="text-12 text-gray-300">
                  {product.avgScore} ({product.reviewCount})
                </span>
              </div>

              <div className="flex items-center space-x-1">
                <Icon.BookmarkFilled className="h-2.5 w-2.5 text-[#FED188]" />
                <span className="text-10 text-gray-300">
                  {product.bookmarkCount}
                </span>
              </div>
            </div>

            <ProductCardPrice
              product={product}
              containerClass="text-18 md:text-20"
            />
          </Section>

          <Section className="mt-8 hidden space-x-4 md:flex">
            <BookmarkButton productId={product.id} />

            <Button
              text="북앤픽업"
              className="filled-gray-900 h-12 w-full"
              onClick={showPickUpOrLoginModal}
            />

            <Button
              text={product.isSoldOut ? '품절입니다' : '구매하기'}
              disabled={product.isSoldOut}
              className="filled-gray-900 h-12 w-full"
              onClick={() => addProductToCart({ product })}
            />
          </Section>

          <Section className="mt-8 md:overflow-hidden md:rounded-xl">
            <div className="mb-0.5 bg-brand-3 p-5">
              <Accordion
                title={<h6 className="font-bold">목차</h6>}
                panel={
                  <p
                    className="text-gray-700"
                    dangerouslySetInnerHTML={{
                      __html: product.bookInfo?.descTable ?? '',
                    }}
                  />
                }
              />
            </div>
            <div className="mb-0.5  bg-brand-3 p-5">
              <Accordion
                title={<h6 className="font-bold">출판사 서평</h6>}
                panel={
                  <p
                    className="text-gray-700"
                    dangerouslySetInnerHTML={{
                      __html: product.bookInfo?.descPub ?? '',
                    }}
                  />
                }
              />
            </div>
            <div className="mb-0.5  bg-brand-3 p-5">
              <Accordion
                title={<h6 className="font-bold">분류</h6>}
                panel={
                  <p className="text-gray-700">{product.category?.name}</p>
                }
              />
            </div>
            <div className="mb-0.5  bg-brand-3 p-5">
              <Accordion
                title={<h6 className="font-bold">ISBN</h6>}
                panel={<p className="text-gray-700">{product.barcode}</p>}
              />
            </div>
            <div className="mb-0.5 bg-brand-3 p-5">
              <Accordion
                title={<h6 className="font-bold">배송 안내</h6>}
                panel={
                  <div className="space-y-1 text-gray-700">
                    <p>[배송 안내]</p>
                    <p>
                      1) 배송 정책
                      <br />
                      도서 1만 원 이상 구매시, 일반상품 3만 원 이상 구매시
                      무료배송
                    </p>
                    <p>
                      2) 배송기간 <br />
                      도서 : 주문일로부터 2-3일 내로 출고 <br />
                      일반상품 : 상품에 따라 다르며, 기본 주문일로부터 3~4일내
                      출고되며 주문이 들어간 후 제작되는 상품일 경우 상품
                      상세페이지에 따른 배송기간에 준수함
                    </p>
                    <p>
                      3)주문 처리 <br />
                      &emsp;• 주문이 접수됨과 동시에 자동으로 배송준비중으로
                      변경됩니다.
                      <br />
                      &emsp;• 취소 문의는 익일 오전 10시 전까지 사이트 하단부
                      고객센터로 접수 바랍니다.
                      <br />
                      &emsp;• 이미 상품이 출고된 이후에는 반품 접수로 처리되며,
                      반송비용이 부과됩니다.
                    </p>
                    <p>
                      4) 출고지 주소 <br />
                      (10881) 경기도 파주시 문발로 77 (주)웅진북센
                    </p>
                  </div>
                }
              />
            </div>
            <div className="mb-0.5 bg-brand-3 p-5">
              <Accordion
                title={<h6 className="font-bold">교환 및 환불 안내</h6>}
                panel={
                  <div className="space-y-1 text-gray-700">
                    <p>
                      [교환/환불 안내] <br />※ 상품 설명에 반품/교환과 관련한
                      안내가 있는경우 아래 내용보다 우선합니다. (업체 사정에
                      따라 달라질 수 있습니다)
                    </p>

                    <p>
                      1) 교환/환불 방법 <br />
                      마이페이지 {'>'} 주문내역 {'>'} 주문상품 우측버튼 클릭
                      {'>'} 하단 교환환불 문의 클릭 <br />
                      판매자 배송 상품은 판매자와 반품/교환이 협의된 상품에 한해
                      가능합니다.
                    </p>

                    <p>
                      2) 반품/교환 가능기간 <br />- 결제 후 5일 이내의 주문 상품
                    </p>
                    <p>
                      3) 반품/교환 비용 <br />
                      - 고객의 단순변심 및 착오구매일 경우 상품 반송비용은 고객
                      부담임 <br />
                      - 직수입양서/직수입일서중 일부는 변심 또는 착오로 취소시
                      해외 주문 취소 수수료 20%를 부과할수 있음 <br />
                      단, 아래의 주문/취소 조건인 경우, 취소 수수료 면제 <br />
                      ex)오늘 00시 ~ 06시 30분 주문을 오늘 오전 06시 30분 이전에
                      취소, 오늘 06시 30분 이후 주문을 익일 오전 06시 30분
                      이전에 취소 <br />- 박스 포장은 택배 배송이 가능한 규격과
                      무게를 준수하며, 고객의 단순변심 및 착오구매일 경우 상품의
                      반송비용은 박스 당 부과됩니다.
                    </p>
                    <p>
                      4) 반품/교환 불가사유 <br />- 소비자의 책임 있는 사유로
                      상품 등이 손실 또는 훼손된 경우 <br />
                      - 소비자의 사용, 포장 개봉에 의해 상품 등의 가치가 현저히
                      감소한 경우 : 예) 화장품, 식품, 가전제품, 전자책 단말기 등
                      복제가 가능한 상품 등의 포장을 훼손한 경우 : 예) CD/LP,
                      DVD/Blu-ray, 소프트웨어, 만화책, 잡지, 영상 화보집 <br />
                      -소비자의 요청에 따라 개별적으로 주문 제작되는 상품의 경우
                      <br />
                      - 시간의 경과에 의해 재판매가 곤란한 정도로 가치가 현저히
                      감소한 경우 <br />
                      - 전자상거래 등에서의 소비자보호에 관한 법률이 정하는
                      소비자 청약철회 제한 내용에 해당되는 경우 소비자 피해보상
                      <br />- 상품의 불량에 의한 반품, 교환, A/S, 환불, 품질보증
                      및 피해보상 등에 관한 사항은
                      소비자분쟁해결기준(공정거래위원회 고시)에 준하여 처리됨
                    </p>
                    <p>
                      5) 환불 지연에 따른 배상 <br />- 대금 환불 및 환불 지연에
                      따른 배상금 지급 조건, 절차 등은 전자상거래 등에서의
                      소비자 보호에 관한 법률에 따라 처리
                    </p>
                    <p>
                      6) 반품지 주소 <br />
                      (10881) 경기도 파주시 문발로 77 (주)웅진북센
                    </p>
                  </div>
                }
              />
            </div>
          </Section>
        </div>
      </div>

      <Section className="fixed bottom-0 z-20 flex w-full space-x-4 border-t border-gray-100 bg-white px-5 py-3 md:hidden ">
        <BookmarkButton productId={product.id} />

        <Button
          text="북앤픽업"
          className="filled-gray-900 h-12 flex-1"
          onClick={showPickUpOrLoginModal}
        />

        <Button
          text={product.isSoldOut ? '품절입니다' : '구매하기'}
          disabled={product.isSoldOut}
          className="filled-gray-900 h-12 flex-1"
          onClick={() => addProductToCart({ product })}
        />
      </Section>
    </>
  );
};
