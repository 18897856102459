import { Paginated } from '../types/api'
import { IsWritable, PostReviewBody, Review } from '../types/review'
import base, { makeWhereParams } from './base'

const reviews = {
  getReview (id: string) {
    return base.get<Review>(`/reviews/${id}`);
  },
  getReviews (where: Partial<Review>) {
    return base.get<Paginated<Review>>('/reviews', {
      params: {
        ...(where && makeWhereParams(where)),
        'order[createdAt]': 'DESC',
      },
    });
  },
  postReview (body: PostReviewBody) {
    return base.post<Review>('/reviews', body);
  },
  patchReview (body: PostReviewBody, id: string) {
    return base.patch<Review>(`/reviews/${id}`, body);
  },
  getProductReviewable (productId: string) {
    return base.get<IsWritable | {}>(`/reviews/products/${productId}/reviewable`);
  }
}

export default reviews
