import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../components/Icon';
import { ProductCardPrice } from '../../components/ProductCard'
import { Section } from '../../components/Section';
import { useAddProductToCart } from '../../queries/cart';
import { Posting } from '../../types/posting';
import { displayProductInfo } from '../../utils';

interface TypeBListProps {
  posting: Posting;
  id: string;
}

export const TypeBList = ({ posting, id }: TypeBListProps) => {
  const { goBack, push } = useHistory();
  const { addProductToCart } = useAddProductToCart();
  if (!posting) return <></>;
  return (
    <>
      <div className="space-y-4 bg-brand-3 px-5 py-8">
        <div className=" flex justify-center">
          <div>
            <button onClick={goBack} className="md:hidden">
              <Icon.ChevronLeft />
            </button>
            <h4 className="text-center font-semibold">{posting.title}</h4>
            <p
              className="max-w-4xl text-center text-15 text-gray-700"
              dangerouslySetInnerHTML={{ __html: posting.description }}
            />
          </div>
        </div>
      </div>

      {posting.products.map(({ product, title, description }) => (
        <Section
          key={product.id}
          className="mt-10 md:mx-auto md:flex md:max-w-screen-md md:items-center"
        >
          <div className="relative mx-auto aspect-[3/4.5] w-1/2 overflow-hidden rounded-xl object-cover md:h-[270px] md:w-[180px]">
            <button
              className="wh-10 absolute bottom-3 right-3 z-10 grid place-content-center rounded-full bg-black bg-opacity-50"
              onClick={() => addProductToCart({ product })}
            >
              <Icon.ShoppingCart className="wh-5 text-white" />
            </button>
            <img
              src={product.images[0]}
              alt=""
              className=" h-full w-full object-cover"
              onClick={() => push(`/product/${product.id}`)}
            />
          </div>

          <div className="mx-auto text-center md:mx-8 md:flex md:flex-1 md:flex-col-reverse md:text-left">
            <div className="mt-4 space-y-1 md:mt-8">
              <p className="text-14 font-semibold">{product.name}</p>
              <p className="text-12 text-gray-600">
                {displayProductInfo(product)}
              </p>
            </div>
            <ProductCardPrice product={product} />

            <div className="mt-6 space-y-4 px-5 md:px-0">
              <h5 className="font-bold">{title}</h5>
              <p className="text-15 text-gray-600">{description}</p>
            </div>
          </div>
        </Section>
      ))}
    </>
  );
};
