import { BookRemainStatus, Branch, RemainStocks } from '../types/branch';
import base from './base';

const branches = {
  getBranches () {
    return base.get<Branch[]>('/branches');
  },
  getBranchBookStock (branchCode: string, productId: number) {
    return base.get<BookRemainStatus>(`/branches/${branchCode}/books/${productId.toString()}`);
  },
  getBranchesBooksStock (productIds: string[]) {
    return base.get<RemainStocks[]>(`/branches/books/${productIds.join(',')}`);
  },
};

export default branches;
