import { Posting } from '../types/posting'
import base from './base'

const postings = {
  getPosting (id: string) {
    return base.get<Posting>(`/postings/${id}`)
  },
}

export default postings
