import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUser } from '../queries/user';
import {
  CreateShippingAddress,
  ShippingAddress,
} from '../types/shippingAddress';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { DaumPost, DaumPostResult } from './DaumPost';
import { TextField } from './TextField';

interface AddressFormCardProps {
  onSave: (data: CreateShippingAddress) => void;
  onCancel: () => void;
  address?: ShippingAddress;
}

export const AddressFormCard: FC<AddressFormCardProps> = ({
  onSave,
  onCancel,
  address,
}) => {
  const [openDaumPost, setOpenDaumPost] = useState(false);
  const {
    register,
    setValue,
    getValues,
    formState: { isValid },
    handleSubmit,
  } = useForm<CreateShippingAddress>({ mode: 'onChange' });
  const {
    user: { id: userId },
  } = useUser();

  useEffect(() => {
    if (!userId || !setValue) return;
    setValue('userId', userId);
  }, [userId, setValue]);

  useEffect(() => {
    if (!address || !setValue) return;
    setValue('userId', address.userId);
    setValue('isDefault', address.isDefault);
    setValue('title', address.title);
    setValue('receiverName', address.receiverName);
    setValue('receiverTel', address.receiverTel);
    setValue('zipcode', address.zipcode);
    setValue('address', address.address);
    setValue('addressDetail', address.addressDetail);
    setValue('publicDoorPassword', address.publicDoorPassword);
    setValue('requestMemo', address.requestMemo);
  }, [address, setValue]);

  const onCompleteDaumPost = (data: DaumPostResult) => {
    setValue('address', data.address);
    setValue('zipcode', data.zonecode);
  };

  return (
    <>
      <div className="card-2 space-y-9">
        <h5 className="font-bold">배송지 입력</h5>

        <TextField
          label="배송지명*"
          placeholder="배송지명*"
          {...register('title', { required: true })}
        />
        <TextField
          label="받으시는 분 이름*"
          placeholder="받으시는 분 이름*"
          {...register('receiverName', { required: true })}
        />

        <TextField
          label="연락처*"
          placeholder="연락처*"
          {...register('receiverTel', { required: true })}
        />

        <div className="space-y-3">
          <div className="grid grid-cols-5 gap-x-2">
            <div className="col-span-3">
              <TextField
                placeholder="주소 입력"
                peerStyle={false}
                {...register('address', { required: true })}
                readOnly
                onClick={() => setOpenDaumPost(true)}
              />
            </div>
            <Button
              text="검색"
              className="filled-gray-900 col-span-2"
              onClick={() => setOpenDaumPost(true)}
            />
          </div>
          <TextField
            placeholder="상세 주소 입력"
            peerStyle={false}
            {...register('addressDetail')}
          />
        </div>

        <TextField
          label="공동현관 비밀번호"
          placeholder="공동현관 비밀번호"
          {...register('publicDoorPassword')}
        />
        <TextField
          label="배송 요청사항"
          placeholder="배송 요청사항"
          {...register('requestMemo')}
        />

        <Checkbox label="기본 배송지로 설정" {...register('isDefault')} />

        <div className="flex flex-row-reverse space-x-3 space-x-reverse">
          <Button
            disabled={!isValid}
            onClick={handleSubmit(onSave)}
            text="저장"
            className="filled-gray-900 px-6"
          />
          <Button
            onClick={onCancel}
            type="button"
            text="취소"
            className="outlined-gray-900 px-6"
          />
        </div>
      </div>
      <DaumPost
        address={getValues('address')}
        onComplete={onCompleteDaumPost}
        open={openDaumPost}
        onClose={() => setOpenDaumPost(false)}
      />
    </>
  );
};
