import { FC } from 'react';
import { ReactComponent as BigCheckedCircle } from '../assets/svg/big-check-circle.svg';
import { SoldOutOverlay } from './SoldOutOverlay'

interface PromotionThumbnailProps {
  src: string;
  selected: boolean;
  isSoldOut: boolean;
}

export const PromotionThumbnail: FC<PromotionThumbnailProps> = ({
  selected,
  src,
  isSoldOut = false,
}) => {
  return (
    <div className="relative">
      {selected && (
        <div className="absolute grid h-full w-full place-content-center rounded-2xl bg-black/40">
          <BigCheckedCircle />
        </div>
      )}
      <div className=" mb-2 aspect-[3/4.5]  w-full overflow-hidden rounded-2xl">
        <img src={src} alt="" className="h-full w-full object-cover" />
        {isSoldOut && <SoldOutOverlay />}
      </div>
    </div>
  );
};
