import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { usePartnership } from '../../queries/partnerships';

export const PartnershipPage = () => {
  const { push } = useHistory();
  const { partnership } = usePartnership();

  return (
    <div className="w-screen overflow-hidden px-6 md:mx-auto md:mt-16 md:max-w-screen-xl">
      <div className="flex items-center justify-between">
        <p className="hidden text-36 text-[#221F20] md:block">
          제휴안내 및 문의하기
        </p>
        <Button
          className=" flex w-full items-center justify-between rounded border border-black bg-white px-5 py-3 md:w-fit md:space-x-14"
          onClick={() => push('/partnership/form')}
        >
          <p>📝 제휴 문의하기</p> <p>→</p>
        </Button>
      </div>
      <div className="mt-10 h-full w-full md:mt-20">
        <p
          className="hidden md:flex md:flex-col"
          dangerouslySetInnerHTML={{ __html: partnership.pcContents }}
        />
        <p
          className="md:hidden"
          dangerouslySetInnerHTML={{ __html: partnership.mobileContents }}
        />
      </div>
    </div>
  );
};
