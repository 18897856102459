import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';

interface TopBarProps {
  backButton?: boolean;
  title?: string;
}

export const TopBar: FC<TopBarProps> = ({ backButton = true, title }) => {
  const { goBack } = useHistory();
  return (
    <div className="flex items-center pl-5 md:px-0">
      {backButton && (
        <button type="button" onClick={goBack} className="mr-3 md:hidden">
          <Icon.ChevronLeft />
        </button>
      )}

      <h4>{title}</h4>
    </div>
  );
};
