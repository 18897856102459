import React, { FC } from 'react';
import { useBranches } from '../../queries/branches'
import { ShippingLine, ShippingStatus } from '../../types/orders';
import { getCourierName, getTrackShippingUrl } from '../../utils'

interface DeliveryInfoProps {
  shippingLine: ShippingLine;
}

const shippingStatusText = {
  online: {
    [ShippingStatus.INIT]: '결제완료',
    [ShippingStatus.READY]: '배송준비중',
    [ShippingStatus.IN_PROGRESS]: '배송중',
    [ShippingStatus.FINISH]: '배송완료',
    [ShippingStatus.CHANGE]: '교환',
    [ShippingStatus.RETURN]: '반품',
    [ShippingStatus.CANCEL]: '주문취소',
    [ShippingStatus.REJECT]: '불가',
  },
  pickup: {
    [ShippingStatus.INIT]: '북앤픽업 결제완료',
    [ShippingStatus.READY]: '북앤픽업 상품준비중',
    [ShippingStatus.IN_PROGRESS]: '북앤픽업 상품준비완료',
    [ShippingStatus.FINISH]: '북앤픽업 완료',
    [ShippingStatus.CHANGE]: '북앤픽업 교환',
    [ShippingStatus.RETURN]: '북앤픽업 반품',
    [ShippingStatus.CANCEL]: '북앤픽업 주문취소',
    [ShippingStatus.REJECT]: '북앤픽업 불가',
  },
};

export const DeliveryInfo: FC<DeliveryInfoProps> = ({ shippingLine }) => {
  const isPickUp = shippingLine.branchCode !== '-';
  const formatShippingStatus = () => {
    const type = isPickUp ? 'pickup' : 'online';
    return shippingStatusText[type][shippingLine.shippingStatus];
  };
  const { branches } = useBranches();
  const trackShippingUrl = getTrackShippingUrl(shippingLine.courier, shippingLine.waybillCode ?? '');
  const currentBranch = branches.find(branch => branch.code === shippingLine.branchCode);
  const goToTrackShipping = () => {
    if (isPickUp) {
      window.open(`https://map.naver.com/v5/search/아크앤북 ${currentBranch?.name}`, '_blank');
    } else {
      window.open(trackShippingUrl, '_blank');
    }
  };
  const showShippingInfo = shippingLine.shippingStatus === ShippingStatus.IN_PROGRESS || shippingLine.shippingStatus === ShippingStatus.FINISH;
  return (
    <>
      {' '}
      <div className="flex items-center justify-between pt-5">
        <h6 className="text-14 font-bold">{shippingLine.company.name}</h6>
        {isPickUp ? (
          // 매장 픽업인 경우
          <div className="flex flex-col items-end">
            <p className="text-12">{formatShippingStatus()}</p>
            <button
              className="text-12 text-gray-700"
              onClick={goToTrackShipping}
            >
              {currentBranch?.name}
            </button>
          </div>
        ) : showShippingInfo ? (
          // 온라인 상품이면서 배송 이후인 경우
          <>
            <p className="text-12">
              <span className="text-blue-500">{shippingLine.waybillCode}</span>{' '}
              <span className="text-gray-500">{getCourierName(shippingLine.courier)}</span>{' '}
              {trackShippingUrl && (
                <button
                  className="font-bold text-gray-700"
                  onClick={goToTrackShipping}
                >
                  배송조회
                </button>
              )}
            </p>
          </>
        ) : (
          // 온라인 상품이면서 배송 준비중인 경우
          <p className="text-12">{formatShippingStatus()}</p>
        )}
      </div>
    </>
  );
};
