import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import API from '../api'
import { GridCardProduct, GridCardProductType } from '../types/gridCardProduct'

export const useGridCardProducts = (type: keyof typeof GridCardProductType) => {
  const { data, isLoading } = useQuery(
    ['gridCardProducts', type],
    async () => await API.getGridCardProducts(type),
    {
      select: useCallback((data: AxiosResponse<GridCardProduct[]>) => {
        const gridCardProducts = data?.data
        if (!gridCardProducts) return
        return {
          gridCardProducts: gridCardProducts
            .sort((a, b) => a.order - b.order)
            .map(products => ({
              ...products,
              curationProducts: products.curationProducts
                .filter(product => !product.product.isDisplayHidden)
                .sort((a, b) => a.order - b.order)
            }))
        }
      }, []),
    }
  )
  if (isLoading || !data) {
    return {
      gridCardProducts: [] as GridCardProduct[]
    }
  }

  return {
    ...data,
  }
}
