import { FC } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import { Radio } from './Radio';
import { Branch } from '../types/branch';
import { formatStoreRunningTime, getMomentDailyRunning } from '../utils'

interface StoreCardProps {
  branch: Branch;
  selected: boolean;
  onSelect: Function;
}

export const StoreCard: FC<StoreCardProps> = ({ branch, selected, onSelect }) => {
  const { name, code, address, tel, dailyRunning, images } = branch;
  const { startTime, finishTime } = getMomentDailyRunning(dailyRunning);
  return (
    <div>
      <Swiper
        pagination={{
          dynamicBullets: true,
          dynamicMainBullets: 3,
        }}
        loop
        id="gray_bullet"
        modules={[Pagination]}
        className="mySwiper mb-4"
        spaceBetween={20}
      >
        {images?.map((image) => (
          <SwiperSlide key={image}>
            <div className="h-[170px] w-full overflow-hidden rounded-2xl bg-brand-3">
              <img src={image} alt="" className="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex items-start space-x-4">
        <div className="pt-1">
          <Radio checked={selected} onChange={(e) => onSelect(code)} />
        </div>
        <div className="">
          <h6 className="font-bold">{name}</h6>

          <p className="pt-2 text-13 text-gray-500">
            {formatStoreRunningTime(startTime, finishTime)}
            <br /> {address}
            <br /> {tel}
          </p>
        </div>
      </div>
    </div>
  );
};
