import React, { FC, InputHTMLAttributes, useRef } from 'react';
import { Icon } from './Icon';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: (value: string) => void;
  onClose?: () => void;
}

export const Search: FC<SearchProps> = ({
  value,
  onSearch = (value) => {},
  onClose = () => {},
  ...props
}) => {
  const input = useRef<HTMLInputElement>(null);

  return (
    <form
      className="flex h-12 items-center rounded-xl border border-gray-200 px-4 focus-within:border-brand-2"
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(input.current?.value as string);
      }}
    >
      <input
        ref={input}
        className="flex-1 text-sm placeholder-gray-400"
        value={value}
        {...props}
      />
      <div className="wh-10 -ml-2 flex items-center justify-center">
        <Icon.Search onClick={() => onSearch(input.current?.value as string)} />
      </div>
      {value && (
        <div className="wh-10 -mr-2 flex items-center justify-center">
          <Icon.X
            className="wh-4"
            onClick={() => {
              onClose();
              Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
              )?.set?.call(input.current, '');
              input.current?.dispatchEvent(
                new Event('change', { bubbles: true })
              );
            }}
          />
        </div>
      )}
    </form>
  );
};
