import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/svg/logo.svg';
import {useAuth} from '../hooks';
import {bookmarkRoute} from '../pages/Router';
import {useCartShallow} from '../queries/cart'
import {CartButton} from './cart/CartButton';
import {Icon} from './Icon';
import TopMenuBar from "./TopMenuBar";

interface TopNavBarProps {
}

export const TopNavBar: FC<TopNavBarProps> = ({}) => {
    const {authenticated} = useAuth();
    const {cart} = useCartShallow();
    const refreshHome = () => {
        if (window.location.pathname === '/home') {
            window.location.reload();
        }
    };

    const exceptMenuBarList = [
        '/search',
        '/cart',
        '/cart/pay',
        '/bookmark',
        '/mypage',
        '/order-history',
        '/mypage/mileage',
        '/mypage/coupon',
        '/shipping-address',
        '/mypage/reviews',
    ];

    return (
        <>
            <div className="sticky top-0 z-50 bg-white px-5 py-3 md:border-b md:border-gray-100">
                <div className="mx-auto flex w-full max-w-screen-xl items-center justify-between">
                    <Link to="/home" onClick={refreshHome}>
                        <Logo/>
                    </Link>

                    <div className="flex items-center space-x-6">
                        <Link to="/categories" className="hidden md:block">
                            카테고리
                        </Link>

                        {authenticated ? (
                            <Link to="/mypage" className="hidden md:block">
                                마이페이지
                            </Link>
                        ) : (
                            <Link to="/login" className="hidden md:block">
                                로그인
                            </Link>
                        )}

                        <Link to="/search">
                            <Icon.Search className="wh-5"/>
                        </Link>

                        {authenticated && (
                            <Link to={bookmarkRoute} className="hidden md:block">
                                <Icon.Bookmark/>
                            </Link>
                        )}

                        <CartButton itemsCount={cart?.itemsCount ?? 0}/>
                    </div>
                </div>
            </div>
            {!exceptMenuBarList.includes(window.location.pathname) && <TopMenuBar/>}
        </>
    );
};
