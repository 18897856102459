import React, { ChangeEvent } from 'react';
import { ProductCard } from '../../../components/ProductCard';
import { Select } from '../../../components/Select';
import { TopBar } from '../../../components/TopBar';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import { KeywordParams } from '../../../types/router';
import { useProductsSearch } from '../../../queries/products';
import qs from 'qs';

export const SearchResultsPage = () => {
  const { keyword } = useParams<KeywordParams>();
  const { replace, location } = useHistory();
  const queryParams = qs.parse(location.search.replace('?', ''));
  const sortBy = (queryParams.sortBy as string) ?? 'rank';
  const { products, fetchNextPage, hasNextPage, totalItemCount, isLoading } =
    useProductsSearch(keyword, sortBy);
  const changeSortBy = (e: ChangeEvent<HTMLSelectElement>) => {
    replace({
      search: qs.stringify({
        ...queryParams,
        sortBy: e.target.value,
      }),
    });
  };

  return (
    <div className="mt-8 md:mx-auto md:max-w-screen-xl">
      <TopBar title={`"${keyword}"`} />

      {Number(totalItemCount) !== 0 ? (
        <div className="px-5 md:px-0">
          <div className="flex items-center justify-between">
            <Select
              className="border-0"
              onChange={changeSortBy}
              defaultValue={sortBy}
            >
              <option value="rank">추천순</option>
              <option value="recent">최신순</option>
              <option value="popular">인기순</option>
            </Select>
            <span className="text-12 text-gray-500">
              총 {totalItemCount?.toLocaleString()}권
            </span>
          </div>
          {isLoading && (
            <div className="mt-[18vw] w-full break-words px-[20vw] text-center font-semibold text-gray-500">
              로딩 중입니다.
            </div>
          )}
          <InfiniteScroll
            dataLength={products?.length ?? 0}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<>로딩 중입니다.</>}
            className="grid grid-cols-2 gap-5 md:grid-cols-6"
          >
            {products?.map((p) => (
              <ProductCard to={`/product/${p.id}`} product={p} key={p.id} />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="mt-[18vw] w-full break-words px-[20vw] text-center font-semibold text-gray-500">
          검색 결과가 없습니다. <br />
          다른 검색어를 입력하거나 두 단어 이상의 검색어인 경우 <br/>
          띄어쓰기를 포함하여 검색해주세요.
        </div>
      )}
    </div>
  );
};
