import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { CartCard } from '../../components/cart/CartCard';
import { DesktopCartCard } from '../../components/cart/DesktopCartCard';
import { SelectStoreModal } from '../../components/modal/SelectStoreModal';
import { SelectedStoreInfo } from '../../components/SelectedStoreInfo';
import { useCartContext } from '../../context/cart';
import { useBranchBooksStock, useBranches } from '../../queries/branches';
import { useCart } from '../../queries/cart';
import { useShipmentsPricingByProducts } from '../../queries/shipments';
import { checkDailyRunning } from './utils';

export const StorePickUp = () => {
  const [showSelectStoreModal, setShowSelecteStoreModal] = useState(false);
  const { push } = useHistory();
  const { pickup } = useCart();
  const { books, currentBranchCode } = pickup;
  const { syncItems, selectedCartItems, selectedCartItemIds } =
    useCartContext();
  const { calculrateResult } = useShipmentsPricingByProducts(
    true,
    selectedCartItems
  );
  const priceSum = selectedCartItems.reduce(
    (sum, curr) => sum + curr.totalPrice,
    0
  );

  const { branchBooksStock } = useBranchBooksStock(
    currentBranchCode,
    books.map((book) => book.productId)
  );

  useEffect(() => {
    // 카트 상품 상태 바뀌면 동기화
    syncItems([
      ...books.filter(
        (book) =>
          branchBooksStock.find((stock) => stock?.productId === book.productId)
            ?.remainQuantity
      ),
    ]);
  }, [books, syncItems, branchBooksStock]);

  const { branches } = useBranches();
  const currentBranch = branches.find(
    (branch) => branch.code === currentBranchCode
  );

  const goToCartPayPage = () => {
    sessionStorage.setItem(
      'cartPayItemIds',
      JSON.stringify(selectedCartItemIds)
    );
    push('/cart/pay/store-pickup');
  };
  return (
    <div>
      <SelectStoreModal
        open={showSelectStoreModal}
        onClose={() => setShowSelecteStoreModal(false)}
        currentBranchCode={currentBranchCode}
      />

      <div className="flex flex-col md:flex-row md:space-x-8">
        <div className="flex-1">
          <SelectedStoreInfo
            currentBranchCode={currentBranchCode}
            onClick={() => setShowSelecteStoreModal(true)}
          />

          {books?.length > 0 ? (
            <>
              {/* Mobile UI */}
              <div className="m-5 grid grid-cols-2 gap-x-5 gap-y-8 md:hidden">
                {books.map((cartItem) => (
                  <CartCard
                    key={cartItem.id}
                    isPickup={true}
                    item={cartItem}
                    remainQuantity={
                      branchBooksStock.find(
                        (stock) => stock?.productId === cartItem.productId
                      )?.remainQuantity ?? 0
                    }
                  />
                ))}
              </div>

              {/* Desktop UI */}
              <div className="my-8 hidden space-y-8 md:block">
                {books.map((cartItem) => (
                  <DesktopCartCard
                    key={cartItem.id}
                    isPickup={true}
                    item={cartItem}
                    remainQuantity={
                      branchBooksStock.find(
                        (stock) => stock?.productId === cartItem.productId
                      )?.remainQuantity ?? 0
                    }
                  />
                ))}
              </div>
            </>
          ) : (
            <div>
              <p className="grid h-56 place-content-center text-center text-gray-300">
                북앤픽업 상품을 장바구니에 담을 시, <br />
                지점 선택이 가능합니다.
              </p>
            </div>
          )}
        </div>

        <div className="pt-8 md:w-1/4 md:pt-0">
          <div className="m-5 space-y-3 md:m-0 md:rounded-xl md:border md:border-gray-100 md:p-5">
            <h5 className="hidden font-bold md:block">결제 금액</h5>
            <div className="flex justify-between text-15 text-gray-600">
              <span className="">총 주문금액</span>
              <span className="">{priceSum.toLocaleString()}원</span>
            </div>

            <hr className="border-gray-100" />

            <div className="flex justify-between pt-4 text-17 font-bold md:text-20">
              <span className="">총 결제금액</span>
              <span className="">
                {(
                  priceSum + calculrateResult.totalShippingPrice
                ).toLocaleString()}
                원
              </span>
            </div>

            <hr className="border-gray-100" />

            <p className="text-12 text-gray-500">
              * 결제 후 1시간 이내에 픽업 하실 경우, 도서를 직접 카운터로 가져가
              주문 확인을 받아주세요. <br /> * 1시간 이후에는 카운터에서 주문한
              책을 바로 수령할 수 있습니다. <br /> * 결제 후 5일간 미수령 시
              자동으로 결제 취소됩니다.
              <br /> * 반품은 결제 후 5일 이내로 가능하며 주문하신 매장으로
              방문해 주셔야 합니다. <br /> * 환불은 반품 접수 후 영업일 기준
              2~3일 소요됩니다.
            </p>
          </div>

          <div className="p-5 md:px-0">
            <Button
              disabled={selectedCartItemIds.length === 0}
              onClick={() =>
                checkDailyRunning(
                  goToCartPayPage,
                  currentBranch?.dailyRunning,
                  currentBranch?.name
                )
              }
              text="북앤픽업 주문하기"
              className="filled-gray-900 w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
