import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form'
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { useChangePassword } from '../../queries/user'
import { ChangePasswordRequestBody } from '../../types/user'

interface ResetPasswordSectionProps {
  userId: number
  token: string
}

export const ResetPasswordSection = ({ userId, token }: ResetPasswordSectionProps) => {
  const { changePassword } = useChangePassword(userId)
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<ChangePasswordRequestBody>({ mode: 'onChange' });
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const onSubmit = (data: ChangePasswordRequestBody) => {
    changePassword(data);
  };

  useEffect(() => {
    if (!setValue || !token) return;
    setValue('token', token);
  }, [token, setValue]);

  return (
    <form className="space-y-9 px-5">
      <h6 className="text-gray-700">새 비밀번호를 설정해주세요.</h6>

      <TextField
        type="password"
        label="새로운 비밀번호를 입력해주세요."
        placeholder="새로운 비밀번호를 입력해주세요."
        helper={errors.password?.message}
        {...register('password', {
          required: '비밀번호를 입력해주세요',
          minLength: { value: 8, message: '비밀번호를 8자리 이상 입력해주세요.' },
        })}
      />
      <TextField
        type="password"
        label="새로운 비밀번호를 다시 입력해주세요."
        placeholder="새로운 비밀번호를 다시 입력해주세요."
        {...(passwordConfirm && passwordConfirm !== getValues('password') && {
          helper: '비밀번호가 일치하지 않습니다.'
        })}
        onChange={e => setPasswordConfirm(e.target.value)}
        value={passwordConfirm}
      />

      <Button
        disabled={passwordConfirm !== getValues('password') || !isValid}
        onClick={handleSubmit(onSubmit)}
        text="변경하기"
        className="filled-gray-900 w-full"
      />
    </form>
  );
};
