import React, { ButtonHTMLAttributes, FC } from 'react';

interface KeywordButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  selected?: boolean;
}

export const KeywordButton: FC<KeywordButtonProps> = ({
  title,
  selected,
  ...props
}) => {
  return (
    <>
      <button
        {...props}
        className={`mr-2 mb-4 w-max snap-start rounded-full px-4 py-2 text-14 shadow-3xl ${
          selected ? 'filled-brand-2' : ''
        }`}
      >
        {title}
      </button>
    </>
  );
};
