import React, { FC } from 'react';

interface InfoItemProps {
  title: string;
  desc: string;
}

export const InfoItem: FC<InfoItemProps> = ({ title, desc }) => {
  return (
    <div className="flex space-x-4 text-15">
      <span className="w-24">{title}</span>
      <span className="flex-1 text-gray-600">{desc}</span>
    </div>
  );
};
