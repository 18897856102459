import { FC } from 'react';

interface BadgeProps {
  text: string;
}

export const Badge: FC<BadgeProps> = ({ text }) => {
  return (
    <div className="w-max rounded-full bg-brand-1 px-3 py-2 text-12 font-semibold uppercase text-white">
      {text}
    </div>
  );
};
