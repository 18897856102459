import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify'
import { Button } from '../../../../components/Button';
import { PaymentType } from '../../../../components/cart/PaymentType';
import { Points } from '../../../../components/cart/Points';
import { ProductList } from '../../../../components/cart/ProductList';
import { TotalAmount } from '../../../../components/cart/TotalAmount';
import { Checkbox } from '../../../../components/Checkbox';
import { ShippingAddressModal } from '../../../../components/modal/ShippingAddressModal';
import { TopBar } from '../../../../components/TopBar';
import { useBranchBooksStock, useBranches } from '../../../../queries/branches'
import { useCart } from '../../../../queries/cart';
import { useStartOrder, useOrderCalculate } from '../../../../queries/orders';
import { useShipmentsPricingByProducts } from '../../../../queries/shipments';
import { useUser } from '../../../../queries/user';
import { PayMethod, CreateOrder } from '../../../../types/orders';
import { formatStoreRunningTime, getMomentDailyRunning } from '../../../../utils'
import { checkDailyRunning } from '../../utils'
import {CouponSelector} from "../../../../components/cart/CouponSelector";
import {useQueryClient} from "react-query";

export const CartPayStorePickUpPage = () => {
  const [showShippingAddressModal, setShowShippingAddressModal] =
    useState(false);
  const [payMethod, setPayMethod] = useState<keyof typeof PayMethod>(
    PayMethod.CREDIT_CARD
  );
  const [shippingAddressId, setShippingAddressId] = useState(0);
  const [agreeOrder, setAgreeOrder] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState<number | null>(null);
  const [usedPointAmount, setUsedPointAmount] = useState(0);
  const { cart } = useCart();
  const { user } = useUser();
  const { startOrder } = useStartOrder();
  const queryClient = useQueryClient();

  const orderItems = useMemo(() => {
    const cartPayItemIdsStr = sessionStorage.getItem('cartPayItemIds');
    if (!cartPayItemIdsStr) return [];
    const cartPayItemIds = JSON.parse(cartPayItemIdsStr);
    return cart.filter((item) => cartPayItemIds.includes(item.id));
  }, [cart]);

  useEffect(() => {
    if (!user) return;
    setShippingAddressId(user.defaultShippingAddressId ?? 0);
  }, [user]);

  useEffect(() =>{
    if (orderItems.length > 0 && selectedCouponId !== null) {
      setUsedPointAmount(0);
      queryClient.refetchQueries(['orderCalculate']);
    }
  }, [selectedCouponId, usedPointAmount]);

  const { calculrateResult } = useShipmentsPricingByProducts(true, orderItems);
  const { calculrateResult: orderCalculateResult } = useOrderCalculate({
    itemIds: orderItems.map((item) => item.id),
    shippingPrice: calculrateResult.totalShippingPrice,
    usedPointAmount: usedPointAmount,
    userCouponIds: selectedCouponId ? [selectedCouponId] : [],
  });

  const totalProductPrice = useMemo(() => {
    const prices = orderItems.map((item) => item.totalPrice);
    return prices.reduce((total, price) => total + price, 0);
  }, [orderItems]);

  const totalPayAmount = useMemo(() => {
    return (
      totalProductPrice + orderCalculateResult.shippingPrice - usedPointAmount
    );
  }, [totalProductPrice, orderCalculateResult.shippingPrice, usedPointAmount]);

  const MIMINUM_PAY_AMOUNT = 1000;
  const isFreeByMileage = totalPayAmount === 0;

  const isOrderable =
    agreeOrder &&
    agreePrivacy &&
    (totalPayAmount >= MIMINUM_PAY_AMOUNT || isFreeByMileage);

  const { branches } = useBranches();
  const currentBranch = branches.find(branch => branch.code === orderItems[0].branchCode);
  const { startTime, finishTime } = getMomentDailyRunning(currentBranch?.dailyRunning);
  const { branchBooksStock } = useBranchBooksStock(
    currentBranch?.code ?? '',
    orderItems.map(item => item.productId)
  );

  const startPay = async () => {
    if (branchBooksStock.some(status => status?.remainQuantity === 0)) {
      toast.error('재고가 부족하여 주문이 불가능합니다.');
      return;
    }
    const body: CreateOrder = {
      isCheckoutAgreed: true,
      itemIds: orderItems.map((item) => item.id),
      payMethod: isFreeByMileage ? PayMethod.FREE_ORDER : payMethod,
      shippingAddressId,
      shippingPrice: orderCalculateResult.shippingPrice,
      totalDiscountAmount: orderCalculateResult.totalDiscountAmount,
      totalPayAmount: orderCalculateResult.totalPayAmount,
      totalProductPrice: orderCalculateResult.totalProductPrice,
      usedPointAmount: orderCalculateResult.usedPointAmount,
      isProvidePrivacyAgreed: true,
      userCouponIds: selectedCouponId ? [selectedCouponId] : [],
      usedCouponDiscountAmount: orderCalculateResult.totalDiscountAmount,
    };
    startOrder(body);
  };

  return (
    <>
      <div className="max-w-screen-xl md:mx-auto md:mt-10 md:w-full">
        <TopBar title="결제하기" />

        <ShippingAddressModal
          open={showShippingAddressModal}
          onClose={() => setShowShippingAddressModal(false)}
          selectedAddressId={shippingAddressId}
          onCheckAddress={setShippingAddressId}
        />

        <div className="flex flex-col p-5 md:flex-row md:space-x-8 md:p-0">
          <div className="flex-1 space-y-8">
            <div className="card-2">
              <div className="flex justify-between">
                <h5 className="font-bold">픽업정보</h5>
                <span className="text-14 font-bold text-brand-2">
                  {currentBranch?.name}
                </span>
              </div>

              <p className="mt-4 text-14 text-gray-600">
                {formatStoreRunningTime(startTime, finishTime)}
                <br /> {currentBranch?.address}
                <br /> {currentBranch?.tel}
              </p>
            </div>

            <ProductList orderItems={orderItems} />

            <PaymentType payMethod={payMethod} setPayMethod={setPayMethod} />

            <div className={'card-2'}>
              <h6 className="mb-4 font-bold">할인</h6>
              <CouponSelector totalProductPrice={totalProductPrice}
                              totalDeliveryPrice={orderCalculateResult.shippingPrice}
                              orderItems={orderItems}
                              // selectedCouponId={selectedCouponId}
                              onSelect={(id) => setSelectedCouponId(id)}/>
              <Points
                  value={usedPointAmount}
                  disabled={selectedCouponId !== null}
                  totalProductPrice={totalProductPrice}
                  shippingPrice={orderCalculateResult.shippingPrice}
                  onChangeMileage={(mileage: number) => setUsedPointAmount(mileage)}
              />
            </div>

            <div className="space-y-4">
              <p className="text-15 text-gray-700">
                주문하실 상품, 가격, 배송정보, 할인정보 등을 확인 하였으며,
                구매에 동의하시겠습니까?
              </p>

              <Checkbox
                label="동의합니다. (전자상거래법 제8조 제2항)"
                labelClassName={`${
                  agreeOrder
                    ? `text-black font-bold text-16`
                    : `text-gray-500 text-16`
                }`}
                onClick={() => setAgreeOrder(!agreeOrder)}
                checked={agreeOrder}
              />
              <div className="flex space-x-3 ">
                <Checkbox
                  onClick={() => setAgreePrivacy(!agreePrivacy)}
                  checked={agreePrivacy}
                />
                <button
                  onClick={() => {
                    window.open(
                      'https://prairie-porcupine-6b9.notion.site/e114db6022f44737bae05505ffb07753',
                      '_blank'
                    );
                  }}
                  className={`${
                    agreePrivacy
                      ? `text-16 font-bold text-black underline`
                      : `text-16 text-gray-500 underline`
                  }`}
                >
                  개인정보 제 3자 제공
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-8">
            <TotalAmount
              totalProductPrice={totalProductPrice}
              totalPayAmount={totalPayAmount}
              calculrateResult={calculrateResult}
              orderCalculateResult={orderCalculateResult}
              usedPointAmount={usedPointAmount}
              isPickUp={true}
            />
            <Button
              disabled={!isOrderable}
              onClick={() => checkDailyRunning(
                startPay,
                currentBranch?.dailyRunning,
                currentBranch?.name
              )}
              text="결제하기"
              className="filled-gray-900 hidden md:block"
            />
          </div>
        </div>

        <div className="fixed bottom-5 w-full px-5 md:hidden">
          <Button
            disabled={!isOrderable}
            onClick={() => checkDailyRunning(
              startPay,
              currentBranch?.dailyRunning,
              currentBranch?.name
            )}
            text="결제하기"
            className="filled-gray-900 w-full"
          />
        </div>
      </div>
    </>
  );
};
