import { FC, HTMLAttributes } from 'react';

interface HorizontalScrollViewProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  alignTop?: boolean;
}

export const HorizontalScrollView: FC<HorizontalScrollViewProps> = ({
  className,
  children,
  alignTop = false,
  ...props
}) => {
  return (
    <div
      className={`no-scrollbar overflow-x-auto ${className}`}
      style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
      {...props}
    >
      <div className="inline-block">
        <ul
          className={`flex space-x-4 ${
            alignTop ? 'items-start' : 'items-center'
          }`}
        >
          {children}
        </ul>
      </div>
    </div>
  );
};
