import { Paginated } from '../types/api'
import { EventTag } from '../types/eventTag'
import { Product } from '../types/products'
import { Review } from '../types/review'
import base, { makeWhereParams } from './base'

const products = {
  getProduct (id: string) {
    return base.get<Product>(`/products/${id}`);
  },
  getProductReviews (id: string, page = 1) {
    return base.get<Paginated<Review>>(`/products/${id}/reviews`, {
      params: {
        page,
        itemsPerPage: 10,
        'order[createdAt]': 'DESC',
      },
    })
  },
  getProductEventTags (id: string) {
    return base.get<EventTag[]>(`/products/${id}/eventTags`)
  },
  getProducts (categoryId: number, sortBy: string, page = 1, where?: Partial<Product>) {
    return base.get<Paginated<Product>>('products', {
      params: {
        categoryId,
        sortBy,
        page,
        ...(where && makeWhereParams(where)),
        'where[isDisplayHidden]': 0,
      }
    })
  },
  getProductsByIds (productIds: number[]) {
    return base.get<Paginated<Product>>('products', {
      params: {
        'where[id]': `[${productIds.join(',')}]`,
        'where[isDisplayHidden]': 0,
      }
    })
  },
  getProductsSearch (keyword: string, sortBy: string, page = 1) {
    return base.get<Paginated<Product>>('products/search', {
      params: {
        keyword,
        sortBy,
        page,
        'where[isDisplayHidden]': 0,
      }
    })
  },
  getBookmarkProducts () {
    return base.get<Paginated<Product>>('/products/bookmark')
  },
  postBookmarkProduct (productId: number) {
    return base.post(`/products/${productId}/bookmark`)
  },
  deleteBookmarkProduct (productId: number) {
    return base.delete(`/products/${productId}/bookmark`)
  },
}

export default products
