import { ButtonHTMLAttributes, FC } from 'react';
import { Icon } from './Icon';

interface XButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const XButton: FC<XButtonProps> = ({ ...props }) => {
  return (
    <button {...props} className="">
      <Icon.X />
    </button>
  );
};
