import React, { FC } from 'react';
import { Accordion } from '../Accordion';
import { CartItem } from '../../types/cart'
import { displayProductInfo } from '../../utils';
import { ProductCardPrice } from '../ProductCard'

interface ProductListProps {
  bookItems?: CartItem[] // TODO: required로 변경
  generalItems?: CartItem[]
  orderItems?: CartItem[]
}

export const ProductList: FC<ProductListProps> = ({ orderItems }: ProductListProps) => {
  const books = orderItems?.filter(item => item.product.type === 'BOOK')
  const generals = orderItems?.filter(item => item.product.type === 'GENERAL')
  return (
    <>
      <div className="card-2 ">
        <Accordion
          title={
            <div className="flex w-full justify-between">
              <h6 className="font-bold">주문상품</h6>
              <span className="mr-3 text-gray-600">총 {orderItems?.length}개</span>
            </div>
          }
          panel={
            <>
              {books && books.length > 0 && (
                <>
                  <span className="font-bold text-gray-600">도서상품</span>

                  {books.map((item, index) => (
                    <ProductListCard key={index} cartItem={item} />
                  ))}
                </>
              )}

              {generals && generals.length > 0 && (
                <>
                  <span className="font-bold text-gray-600">일반상품</span>

                  {generals?.map((item, index) => (
                    <ProductListCard key={index} cartItem={item} />
                  ))}
                </>
              )}
            </>
          }
        />
      </div>
    </>
  );
};

interface ProductListCardProps {
  cartItem: CartItem
}

export const ProductListCard: FC<ProductListCardProps> = ({ cartItem }) => {
  const { product } = cartItem
  return (
    <>
      <div className="my-4 flex space-x-3">
        <img
          src={product.images[0]}
          alt=""
          className="h-32 w-20 overflow-hidden rounded-lg bg-brand-3"
        />

        <div className="flex flex-col">
          <span className="">{product.name}</span>
          <span className="text-13 text-gray-400">{displayProductInfo(product)}</span>

          <ProductCardPrice product={product} />
          <p className="text-12 text-gray-600">
            <span>{cartItem.totalPrice.toLocaleString()} 원</span> /{' '}
            <span>수량: {cartItem.quantity}</span>
          </p>
        </div>
      </div>
    </>
  );
};
