import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../api'
import { tokenState } from '../plugins/ridge'
import { LoginBody } from '../types/user'

const useAuth = () => {
  const { push } = useHistory()
  const [token, setToken] = tokenState.use();
  const authenticated = token !== null;
  const login = (data: LoginBody) => {
    API.postUserSession(data)
      .then(({ data: { token } }) => {
        setToken(token)
        push('/')
      })
      .catch((error) => {
        if (!axios.isAxiosError(error)) return;
        if (error?.response?.status === 401) {
          toast.error('이메일주소 또는 비밀번호가 틀렸습니다.');
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }
  const logout = () => {
    tokenState.reset()
    push('/')
  }
  return { token, authenticated, login, logout };
};

export default useAuth
