// @ts-ignore
import BootPay from "bootpay-js";
import { AxiosError } from 'axios'
import moment from 'moment'
import { useCallback } from 'react'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../api'
import {
  CalculateOrderRequest,
  CalculateOrderResponse,
  CreateOrder,
  Order,
  Payment,
  PayMethod,
  ShippingLineItem
} from '../types/orders'
import { useUser } from './user'

export const useOrders = () => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['orders'],
    async ({ pageParam = 1 }) => API.getOrders(pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        const pagination = lastPage.data.pagination
        if (pagination.currentPage < pagination.totalPage) return pagination.currentPage + 1
        return undefined
      }
    }
  )

  return {
    orders: data?.pages.flatMap(page => page?.data.items) ?? [],
    totalItemCount: data?.pages[0]?.data.pagination.totalItemCount,
    isLoading,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
  }
}

export const useLastOrder = () => {
  const { data, isLoading } = useQuery(
    ['lastOrder'],
    () => API.getOrders(1),
  )

  if (isLoading || !data?.data || data.data.items.length === 0) {
    return {
      order: {} as Order,
    }
  }

  return {
    order: data?.data.items[0],
  }
}

export const useOrderDetail = (id: number) => {
  const { data, isLoading } = useQuery(
    ['orders', id],
    () => API.getOrder(id),
    {
      select: useCallback((data) => {
        const order: Order = data.data
        const books = order.shippingLines
          .filter(line => line.items.some(item => item.productType === 'BOOK'))
        const generals = order.shippingLines
          .filter(line => line.items.some(item => item.productType === 'GENERAL'))
        return {
          order,
          books,
          generals,
        }
      }, [])
    }
  )

  if (isLoading || !data?.order) {
    return {
      order: {} as Order,
      books: [],
      generals: [],
    }
  }

  return {
    ...data,
  }
}

const bootPayMethod = {
  [PayMethod.CREDIT_CARD]: 'card',
  [PayMethod.BANK_TRANSFER]: 'bank',
  [PayMethod.NAVER_PAY]: 'npay',
  [PayMethod.KAKAO_PAY]: 'kakao',
  [PayMethod.FREE_ORDER]: 'free',
};

const logOrderToGA = (order: Order) => {
  if (process.env.REACT_APP_SERVER_ENV !== 'operation') return;
  if (!order) return;
  // @ts-ignore
  gtag("event", "purchase", {
    transaction_id: order.code,
    value: order.payment.totalProductPrice,
    shipping: order.payment.shippingPrice,
    currency: "KRW",
    items: order.shippingLines.flatMap((shippingLine) => {
      return shippingLine.items.map((item) => ({
        item_id: item.productId,
        item_name: item.productName,
        currency: "KRW",
        item_category: item.productType,
        price: item.totalPrice,
        quantity: item.quantity,
      }));
    }),
  });
};

const useCompleteOrder = () => {
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const completeOrder = async (order: Order) => {
    logOrderToGA(order);
    queryClient.invalidateQueries(['cartShallow']);
    queryClient.invalidateQueries(['cart']);
    push('/cart/pay/success');
  };

  return { completeOrder };
};

const useExecuteBootpay = () => {
  const { user } = useUser();
  const { completeOrder } = useCompleteOrder();

  const executeBootpay = (order: Order, payment: Payment, items: ShippingLineItem[]) => {
    const bootPayRequestBody = {
      price: payment.totalPayAmount,
      application_id: process.env.REACT_APP_BOOTPAY_APP_ID,
      name: items.map(item => `${item.productName}[${item.quantity}]`).join(', '),
      pg: 'payapp',
      method: bootPayMethod[payment.method],
      show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
      items: items.map((item) => ({
        item_name: item.productName,
        qty: item.quantity,
        unique: item.productId.toString(),
        price: item.unitPrice,
        cat1: item.productType,
      })),
      user_info: {
        username: user.name,
        email: user.email,
        addr: `${user.address} ${user.addressDetail}`,
        phone: user.phone
      },
      order_id: order.id,
      account_expire_at: moment().add(7, 'days').format('YYYY-MM-DD'),
      // extra: {
      //   quota: '0,2,3', // 결제금액이 5만원 이상시 할부개월 허용범위를 설정할 수 있음, [0(일시불), 2개월, 3개월] 허용, 미설정시 12개월까지 허용,
      // }
    };

    BootPay.request(bootPayRequestBody).error(function (data: unknown) {
      //결제 진행시 에러가 발생하면 수행됩니다.
    }).cancel(function (data: unknown) {
      //결제가 취소되면 수행됩니다.
    }).ready(function (data: unknown) {
      // 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니
    }).confirm(function (data: unknown) {
      //결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
      //주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
      var enable = true; // 재고 수량 관리 로직 혹은 다른 처리
      if (enable) {
        BootPay.transactionConfirm(data); // 조건이 맞으면 승인 처리를 한다.
      } else {
        BootPay.removePaymentWindow(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
      }
    }).close(function (data: unknown) {
      // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
    }).done(async function (data: unknown) {
      // BootPay 결제 완료 처리 (https://docs.bootpay.co.kr/flow 7번)
      // @ts-ignore
      completeOrder(order);
    });
  };

  return { executeBootpay };
};

export const useStartOrder = () => {
  const { executeBootpay } = useExecuteBootpay();
  const { completeOrder } = useCompleteOrder();
  const { mutate: startOrder } = useMutation(
    (body: CreateOrder) => API.postOrder(body),
    {
      onSuccess: async (res) => {
        // BootPay 결제 요청 (https://docs.bootpay.co.kr/flow 1번)
        const order = res.data;
        const { payment } = order;
        const isFreeByMileage = payment.totalPayAmount === 0;

        const items: ShippingLineItem[] = [];
        order.shippingLines.forEach((shippingLine) => {
            shippingLine.items.forEach((item) => {
                items.push(item);
            });
        });

        if (isFreeByMileage) {
          completeOrder(order);
        } else {
          executeBootpay(order, payment, items);
        }
      },
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      }
    }
  );
  return {
    startOrder,
  };
}

export const useOrderCalculate = (body: CalculateOrderRequest) => {
  const { data, isLoading, refetch } = useQuery(
    [
      'orderCalculate',
      body.itemIds,
      body.usedPointAmount,
      body.userCouponIds,
      body.shippingPrice,
    ],
    async () => {
      const res = await API.postOrderCalculate(body)
      return res
    },
    {
      enabled: body.itemIds.length > 0
    }
  )
  if (isLoading || !data?.data) {
    return {
      calculrateResult: {
      } as CalculateOrderResponse,
    }
  }

  return {
    calculrateResult: data.data,
    refetch,
  }
}

export const useCancelOrder = () => {
  const { push } = useHistory()
  const { mutate: cancelOrder } = useMutation(
    (id: string) =>
      API.postOrderCancel(id),
    {
      onSuccess: () => {
        toast.success('주문이 취소되었습니다.')
        push('/order-history')
      }
    }
  )
  return {
    cancelOrder,
  }
}
