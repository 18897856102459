import { useQueries, useQuery } from 'react-query';
import API from '../api';
import { modalState } from '../plugins/ridge';
import { BookRemainStatus } from '../types/branch';
import { useCart, useUpdateCartItems } from './cart';

export const useBranches = () => {
  const { data, isLoading } = useQuery(
    'branches',
    async () => await API.getBranches()
  );
  if (isLoading || !data?.data) {
    return {
      branches: [],
    };
  }

  return {
    branches: data.data,
  };
};

export const useBranchesBooksStock = (productId: number) => {
  const { data, isLoading } = useQuery(
    ['branchesBooksStock', productId],
    async () => await API.getBranchesBooksStock([productId.toString()])
  );
  if (isLoading || !data?.data || !data.data) {
    return {
      books: [],
    };
  }
  return {
    books: data.data,
  };
};

export const useBranchBooksStock = (branchCode: string, productIds: number[]) => {
  const fetchBranckBookStock = (productId: number) => async () => {
    const data = await API.getBranchBookStock(branchCode, productId);
    if (!data.data) return { remainQuantity: 0 } as BookRemainStatus;
    return data.data;
  }
  const queries = useQueries(
    productIds.map((id) => ({
      queryKey: ['branchBooksStock', branchCode, id],
      queryFn: fetchBranckBookStock(id),
    }))
  );

  const isLoading = queries.some(data => data.isLoading);
  if (isLoading) return { branchBooksStock: [] };

  return {
    branchBooksStock: queries
      .map((data, index) => {
        const productId = productIds[index]
        if (!data.data) return;
        return {
          productId,
          remainQuantity: data.data.remainQuantity,
        }
      })
      .filter(_ => _),
  };
};

export const useChangeBranch = (branchCode: string) => {
  const { pickup } = useCart();
  const books = pickup.books;
  const { updateCartItems } = useUpdateCartItems();

  const { branchBooksStock } = useBranchBooksStock(branchCode, books.map(item => item.productId));
  const changeBranch = (callback: Function) => {
    modalState.set({
      title: '지점을 변경하시겠습니까?',
      bodyText:
        '선택하신 상품을 장바구니에 담을 경우 지점이 변경되며, 이미 담긴 상품의 재고가 달라질 수 있습니다.',
      primaryButtonText: '담기',
      primaryClick: async () => {
        await updateCartItems(books.map(item => ({
          id: item.id,
          branchCode: branchCode,
          quantity: Math.min(item.quantity, branchBooksStock.find(stock => stock?.productId === item.id)?.remainQuantity ?? 1),
        })));
        callback();
      }, 
      secondaryButtonText: '취소',
    });
  };
  
  return {
    changeBranch,
  };
};
