import { Paginated } from '../types/api'
import { MileageHistory } from '../types/mileageHistory'
import base from './base'

const mileageHistories = {
  getMileageHistories (userId: number) {
    return base.get<Paginated<MileageHistory>>(`/mileage-histories/${userId.toString()}`, {
      params: {
        'order[id]': 'DESC'
      }
    })
  },
}

export default mileageHistories
