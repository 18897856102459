import React, { ButtonHTMLAttributes, FC } from 'react';
import { Icon } from './Icon';

interface CheckCircleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean;
}

export const CheckCircle: FC<CheckCircleProps> = ({ checked, ...props }) => {
  // const [checked, setChecked] = useState(false);
  return (
    <button
      {...props}
      className={`${
        checked ? 'bg-brand-2' : 'bg-gray-200'
      } wh-8 mb-3 grid place-content-center rounded-full `}
    >
      <Icon.Tick className="wh-4 text-white" />
    </button>
  );
};
