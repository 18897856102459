import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '../Icon';
import { modalState } from '../../plugins/ridge';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { TextField } from '../TextField';

export interface ModalProps {
  title?: string;
  bodyText?: string;
  input?: boolean;
  inputInitValue?: string;
  primaryButtonText?: string;
  primaryClick?: (input: string) => void;
  secondaryButtonText?: string;
  secondaryClick?: () => void;
}

export const Modal: React.FC<ModalProps> = () => {
  const modal = modalState.useValue();
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    setInputValue(modal.inputInitValue ?? '');
  }, [modal.inputInitValue]);
  if (!modal.title) return <></>;

  return (
    <Transition.Root show={!!modal.title} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={modalState.reset}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block w-full transform space-y-6 overflow-hidden rounded-lg bg-white p-5 text-left align-middle shadow-xl transition-all md:w-1/4`}
            >
              <div className="flex items-center justify-between space-x-6 ">
                <Dialog.Title className="text-2xl font-medium leading-6 text-gray-900">
                  {modal.title}
                </Dialog.Title>

                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={modalState.reset}
                >
                  <span className="sr-only">Close</span>
                  <Icon.X className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <p className="text-gray-700">{modal.bodyText}</p>
              {modal.input && (
                <TextField
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              )}

              <div className="space-y-4">
                <Button
                  onClick={() => {
                    modal.primaryClick && modal.primaryClick(inputValue);
                    modalState.reset();
                  }}
                  text={modal.primaryButtonText}
                  className="filled-gray-900 w-full"
                />

                {modal.secondaryButtonText && (
                  <div className="grid place-content-center">
                    <Button
                      onClick={() => {
                        modal.secondaryClick && modal.secondaryClick();
                        modalState.reset();
                      }}
                      text={modal.secondaryButtonText}
                      className="h-8 text-14 text-gray-500 underline"
                    />
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export function useModal() {
  const { push } = useHistory();

  const changeStoreConfirmation = () =>
    modalState.set({
      title: '지점을 변경하시겠습니까?',
      bodyText:
        '선택하신 상품을 장바구니에 담을 경우 지점이 변경되며, 이미 담긴 상품의 재고가 달라질 수 있습니다.',
      primaryButtonText: '담기',
      primaryClick: () => push('/cart'),
      secondaryButtonText: '취소',
    });

  const createReview = (productId: number, shippingLineItemId: number) =>
    modalState.set({
      title: '리뷰 작성하기',
      bodyText:
        '리뷰를 작성하면 자동으로 구매 확정이 되어 교환 및 반품이 불가합니다. 작성하시겠습니까?',
      primaryButtonText: '네, 작성할래요',
      primaryClick: () =>
        push(`/mypage/reviews/${productId}-${shippingLineItemId}/create`),
    });

  const verifyAgeRequest = () =>
    modalState.set({
      title: '성인 인증 상품',
      bodyText: '성인 인증이 필요한 상품입니다. 지금 인증하시겠습니까?',
      primaryButtonText: '성인 인증하기',
      primaryClick: () => push('/login'),
    });

  const restrictedViewLoginRequest = () =>
    modalState.set({
      title: '성인 인증 상품',
      bodyText:
        '성인 인증이 필요한 상품은 회원만 구매 가능합니다. 회원가입 또는 로그인을 진행해주세요.',
      primaryButtonText: '로그인',
      primaryClick: () => push('/login'),
      secondaryButtonText: '회원가입',
      secondaryClick: () => push('/signup'),
    });

  const requireLogin = (title?: string) =>
    modalState.set({
      title: title ?? '로그인',
      bodyText: '로그인이 필요한 기능입니다. 로그인 하시겠습니까?',
      primaryButtonText: '확인',
      primaryClick: () => push('/login'),
      secondaryButtonText: '취소',
    });

  const alertNewUser = () => {
    modalState.set({
      title: '신규 회원입니다.',
      bodyText:
        '회원가입시 아크앤북 오프라인에서도 마일리지를 적립 / 사용할 수 있습니다.',
      primaryButtonText: '계속하기',
    });
  };

  const alertLibroUser = () => {
    modalState.set({
      title: '이어서 회원가입을 진행해주세요.',
      bodyText:
        '오프라인에 가입 내역이 있는 회원입니다. 이어서 회원가입 진행시 마일리지를 온/오프라인에서 함께 사용이 가능합니다.',
      primaryButtonText: '계속하기',
    });
  };

  const partnershipFormSuccessModal = () => {
    modalState.set({
      title: '제휴 문의 완료',
      bodyText:
        '문의해주셔서 감사합니다. 관리자가 확인후 개별 연락을 드릴 예정이니, 조금만 기다려주세요!',
      primaryButtonText: '확인',
      primaryClick: () => push('/partnership'),
    });
  };

  return {
    changeStoreConfirmation,
    createReview,
    verifyAgeRequest,
    restrictedViewLoginRequest,
    requireLogin,
    alertNewUser,
    alertLibroUser,
    partnershipFormSuccessModal,
  };
}
