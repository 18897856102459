import { FC, useState } from 'react';
import { usePromotion } from '../../queries/promotions'
import { Product } from '../../types/products'
import { Badge } from '../Badge';
import { Button } from '../Button';
import { PromotionCard } from '../PromotionCard';
import { XButton } from '../XButton';

interface PromotionModalProps {
  open: boolean;
  onConfirm: (product?: Product) => void
  onClose: () => void;
}

export const PromotionModal: FC<PromotionModalProps> = ({
  open,
  onConfirm,
  onClose
}) => {
  const { promotion } = usePromotion('1000promo');
  const [selectedProduct, selectProduct] = useState<Product | undefined>();
  if (!open) return <></>;

  return (
    <>
      <div className=" fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 md:items-center">
        <div className="no-scrollbar relative flex h-screen w-full max-w-md flex-col overflow-hidden overflow-y-auto bg-white py-8 px-5 md:max-h-[85vh] md:rounded-xl md:px-8">
          <div className="flex justify-between">
            <Badge text="Event" />
            <XButton onClick={onClose} />
          </div>

          <h1 className="my-4 text-24 font-medium leading-[36px]">
            인기 브랜드 상품, <br />
            <span className="font-bold">1천원에</span> 함께 구매하세요!
          </h1>

          <p className="text-17 text-gray-600">
            2만원 이상 구매시,{' '}
            <span className="font-bold">아래 상품 중 한가지</span>를 1천원에
            구매 가능합니다. 구매할 상품을 선택해주세요!
          </p>

          <div className="mt-5 grid grid-cols-3 gap-x-4 gap-y-5">
            {promotion?.products?.map((product) => (
              <PromotionCard
                selected={product.id === selectedProduct?.id}
                onClick={() => {
                  if (product.isSoldOut) return;
                  selectProduct(product);
                }}
                product={product}
              />
            ))}
          </div>

          <div className="flex flex-col">
            <Button
              text="함께 구매하기 →"
              className="filled-gray-900 mt-8"
              disabled={!selectedProduct}
              onClick={() => onConfirm(selectedProduct)}
            />
            <Button
              text="건너뛰기"
              className="text-15 font-bold text-gray-500"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </>
  );
};
