import React, { FC, HTMLAttributes } from 'react';

export interface SoldOutOverlayProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
}

export const SoldOutOverlay: FC<SoldOutOverlayProps> = ({
  className = '',
  ...props
}) => {
  return (
    <div className={`absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center rounded-xl text-white text-16 ${className}`} {...props}>
      품절
    </div>
  );
};
