import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../components/Icon';
import { ProductCard } from '../../components/ProductCard';
import { Section } from '../../components/Section';
import { Posting } from '../../types/posting';

interface TypeDListProps {
  posting: Posting;
  id: string;
}

export const TypeDList = ({ posting, id }: TypeDListProps) => {
  const { goBack } = useHistory();

  if (!posting) return <></>;
  return (
    <>
      <div className="space-y-4 bg-brand-3 px-5 py-8">
        <div className=" flex justify-center">
          <div>
            <button onClick={goBack} className="md:hidden">
              <Icon.ChevronLeft />
            </button>
            <h4 className="text-center font-semibold">{posting.title}</h4>
            <p
              className="max-w-4xl text-center text-15 text-gray-700"
              dangerouslySetInnerHTML={{ __html: posting.description }}
            />
          </div>
        </div>
      </div>

      <Section className="mt-10 md:mx-auto md:flex md:max-w-screen-md md:items-center">
        <div className="flex justify-center">
          <p
            className="max-w-4xl text-center text-15 text-gray-700"
            dangerouslySetInnerHTML={{ __html: posting.content }}
          />
        </div>
      </Section>

      <div className="mt-8 md:mx-auto md:mt-20 md:max-w-screen-xl">
        <div className="px-5 md:px-0">
          <InfiniteScroll
            dataLength={posting.products?.length ?? 0}
            next={() => {}}
            hasMore={false}
            loader={<>로딩 중입니다.</>}
            className="grid grid-cols-2 gap-5 md:grid-cols-6"
          >
            {posting.products.map(({ product }) => (
              <ProductCard
                to={`/product/${product.id}`}
                product={product}
                key={product.id}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};
