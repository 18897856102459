import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import { Icon } from '../../components/Icon';
import { Toggle } from '../../components/Toggle';
import { shippingAddressRoute } from '../Router';
import useAuth from '../../hooks/useAuth';
import {useCoupon, useUser} from '../../queries/user';
import {getUserGradeText, openChannelIO} from '../../utils';

export const MyPage = () => {
  const { push } = useHistory();
  const { logout } = useAuth();
  const { user } = useUser();
  const { coupons } = useCoupon();

  const couponCount = coupons.filter((coupon) => !coupon.isUsed && new Date(coupon.expireAt) > new Date()).length;

  if (!user) return <></>;
  return (
    <div className="md:mx-auto md:mt-14 md:w-full md:max-w-3xl">
      <div className="flex flex-col bg-brand-3 px-5 py-8">
        {/*<h6 className="">반갑습니다,</h6>*/}
        <button
          onClick={() => push('/mypage/edit')}
          type="button"
          className="flex items-center space-x-2"
        >
          <h4>{user.name}님</h4>
          <Icon.ChevronRight />
        </button>
        <div className={'flex space-x-4 pt-4 items-center'}>
          <button className={'bg-white rounded-3xl px-[12px] py-[4px] font-bold'}>
            <p>{getUserGradeText(user.level)}</p>
          </button>
          <Link to={'/mypage/grade-information'} className={'underline text-[#7A7979]'}>내 누적 구매금액 • 등급별 혜택 보기</Link>
        </div>
      </div>

      <div className="mt-5 space-y-5 px-5 md:mt-10 md:space-y-10">
        <button
          onClick={() => push('/order-history')}
          type="button"
          className="w-full border-b border-gray-100 pb-4 text-left"
        >
          주문 내역
        </button>

        <button
          onClick={() => push('/mypage/mileage')}
          type="button"
          className="flex w-full justify-between border-b border-gray-100 pb-4 text-left"
        >
          <span>마일리지</span>
          <span>{user.mileageAmount?.toLocaleString()}P</span>
        </button>

        <button
          onClick={() => push('/mypage/coupon')}
          type="button"
          className="flex w-full justify-between border-b border-gray-100 pb-4 text-left"
        >
          <span>쿠폰</span>
          <span>{couponCount}장</span>
        </button>

        {[
          { title: '배송지 관리', pathname: shippingAddressRoute },
          { title: '내가 쓴 리뷰', pathname: '/mypage/reviews' },
        ].map((nav) => (
          <button
            key={nav.title}
            onClick={() => push(nav.pathname)}
            type="button"
            className="w-full border-b border-gray-100 pb-4 text-left"
          >
            {nav.title}
          </button>
        ))}
        <button
          onClick={openChannelIO}
          type="button"
          className="w-full border-b border-gray-100 pb-4 text-left"
        >
          고객센터
        </button>

        {/* <div className="flex w-full justify-between border-b border-gray-100 pb-4 text-left">
          <span>푸시알림</span>
          <Toggle onChange={(checked) => console.log(checked)} />
        </div> */}

        <button
          type="button"
          className="w-full border-b border-gray-100 pb-4 text-left"
          onClick={() => logout()}
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};
