import {Paginated} from "../types/api";
import base from "./base";
import {UsersCouponDto} from "../types/coupons";

const coupons = {
    getCoupons() {
        const params = {
            page: 1,
            itemsPerPage: 1000,
        };
        return base.get<Paginated<UsersCouponDto>>(`/my/coupons`, {params});
    }
}

export default coupons
