import moment from 'moment';
import { modalState } from '../../plugins/ridge';
import { DailyRunning } from '../../types/branch';
import { formatStoreRunningTime, getMomentDailyRunning } from '../../utils';

export const checkDailyRunning = (
  onConfirm: Function,
  dailyRunning?: DailyRunning,
  name?: string
) => {
  const { startTime, finishTime } = getMomentDailyRunning(dailyRunning);
  const now = moment();

  if (
    now.isBefore(startTime) ||
    now.isAfter(finishTime.clone().subtract(30, 'minutes'))
  ) {
    modalState.set({
      title: '북앤 픽업 안내',
      bodyText: `
        ${name}의 북앤픽업 운영시간은 ${formatStoreRunningTime(
        startTime,
        finishTime
      )}입니다.
        마감시간 직전 또는 이후 구매시 다음날 관리자 확인 후에 픽업이 가능합니다.
        계속 주문하시겠습니까?
      `,
      primaryButtonText: '확인',
      primaryClick: () => onConfirm(),
      secondaryButtonText: '취소',
    });
  } else {
    onConfirm();
  }
};
