import React, { FC } from 'react';
import { Button } from '../Button';
import { TextField } from '../TextField';

interface NonMemberAddressProps {}

export const NonMemberAddress: FC<NonMemberAddressProps> = ({}) => {
  return (
    <>
      <div className="card-2 space-y-9">
        <h6 className="font-bold">배송지 입력</h6>

        <TextField label="받으시는 분 이름*" placeholder="받으시는 분 이름*" />

        <TextField label="연락처*" placeholder="연락처*" />

        <div className="space-y-3">
          <div className="flex space-x-3">
            <div className="w-2/3 md:w-full">
              <TextField placeholder="주소 입력" peerStyle={false} />
            </div>
            <Button
              text="검색"
              className="filled-gray-900 flex-1 md:w-20 md:flex-auto"
            />
          </div>
          <TextField placeholder="상세 주소 입력" peerStyle={false} />
        </div>

        <TextField label="공동현관 비밀번호" placeholder="공동현관 비밀번호" />
        <TextField label="배송 요청사항" placeholder="배송 요청사항" />
      </div>
    </>
  );
};
