import { Promotion } from '../types/promotion';
import base from './base';

const promotions = {
  getPromotion (id: string) {
    return base.get<Promotion>(`/promotions/${id}`);
  },
  getPromotionByKey (key: string) {
    return base.get<Promotion>(`/promotions/keys/${key}/current`);
  },
};

export default promotions;
