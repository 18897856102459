import React from 'react';
import { HorizontalScrollView } from '../../components/HorizontalScrollView';

import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper';
import { SectionDto } from '../../types/sections';
import { useMainBanners } from '../../queries/mainBanners';
import { goToUrl } from './utils';

interface BannerSectionProps {
  section: SectionDto;
}

export const BannerSection = ({ section }: BannerSectionProps) => {
  const { mainBanners } = useMainBanners('MINI');
  return (
    <>
      <div className="mt-12 md:hidden">
        <HorizontalScrollView className="-mx-5 snap-x snap-mandatory space-x-0">
          {mainBanners.map((item) => (
            <div
              key={item.id}
              onClick={() => goToUrl(item.url)}
              className={`ml-5 h-[112px] w-[85vw] snap-center overflow-hidden rounded-xl shadow-lg`}
            >
              <img
                src={item.mobileBannerImageUrl}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
          ))}
        </HorizontalScrollView>
      </div>

      {mainBanners.length > 0 && (
        <Swiper
          pagination={{
            dynamicBullets: true,
            dynamicMainBullets: 3,
          }}
          loop
          id="white_bullet"
          modules={[Pagination]}
          className="mySwiper mt-14 hidden md:block"
          spaceBetween={50}
        >
          {mainBanners.map((item) => (
            <SwiperSlide key={item.id}>
              <div
                onClick={() => goToUrl(item.url)}
                className={`h-32 cursor-pointer overflow-hidden rounded-xl shadow-lg md:h-56`}
              >
                <img
                  src={item.pcBannerImageUrl}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};
