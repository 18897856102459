import React, { ChangeEvent } from 'react';
import { ProductCard } from '../../../components/ProductCard';
import { Section } from '../../../components/Section';
import { Select } from '../../../components/Select';
import { TopBar } from '../../../components/TopBar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useHistory, useParams } from 'react-router-dom';
import { IdParams } from '../../../types/router';
import { useCuration } from '../../../queries/curations';
import qs from 'qs'

export const CurationPage = () => {
  const { id } = useParams<IdParams>();
  const { replace, location } = useHistory();
  const queryParams = qs.parse(location.search.replace('?', ''));
  const sortBy = queryParams.sortBy as string ?? 'rank';
  const { curation } = useCuration(id, sortBy);
  const changeSortBy = (e: ChangeEvent<HTMLSelectElement>) => {
    replace({
      search: qs.stringify({
        ...queryParams,
        sortBy: e.target.value,
      }),
    });
  };

  return (
    <div className=" mt-8 md:mx-auto md:max-w-screen-xl">
      <TopBar title={curation?.name} />

      {curation?.isBannerShow && curation?.banners?.length > 0 && (
        <Section className="mb-8">
          <Swiper
            id="white_bullet"
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            loop
            modules={[Pagination]}
            className="mySwiper"
          >
            {curation?.banners?.map((banner) => (
              <SwiperSlide key={banner.id}>
                <div
                  style={{
                    backgroundImage: `url(${banner.pcBannerImageUrl})`,
                  }}
                  className={`relative aspect-[2.5/1] bg-cover bg-center bg-no-repeat md:overflow-hidden md:rounded-lg`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Section>
      )}

      <div className="px-5 md:px-0">
        <div className="flex items-center justify-between">
          <Select
            className="border-0"
            onChange={changeSortBy}
            defaultValue={sortBy}
          >
            <option value="rank">추천순</option>
            <option value="recent">최신순</option>
            <option value="popular">인기순</option>
          </Select>
          <span className="text-12 text-gray-500">
            총 {curation?.products?.length}권
          </span>
        </div>

        <div className="grid grid-cols-2 gap-x-5 gap-y-8 md:grid-cols-6">
          {curation?.products?.map(({ product }) => (
            <ProductCard
              key={product.id}
              to={`/product/${product.id}`}
              product={product}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
