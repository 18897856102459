import { Paginated } from '../types/api'
import { BannerType, MainBanner } from '../types/mainBanners'
import base from './base'

const mainBanners = {
  getMainBanners (type: BannerType) {
    return base.get<Paginated<MainBanner>>('/main-banners', {
      params: {
        'where[type]': type,
        'where[isShow]': 1, // 숨김처리 된 배너는 나오지 않도록
      },
    })
  },
}

export default mainBanners
