import {goToUrl} from "../pages/home/utils";
import React from "react";
import {useTopMenus} from "../queries/topMenus";

const TopMenuBar = () => {
    const {topMenus} = useTopMenus()

    return (
        <div className="sticky top-[50px] z-30 bg-white px-5 pb-4 md:pt-3">
            <div className="mx-auto w-full max-w-screen-xl">
                {topMenus.map((menu) => (
                    <button
                        key={menu.id}
                        onClick={() => goToUrl(menu.url)}
                        className="mr-6 text-14 font-bold "
                    >
                        {menu.name}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default TopMenuBar
