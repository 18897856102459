import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import Meta from './components/Meta'
import { Modal } from './components/modal/Modal';
import { ScrollToTop } from './components/ScrollToTop';
import { Router } from './pages/Router';

function App() {
  return (
    <BrowserRouter>
      <Modal />
      <ScrollToTop />
      <Meta
        title="아크앤북"
        description="아크앤북 입니다."
        canonical=""
        image="https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/banner/mobile/1653305338108--Frame%205121.png"
      />
      <Switch>
        <Route path="/" component={Router} />
      </Switch>
      <ToastContainer
        autoClose={1000}
        hideProgressBar={true}
      />
    </BrowserRouter>
  );
}

export default App;
