import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { CommentItem } from '../../components/CommentItem';
import { useModal } from '../../components/modal/Modal';
import { TextArea } from '../../components/TextArea';
import useAuth from '../../hooks/useAuth';
import { useCreateComment, usePostingComments } from '../../queries/comments';
import { usePosting } from '../../queries/postings';
import { CreateCommentRequestbody } from '../../types/comments';
import { IdParams } from '../../types/router';
import { TypeAList } from './TypeAList';
import { TypeBList } from './TypeBList';
import { TypeCList } from './TypeCList';
import { TypeDList } from './TypeDList';

export const ListsPage = () => {
  const { id } = useParams<IdParams>();
  const { posting } = usePosting(id);
  if (!posting || !posting.type) return <></>;
  return (
    <>
      <div>
        {posting.type === 'A' ? (
          <TypeAList posting={posting} id={id} />
        ) : posting.type === 'B' ? (
          <TypeBList posting={posting} id={id} />
        ) : posting.type === 'C' ? (
          <TypeCList posting={posting} id={id} />
        ) : (
          <TypeDList posting={posting} id={id} />
        )}
      </div>
      {(posting.type === 'A' || posting.type === 'B') && <CommentSection />}
    </>
  );
};

const CommentSection = () => {
  const { id } = useParams<IdParams>();
  const { comments, fetchNextPage, hasNextPage, isLoading } =
    usePostingComments(id);
  const { createComment } = useCreateComment();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    setValue,
    reset,
  } = useForm<CreateCommentRequestbody>({ mode: 'onChange' });

  useEffect(() => {
    if (!setValue) return;
    setValue('postingId', +id);
  }, [setValue, id]);

  const { authenticated } = useAuth();
  const { requireLogin } = useModal();
  const preventNonUser = () => {
    return new Promise((resolve) => {
      if (authenticated) {
        resolve(false);
        return;
      }
      requireLogin();
      resolve(true);
    });
  };

  const onSubmit = async (data: CreateCommentRequestbody) => {
    if (await preventNonUser()) return;
    await createComment(data);
    reset({
      comment: '',
      postingId: +id,
    });
  };

  return (
    <>
      <div className="bg-[#FBFAF9] px-5 py-8">
        <div className="mx-auto md:max-w-screen-md">
          <h6 className="mb-4 font-semibold">코멘트를 달아주세요</h6>

          <div className="flex items-center justify-between overflow-hidden rounded-xl border border-gray-100 bg-white">
            <div className="w-0 flex-1">
              <TextArea
                placeholder="코멘트를 달아주세요."
                className="border-0"
                {...register('comment', {
                  required: true,
                })}
                onClick={preventNonUser}
              />
            </div>
            <Button
              className={`px-4 font-bold text-brand-2 ${
                !isValid && 'font-normal text-gray-300'
              }`}
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            >
              등록
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-8 space-y-5 px-5 md:mx-auto md:w-full md:max-w-screen-md md:p-0">
        {comments.map((comment) => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
        {hasNextPage && !isLoading && (
          <div
            className="flex cursor-pointer justify-center"
            onClick={() => fetchNextPage()}
          >
            더보기
          </div>
        )}
      </div>
    </>
  );
};
