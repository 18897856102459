import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useReviews } from '../../queries/reviews';
import { ShippingLineItem, ShippingStatus } from '../../types/orders';
import { Button } from '../Button';
import { useModal } from '../modal/Modal';

interface OrderProductCardProps {
  product: ShippingLineItem;
  shippingStatus: keyof typeof ShippingStatus;
}

export const OrderProductCard: FC<OrderProductCardProps> = ({
  product,
  shippingStatus,
}) => {
  const { push } = useHistory();
  const { createReview } = useModal();
  const { reviews } = useReviews({
    shippingLineItemId: product.id,
  });
  const isReviewed = reviews.length > 0;
  return (
    <div>
      <div className="mt-4 flex space-x-3">
        <img
          src={product.productImages[0]}
          alt={product.productName}
          className="h-24 w-16 cursor-pointer rounded-xl bg-brand-3 object-cover"
          onClick={() => push(`/product/${product.productId}`)}
        />

        <div className="flex flex-col pt-3">
          <span className="text-14">{product.productName}</span>
          <span className="text-12 text-gray-500">{'저자 | 회사'}</span>
          <p className="mt-3 text-14 text-gray-600">
            <span>{product.totalPrice.toLocaleString()} 원</span> /{' '}
            <span>수량: {product.quantity}</span>
          </p>
        </div>
      </div>

      {isReviewed ? (
        <Button
          disabled
          className="outlined-brand-2 mt-4 h-12 w-full disabled:border-0 disabled:bg-gray-200 disabled:font-normal disabled:text-white"
          text="리뷰 작성 완료"
        />
      ) : (
        <Button
          disabled={shippingStatus !== ShippingStatus.FINISH}
          onClick={() => createReview(product.productId, product.id)}
          text="리뷰 작성"
          className="outlined-brand-2 mt-4 h-12 w-full disabled:border-0 disabled:bg-gray-200 disabled:font-normal disabled:text-white"
        />
      )}
    </div>
  );
};
