import React from 'react';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';

export const FindUserNameSuccessPage = () => {
  return (
    <>
      <div className="mx-auto w-full max-w-md md:my-12">
        <TopBar title="아이디 찾기" />

        <div className="space-y-9 px-5">
          <h6 className="text-gray-700">회원님의 아이디입니다.</h6>

          <TextField peerStyle={false} disabled value={`test@test.com`} />

          <div className="pt-4">
            <Button
              to="/login"
              text="로그인하기"
              className="filled-gray-900 w-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};
