import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../api'
import { useModal } from '../components/modal/Modal'
import useAuth from '../hooks/useAuth'
import { BulkUpdateCartItem, Cart, CartItem, CartItemShallow, CartShallow } from '../types/cart'
import { Product, ProductType } from '../types/products'

const filterItemsByType = (items: CartItem[], type: ProductType) => {
  return items
    .filter(item => item.product.type === type)
    .sort((a, b) => +a.id - +b.id);
}

export const useCart = () => {
  const { authenticated } = useAuth();
  const { data, isLoading, refetch } = useQuery(
    'cart',
    async () => {
      if (!authenticated) return
      const res = await API.getCart()
      return res
    },
    {
      select: useCallback((data: AxiosResponse<Cart> | undefined) => {
        const cart = data?.data;
        if (!cart) return;

        const onlineItems =
          cart.items.filter(item => item.branchCode === '-');
        const online = {
          books: filterItemsByType(onlineItems, 'BOOK'),
          generals: filterItemsByType(onlineItems, 'GENERAL'),
          count: onlineItems.length,
        };

        const pickupItems =
          cart.items.filter(item => item.branchCode !== '-');
        const pickup = {
          books: filterItemsByType(pickupItems, 'BOOK'),
          generals: filterItemsByType(pickupItems, 'GENERAL'),
          count: pickupItems.length,
          currentBranchCode: pickupItems.length > 0 ? pickupItems[0].branchCode : '',
        };

        return {
          cart: cart.items,
          online,
          pickup
        };
      }, []),
    }
  );
  if (isLoading || !data) {
    return {
      cart: [],
      online: {
        books: [],
        generals: [],
        count: 0,
      },
      pickup: {
        books: [],
        generals: [],
        count: 0,
        currentBranchCode: '',
      }
    };
  }

  return {
    ...data,
    refetch,
  };
};

const extractBranchCodes = (cartItems: CartItem[] | CartItemShallow[]) => {
  return Array.from(
    new Set(cartItems.map(item => item.branchCode))
  );
};

export const useCartShallow = () => {
  const { token, authenticated } = useAuth();
  const { data, isLoading, refetch } = useQuery(
    ['cartShallow', token],
    async () => {
      if (!authenticated) return;
      const res = await API.getCartShallow();
      return res;
    },
  );
  if (isLoading || !data?.data) {
    return {
      cart: {} as CartShallow,
    };
  }

  const branchCodes =
    extractBranchCodes(data.data.items)
      .filter(code => code !== '-');
  let pickUpBranchCode = branchCodes.length > 0 ? branchCodes[0] : '';

  return {
    cart: data.data,
    pickUpItems: data.data.items.filter(item => item.branchCode !== '-'),
    pickUpBranchCode,
    refetch,
  };
};

interface ChangeCartItemAmountParam {
  product: Product
  branchCode?: string
  cartQuantity?: number
}

export const useAddProductToCart = (blockExisted = true) => {
  const { push } = useHistory();
  const { cart } = useCartShallow();
  const { authenticated } = useAuth();
  const { requireLogin } = useModal();
  const queryClient = useQueryClient();
  const { mutateAsync: addProductToCart } = useMutation(
    async ({
      product, cartQuantity = 1, branchCode,
    }: ChangeCartItemAmountParam) => {
      if (!authenticated) {
        requireLogin();
        return Promise.reject();
      }
      if (
        product.type === 'BOOK' &&
        (!branchCode || branchCode === '-') &&
        product.remainQuantity < cartQuantity
      ) {
        toast.info('재고가 부족합니다.');
        return Promise.reject();
      }
      if (product.type === 'GENERAL' && product.isSoldOut) {
        toast.info('재고가 부족합니다.');
        return Promise.reject();
      }
      const isExist = cart.items.some(item => item.productId === product.id);
      if (blockExisted && isExist) {
        toast.info('이미 추가된 상품입니다.', {
          onClick: () => {
            push('/cart');
          }
        });
        return Promise.reject();
      }
      return await API.postCartItems(
        product.id,
        { quantity: cartQuantity },
        branchCode
      );
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(['cartShallow']);
        queryClient.refetchQueries(['cart']);
        toast.success('상품이 장바구니에 추가되었습니다.', {
          onClick: () => {
            push('/cart' + (data.branchCode !== '-' ? '?tab=pickup' : ''));
          }
        })
      }
    }
  );
  return {
    addProductToCart,
  }
};

export const useRemoveProductToCart = () => {
  const queryClient = useQueryClient()
  const { mutate: removeProductToCart } = useMutation(
    ({
      product, cartQuantity = 1, branchCode
    }: ChangeCartItemAmountParam) =>
      API.deleteCartItems(
        product.id,
        { quantity: cartQuantity },
        branchCode
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cartShallow'])
        queryClient.refetchQueries(['cart'])
      }
    }
  )
  return {
    removeProductToCart,
  }
}

export const useAddPostingProductsToCart = (postingId: number) => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const { requireLogin } = useModal();
  const queryClient = useQueryClient();
  const { mutate: addPostingProductsToCart } = useMutation(
    () => {
      if (!authenticated) {
        requireLogin();
        return Promise.reject();
      }
      return API.postCartItemsPosting(postingId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cartShallow']);
        queryClient.invalidateQueries(['cart']);
        toast.success('상품이 장바구니에 추가되었습니다.', {
          onClick: () => {
            push('/cart');
          }
        });
      }
    }
  );
  return {
    addPostingProductsToCart,
  }
};

export const useUpdateCartItems = (callback?: Function) => {
  const { authenticated } = useAuth();
  const { requireLogin } = useModal();
  const queryClient = useQueryClient();
  const { mutate: updateCartItems } = useMutation(
    (body: BulkUpdateCartItem[]) => {
      if (!authenticated) {
        requireLogin();
        return Promise.reject();
      }
      return API.patchCartItems(body);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['cartShallow']);
        queryClient.refetchQueries(['cart']);
        if (!callback) return;
        callback();
      },
    }
  );
  return {
    updateCartItems,
  }
}