import { newRidgeState } from 'react-ridge-state';
import { ModalProps } from '../components/modal/Modal';

export const tokenState = newRidgeState<string | null>(null, {
  onSet: (newState) => {
    if (newState) {
      localStorage.setItem('token', newState);
    } else {
      localStorage.removeItem('token');
    }
  },
});

export const osState = newRidgeState<string>('');

function setInitialState() {
  const token = localStorage.getItem('token');
  tokenState.set(token);
}

export const modalState = newRidgeState<ModalProps>({
  title: '',
  bodyText: '',
  primaryButtonText: '',
  primaryClick: () => {},
  secondaryButtonText: '',
  secondaryClick: () => {},
  // secondaryText: '',
  // secondaryClick: () => {},
  // dangerText: '',
  // dangerClick: () => {},
  // footer: '',
});

setInitialState();
